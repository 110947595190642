import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, last, map, mergeMap } from 'rxjs/operators';
import { MalwareFilterRule } from 'src/app/interfaces/powershell/exo/malware-rule.interface';
import { retry } from 'src/app/pipes/retry.pipe';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as ExoActions from './actions';

@Injectable()
export class MalwareFilterRuleEffects {
    GetAntiMalwareRule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.loadMalwareRule),
            retry(3000, 3, 'malware filter rules timeout'),
            last(),
            mergeMap(({ _tenant }) =>
                this.getAntiMalwareRule(_tenant).pipe(
                    map((data: MalwareFilterRule[]) => ExoActions.loadMalwareRuleSuccess({ _tenant, data })),
                    catchError((error) => of(ExoActions.loadMalwareRuleFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    CreateAntiMalwareRule = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.createMalwareRule),
            mergeMap((action) =>
                this.createAntiMalwareRule(action).pipe(
                    map(() => ExoActions.createMalwareRuleSuccess(action)),
                    catchError((error) => of(ExoActions.createMalwareRuleFailure({ _tenant: action._tenant, error }))),
                ),
            ),
        ),
    );

    UpdateAntiMalwareRule = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.updateMalwareRule),
            mergeMap((action) =>
                this.updateAntiMalwareRule(action).pipe(
                    map(() => ExoActions.updateMalwareRuleSuccess(action)),
                    catchError((error) =>
                        of(
                            ExoActions.updateMalwareRuleFailure({
                                _tenant: action._tenant,
                                error: { message: error.message, trace: error.trace },
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
    ) {}

    getAntiMalwareRule(tenant: string) {
        const url = '/api/microsoft/powershell/exo/malware-filter-rule';
        return this.ajax.get(tenant, url);
    }

    createAntiMalwareRule(options: {
        _tenant: string;
        Name: string;
        RecipientDomainIs: string[] | string;
        MalwareFilterPolicy: string;
    }) {
        const url = '/api/microsoft/powershell/exo/malware-filter-rule';
        return this.ajax.post(options._tenant, url, {
            Name: options.Name,
            RecipientDomainIs: options.RecipientDomainIs,
            MalwareFilterPolicy: options.MalwareFilterPolicy,
        });
    }

    updateAntiMalwareRule(options: { _tenant: string; Identity: string; RecipientDomainIs: string[] | string }) {
        const url = '/api/microsoft/powershell/exo/malware-filter-rule';
        return this.ajax.put(options._tenant, url, options);
    }
}
