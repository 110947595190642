import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DismissItem } from './dismiss-items-table/dismiss-items-table.component';

export interface DismissDialogData {
    tenant_id: string;
    title: string;
    type: string;
    items: DismissItem[];
}

@Component({
    selector: 'app-dismiss-panel-dialog',
    templateUrl: './dismiss-panel-dialog.component.html',
    styleUrls: ['./dismiss-panel-dialog.component.scss'],
})
export class DismissPanelDialogComponent implements OnInit {
    constructor(
        public modal: MatDialogRef<DismissDialogData>,
        @Inject(MAT_DIALOG_DATA) public data: DismissDialogData,
    ) {}

    ngOnInit(): void {}
}
