import { AppRole } from '@microsoft/microsoft-graph-types-beta';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as actions from './actions';
export const featureKey = 'graphServicePrincipalAppRoles';

export type State = EntityState<AppRole>;

export const adapter: EntityAdapter<AppRole> = createEntityAdapter<AppRole>();

export const initialState: State = adapter.getInitialState({
    // additional entity state properties
});

export const reducer = createReducer(
    initialState,

    on(actions.loadServicePrincipalAppRolesSuccess, (state, action) =>
        adapter.upsertMany(action.data, { ...state, loaded: true }),
    ),
    on(actions.loadServicePrincipalAppRolesFailure, (state, { error }) => ({ ...state, error, loaded: true })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectById = (id: string) => (state: State) => state.entities[id];
