import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '../../../base/base.component';
import { CAPRequireCompliantOrHybridAzureADJoinedDeviceSchema } from '.';
import { BehaviorSubject, filter, skip } from 'rxjs';
import { ConditionalAccessPolicy } from '@microsoft/microsoft-graph-types-beta';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { FormBuilder } from '@angular/forms';
import { InitCapBaselineFormItem } from '../shared/cap-form';

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../../styles/baseline-style.scss'],
})
export class BaselineComponent extends BaseComponent implements OnInit, OnDestroy {
    schemaFunc = CAPRequireCompliantOrHybridAzureADJoinedDeviceSchema;
    schema$: BehaviorSubject<any>;
    policy$: BehaviorSubject<ConditionalAccessPolicy> = new BehaviorSubject(null);

    constructor(
        private snackbar: SnackbarService,
        private fb: FormBuilder,
    ) {
        super();
    }
    initForm(schema) {
        return InitCapBaselineFormItem(schema, this.fb);
    }
    ngOnInit(): void {
        this.form = this.initForm(this.baseline.schema);
        this.schema$ = new BehaviorSubject(this.baseline.schema);

        if (this.baseline.schema?.contains?.properties?.state?.const === 'enabled') {
            this.snackbar.fixedError({
                title: 'Warning',
                desc: 'Remediating a conditional access policy that blocks access to the selected users from uncompliant devices. It is better to check your compliant policy first and then click "Remediate" when you are sure that you want to make the change.',
            });
        }

        this.schema$
            .pipe(
                filter((res) => !!res),
                skip(1),
            )
            .subscribe((schema) => {
                // skip first emit
                this.snackbar.dismiss();
                if (schema?.contains?.properties?.state?.const === 'enabled') {
                    this.snackbar.fixedError({
                        title: 'Warning',
                        desc: 'Remediating a conditional access policy that blocks access to the selected users from uncompliant devices. It is better to check your compliant policy first and then click "Remediate" when you are sure that you want to make the change.',
                    });
                }

                this.baselineChange.next({
                    ...this.baseline,
                    schema: JSON.parse(JSON.stringify(schema)),
                });
            });
    }

    ngOnDestroy() {
        this.snackbar.dismiss();
    }

    onBaselineErrorChange(e) {
        this.baselineErrorChange.next(e);
    }

    mapper(item) {
        const mapping = {
            compliantDevice: 'Require device to be marked as compliant.',
            domainJoinedDevice: 'Require hybrid Azure AD joined device.',
        };

        return mapping[item] || item;
    }
}
