import { Baseline } from './../../../../../../stores/client/sway/baseline/model';
import { SwaySpec } from './../../../../../../stores/root/sway/spec/model';
import { AuthenticationMethod, User } from '@microsoft/microsoft-graph-types-beta';
import { Store } from '@ngrx/store';
import { combineLatest, filter, map, Observable, sample } from 'rxjs';
import { client } from 'src/app/stores/client';
import { Status } from 'src/app/stores/status.interface';
import { BooleanFormComponent, BooleanValidatorComponent } from '../generic/boolean';
import { UserExclusion } from '../../group-spec-registry.service';

export const fieldName = 'MFAEnabled';
export const href =
    'https://learn.microsoft.com/en-us/microsoft-365/admin/security-and-compliance/multi-factor-authentication-microsoft-365?view=o365-worldwide';
export interface ExtendedUser extends User {
    RecipientTypeDetails: string;
    roles: string[];
    groups: string[];
}

export { BooleanFormComponent as FormComponent, BooleanValidatorComponent as ValidatorComponent };

export function fetch_data_status(store: Store<any>, tenant_id) {
    // users
    const usersReady$ = store.select(client(tenant_id).graph.users.status).pipe(filter((res) => res.loaded));

    // auth methods
    const authMethodsReady$ = store
        .select(client(tenant_id).graph.authenticationMethods.status)
        .pipe(filter((res) => res.loaded));

    return combineLatest(usersReady$, authMethodsReady$).pipe(
        map(([user_ready, auth_ready]) => ({
            loaded: user_ready.loaded && auth_ready.loaded,
            loading: user_ready.loading && auth_ready.loading,
            error: user_ready.error && auth_ready.error,
            updating: user_ready.updating && auth_ready.updating,
        })),
    );
}

export function selectData(store: Store<any>, tenant_id) {
    // users
    const usersReady$ = store.select(client(tenant_id).graph.users.status).pipe(filter((res) => res.loaded));

    const users$ = store.select(client(tenant_id).graph.users.all).pipe(sample(usersReady$));

    // auth methods
    const authMethodsReady$ = store
        .select(client(tenant_id).graph.authenticationMethods.status)
        .pipe(filter((res) => res.loaded));
    const authMethods$ = store
        .select(client(tenant_id).graph.authenticationMethods.all)
        .pipe(sample(authMethodsReady$));

    // mailboxes
    const mailboxesReady$ = store
        .select(client(tenant_id).powershell.exoMailbox.status)
        .pipe(filter((res) => res.loaded));

    const mailboxes$ = store.select(client(tenant_id).powershell.exoMailbox.all).pipe(sample(mailboxesReady$));

    // roles

    // role members
    const directoryRoleMembersReady$ = store
        .select(client(tenant_id).graph.directoryRole.members.status)
        .pipe(filter((res) => res.loaded));
    const directoryRoleMembers$ = store
        .select(client(tenant_id).graph.directoryRole.members.all)
        .pipe(sample(directoryRoleMembersReady$));

    // group members
    const groupsMemberReady$ = store
        .select(client(tenant_id).graph.groups.members.status)
        .pipe(filter((res) => res.loaded));
    const groups$ = store.select(client(tenant_id).graph.groups.all).pipe(sample(groupsMemberReady$));

    return combineLatest([users$, authMethods$, mailboxes$, directoryRoleMembers$, groups$]).pipe(
        map(([users, authMethods, mailboxes, roles, groups]) => {
            const user_data: any[] = [];

            for (const user of users) {
                const userMethods = authMethods.find((item) => item.id === user.id)?.value;
                // filter roles where user is member

                const userRoles = roles
                    .filter((role) => role?.members?.some((member) => member.id === user.id))
                    .map((role) => role.roleTemplateId);

                const userGroups = groups
                    .filter((group) => group.members.some((member) => member.id === user.id))
                    .map((group) => group.id);

                user_data.push({
                    ...user,
                    roles: userRoles,
                    groups: userGroups,
                    MFAEnabled: userMethods ? getAuthMethod(userMethods) : false,
                    RecipientTypeDetails: mailboxes.find((m) => m.UserPrincipalName === user.userPrincipalName)
                        ?.RecipientTypeDetails,
                });
            }
            return user_data;
        }),
    );
}

export function select_status(store: Store<any>, tenant_id): Observable<Status> {
    // users
    const usersReady$ = store.select(client(tenant_id).graph.users.status).pipe(filter((res) => res.loaded));

    // auth methods
    const authMethodsReady$ = store
        .select(client(tenant_id).graph.authenticationMethods.status)
        .pipe(filter((res) => res.loaded));

    // mailboxes
    const mailboxesReady$ = store
        .select(client(tenant_id).powershell.exoMailbox.status)
        .pipe(filter((res) => res.loaded));

    return combineLatest([usersReady$, authMethodsReady$, mailboxesReady$]).pipe(
        map(([userStatus, authStatus, mailboxStatus]) => ({
            loading: userStatus.loading || authStatus.loading || mailboxStatus.loading,
            loaded: userStatus.loaded && authStatus.loaded && mailboxStatus.loaded,
            updating: userStatus.updating || authStatus.updating || mailboxStatus.updating,
            error: userStatus.error || authStatus.error || mailboxStatus.error,
        })),
    );
}

function getAuthMethod(methods: AuthenticationMethod[]) {
    let status: boolean;

    methods.forEach((res) => {
        switch (res['@odata.type']) {
            case '#microsoft.graph.windowsHelloForBusinessAuthenticationMethod':
                status = true;
                break;

            case '#microsoft.graph.microsoftAuthenticatorAuthenticationMethod':
                status = true;
                break;

            case '#microsoft.graph.fido2AuthenticationMethod':
                status = true;
                break;

            case '#microsoft.graph.phoneAuthenticationMethod':
                status = true;
                break;
            case '#microsoft.graph.softwareOathAuthenticationMethod':
                status = true;
                break;
        }
    });

    return status || false;
}

function excludeUserOnTenantBaseline(baseline: Baseline, user: ExtendedUser): UserExclusion {
    const schema = baseline.schema;
    const includeRoles: string[] =
        schema?.contains?.properties.conditions.properties?.users?.properties?.includeRoles?.items?.enum;
    const includeUsers: string[] =
        schema?.contains?.properties.conditions.properties?.users?.properties?.includeUsers?.items?.enum;
    const includeGroups: string[] =
        schema?.contains?.properties.conditions.properties?.users?.properties?.includeGroups?.items?.enum;

    const excludeRoles: string[] =
        schema?.contains?.properties.conditions.properties?.users?.properties?.excludeRoles?.items?.enum;
    const excludeUsers: string[] =
        schema?.contains?.properties.conditions.properties?.users?.properties?.excludeUsers?.items?.enum;
    const excludeGroups: string[] =
        schema?.contains?.properties.conditions.properties?.users?.properties?.excludeGroups?.items?.enum;
    const isRoleExcluded = user.roles?.some((userRole) =>
        excludeRoles?.some((excludedRole) => excludedRole === userRole),
    );
    const isUserExcluded = excludeUsers?.some((excludedUser) => excludedUser === user.id);
    const isGroupExcluded = user.groups.some((userGroup) =>
        excludeGroups?.some((excludedGroup) => excludedGroup === userGroup),
    );

    if (includeUsers?.[0]?.toLocaleLowerCase() === 'all') {
        // if (isRoleExcluded || isUserExcluded || isGroupExcluded) {
        //     return;
        // } else {
        return {
            type: 'tenant_baseline_override',
            desc: 'Excluded because this user is manged by MFA conditional access policy and all users included.', //
            icon: 'arrow_upward',
        };
        // }
    } else if (includeUsers?.[0]?.toLocaleLowerCase() === 'none') {
        return;
    } else {
        const isRoleIncluded = user.roles?.some((userRole) =>
            includeRoles?.some((includedRole) => includedRole === userRole),
        );
        const isUserIncluded = includeUsers?.some((includedUser) => includedUser === user.id);
        const isGroupIncluded = user.groups.some((userGroup) =>
            includeGroups?.some((includedGroup) => includedGroup === userGroup),
        );

        if (isUserIncluded) {
            // we don't care about exclusion because we explicitly included the user.
            return {
                type: 'tenant_baseline_override',
                desc: 'Excluded because this user is manged by MFA conditional access policy.',
                icon: 'arrow_upward',
            };
        } else if (isRoleIncluded && !isUserExcluded) {
            return {
                type: 'tenant_baseline_override',
                desc: 'Excluded because this user is an admin user and is manged by MFA conditional access policy.',
                icon: 'arrow_upward',
            };
        } else if (isGroupIncluded && !isUserExcluded) {
            return {
                type: 'tenant_baseline_override',
                desc: 'Excluded because this user is manged by MFA conditional access policy.',
                icon: 'arrow_upward',
            };
        }
    }
}

export function exclude(user: ExtendedUser, specs: SwaySpec[], baselines: Baseline[]): UserExclusion | undefined {
    if (!user.accountEnabled) {
        return {
            desc: 'Excluded because this user is blocked from sign-in.',
            type: 'sign-in_blocked',
            icon: 'not_interested',
            class: 'na-icon',
        };
    } else if (user.userType === 'Guest') {
        return {
            type: 'guest_user',
            desc: 'Excluded because this user is a guest user.',
            icon: 'not_interested',
            class: 'na-icon',
        };
    }
    // else if (
    //     user.assignedPlans.filter((plan) => plan.capabilityStatus === 'Enabled')
    //         .length === 0
    // ) {
    //     return {
    //         status: true,
    //         desc: 'Excluded because this user does not have any assigned plans.',
    //     };
    // }

    const MFASpec = specs.find((spec) => spec.tag === 'MfaConditionalAccessPolicy');
    const enforceMFASpec = specs.find((spec) => spec.tag === 'CAPEnforceMFA');

    const MFABaseline = baselines.find((baseline) => baseline.spec_id === MFASpec?.id);
    const enforceMFABaseline = baselines.find((baseline) => baseline.spec_id === enforceMFASpec?.id);

    if (enforceMFABaseline) {
        return excludeUserOnTenantBaseline(enforceMFABaseline, user);
    }

    if (MFABaseline) {
        return excludeUserOnTenantBaseline(MFABaseline, user);
    }

    return undefined;
}
