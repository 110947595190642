import { MessageRulePredicates, NullableOption, User } from '@microsoft/microsoft-graph-types-beta';
import { createReducer, on } from '@ngrx/store';
import { EXOInboxRule, InboxRule } from 'src/app/interfaces/powershell/exo/inbox-rule.interface';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as GraphInboxRulesActions from './actions';
import { InboxRuleModel } from './model';

export const featureKey = 'graphInboxRules';

export interface State extends Status {
    values: InboxRuleModel[];
    error: any;
}

export const fetchState: State = {
    values: [],
    error: false,
    ...initialStatus,
};

function formatDescription(desc: string): { action: string; condition: string } {
    let action = '';
    let condition = '';
    let trimmed = [];
    if (desc) {
        trimmed = desc.replace(/\n|\r|\t|If the message:/g, '').split('Take the following actions:');
    }
    action = trimmed.length > 1 ? trimmed[1] : '';
    condition = 'If ' + trimmed[0];
    return { action, condition };
}

function formatConditions(rule: EXOInboxRule) {
    const conditions: NullableOption<MessageRulePredicates> = {};
    conditions.headerContains =
        rule.HeaderContainsWords && rule.HeaderContainsWords.length > 0 ? rule.HeaderContainsWords : undefined;
    conditions.subjectContains =
        rule.SubjectContainsWords && rule.SubjectContainsWords.length > 0 ? rule.SubjectContainsWords : undefined;
    conditions.bodyOrSubjectContains =
        rule.SubjectOrBodyContainsWords && rule.SubjectOrBodyContainsWords.length > 0
            ? rule.SubjectOrBodyContainsWords
            : undefined;
    conditions.bodyContains =
        rule.BodyContainsWords && rule.BodyContainsWords.length > 0 ? rule.BodyContainsWords : undefined;
    return conditions;
}

function fetchGraphInboxRulesSuccess(state: State, values: InboxRuleModel[]) {
    return { ...state, values, loaded: true, loading: false };
}

function fetchGraphInboxRulesFailure(state: State, error: any) {
    return { ...state, error, loaded: false, loading: false };
}

function fetchExoInboxRuleSuccess(state: State, user_id: string, rule: EXOInboxRule) {
    const values = [...state.values.map((item) => ({ ...item }))]; // clone

    const model_idx = values.findIndex((item) => item.user_id === user_id);
    const model_to_update = values[model_idx];
    model_to_update.rules = [...values[model_idx].rules];

    const rule_idx = model_to_update.rules.findIndex((item) => item.displayName === rule.Name);
    const rule_to_update = model_to_update.rules[rule_idx];

    const conditions = formatConditions(rule);
    const description = formatDescription(rule.Description);

    const updated_rule: InboxRule = {
        ...rule_to_update,
        description,
        conditions,
    };

    model_to_update.rules[rule_idx] = updated_rule;

    return { ...state, values };
}

function fetchExoInboxRuleFailure(state: State, action) {
    const mailbox_idx = state.values.findIndex((i: InboxRuleModel) => i.user_id === action.identity);
    const values = state.values.slice();
    return { ...state, values, error: action.error };
}

function toggleInboxRule(state: State): State {
    return { ...state, updating: true };
}

function toggleInboxRuleSuccess(state: State, action) {
    const mailbox_idx = state.values.findIndex((i: InboxRuleModel) => i.user_id === action.mailbox_id);
    const values = state.values.slice();
    const rules = values[mailbox_idx].rules.slice();
    const rule_idx = rules.findIndex((i: InboxRule) => i.id === action.rule_id);
    const rule: InboxRule = {
        ...rules[rule_idx],
        isEnabled: action.isEnabled,
    };
    rules[rule_idx] = rule;
    values[mailbox_idx].rules = rules;
    return { ...state, values, updating: false };
}

function toggleInboxRuleFailure(state: State, action) {
    const values = state.values.slice();
    return { ...state, values, error: action.error, updating: false };
}

export const reducer = createReducer(
    fetchState,
    on(GraphInboxRulesActions.fetchGraphInboxRules, (state) => ({ ...state, loading: true })),
    on(GraphInboxRulesActions.fetchGraphInboxRulesSuccess, (state, { rules }) =>
        fetchGraphInboxRulesSuccess(state, rules),
    ),
    on(GraphInboxRulesActions.fetchGraphInboxRulesFailure, (state, { error }) =>
        fetchGraphInboxRulesFailure(state, error),
    ),
    on(GraphInboxRulesActions.fetchExoInboxRuleSuccess, (state, { user_id, rule }) =>
        fetchExoInboxRuleSuccess(state, user_id, rule),
    ),
    on(GraphInboxRulesActions.fetchExoInboxRuleFailure, fetchExoInboxRuleFailure),
    on(GraphInboxRulesActions.toggleInboxRule, toggleInboxRule),
    on(GraphInboxRulesActions.toggleInboxRuleSuccess, toggleInboxRuleSuccess),
    on(GraphInboxRulesActions.toggleInboxRuleFailure, toggleInboxRuleFailure),
);

export const selectAll = (state: State) => state.values;
export const selectByUserId = (id: string) => (state: State) => state.values.filter((item) => item.user_id === id);

export const mappedToUser = (users: User[], state: State) => {
    const result: { user: User; rules: InboxRule[] }[] = [];
    for (const user of users) {
        const model = state.values.find((item) => item.user_id === user.id);
        result.push({ user, rules: model ? model.rules : [] });
    }
    return result;
};
