import { createReducer, on } from '@ngrx/store';
import { MspAccessGroupsDTO_Fetch, MspAccessGroupsDTO_Get } from '../../models/msp-access-group';
import { MspAccessRolesDTO_Fetch } from '../../models/msp-access-roles';
import {
    fetchMspAccessGroups,
    fetchMspAccessGroups_success,
    fetchMspAccessRoles_success,
    getMspAccessGroup,
    getMspAccessGroup_success,
} from './msp-access-groups.actions';

export const mspAccessGroupsFeatureKey = 'msp-access-groups';

interface FullMspAccessGroupsState {
    accessGroups: MspAccessGroupsDTO_Fetch;
    selectedAccessGroup: MspAccessGroupsDTO_Get;

    accessRoles: MspAccessRolesDTO_Fetch;
}

export type MspAccessGroupsState = Partial<FullMspAccessGroupsState>;

export const mspAccessGroupsReducer = createReducer(
    {},
    on(fetchMspAccessGroups, (state) => ({ ...state, accessGroups: undefined, loading: true })),
    on(fetchMspAccessGroups_success, (state, { accessGroups }) => ({
        ...state,
        accessGroups,
    })),

    on(getMspAccessGroup, (state) => ({ ...state, selectedAccessGroup: undefined, loading: true })),
    on(getMspAccessGroup_success, (state, { selectedAccessGroup }) => ({
        ...state,
        selectedAccessGroup,
    })),

    on(fetchMspAccessRoles_success, (state, { accessRoles }) => ({
        ...state,
        accessRoles,
    })),
);
