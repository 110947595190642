import { ConditionalAccessPolicySchema, createCapPolicySchema } from '../shared/cap-schema';

const sessionControls = {
    type: 'object',
    properties: {
        persistentBrowser: {
            type: 'object',
            properties: {
                mode: {
                    type: 'string',
                    const: 'never',
                },
                isEnabled: {
                    type: 'boolean',
                    const: true,
                },
            },
        },
    },
};

const policy: ConditionalAccessPolicySchema = {
    sessionControls,
};

export const PersistentPolicySchema = (form) => {
    return createCapPolicySchema(form, policy);
};
