import { Component, OnInit } from '@angular/core';
import { ConditionalAccessPolicy } from '@microsoft/microsoft-graph-types-beta';
import { Store } from '@ngrx/store';
import { combineLatest, first, map, switchMap } from 'rxjs';
import { client } from 'src/app/stores/client';
import { selectSession } from 'src/app/stores/root.store';
import { BaseComponent } from '../../base/base.component';

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../styles/baseline-style.scss'],
})
export class BaselineComponent extends BaseComponent implements OnInit {
    policyLength: number = 0;
    readMore;
    conditionalAccessPolicies: ConditionalAccessPolicy[];
    constructor(private store: Store<any>) {
        super();
    }

    ngOnInit(): void {
        const selectPolicies$ = this.store.select(selectSession).pipe(
            map((sess) => sess.session.clientId),

            switchMap((tid) =>
                combineLatest([
                    this.store.select(client(tid).graph.conditionalAccessPolicy.all),
                    this.store.select(client(tid).graph.conditionalAccessPolicy.status),
                ]),
            ),
            first(([data, status]) => status.loaded),
            map(([data]) => data),
        );

        selectPolicies$.subscribe((res: ConditionalAccessPolicy[]) => {
            if (res instanceof Array) {
                const checkArr = res.filter((re) => re.state === 'enabled');
                this.policyLength = checkArr.length || 0;
                this.conditionalAccessPolicies = checkArr;
            } else {
                this.policyLength = 0;
            }
        });

        const schema = JSON.parse(JSON.stringify(this.baseline.schema));
        const value = schema?.properties?.isEnabled?.const;
        this.control.setValue(value);

        this.control.valueChanges.subscribe((value) => {
            schema.properties.isEnabled['const'] = value;

            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema)),
            });

            this.baselineErrorChange.next({
                remediate: this.policyLength > 0,
                save: false,
            });
        });
    }
}
