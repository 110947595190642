import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './deviation.actions';
import { SwayDeviation } from './deviation.model';

export const featureKey = 'deviation';

export interface State extends EntityState<SwayDeviation>, Status {}

export const adapter: EntityAdapter<SwayDeviation> = createEntityAdapter<SwayDeviation>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,

    // LOAD

    on(actions.loadDeviationsSuccess, (state, action) => adapter.setAll(action.data, { ...state, loaded: true })),
    on(actions.loadDeviationsFailure, (state, { error }) => ({ ...state, loaded: false, error })),

    // CREATE
    on(actions.createDeviation, (state) => ({ ...state, creating: true })),
    on(actions.createDeviationSuccess, (state, { data }) => adapter.upsertOne(data, { ...state, creating: false })),
    on(actions.createDeviationFailure, (state, { error }) => ({ ...state, creating: false, error })),

    // UPDATE (dismiss)
    on(actions.updateDeviation, (state) => ({ ...state, updating: true })),
    on(actions.updateDeviationSuccess, (state, { data }) => adapter.upsertOne(data, { ...state, updating: false })),
    on(actions.updateDeviationFailure, (state, { error }) => ({ ...state, updating: false, error })),

    // CREATE MULTIPLE
    on(actions.createMultipleDeviations, (state) => ({ ...state, creating: true })),
    on(actions.createMultipleDeviationsSuccess, (state, { data }) =>
        adapter.upsertMany(data, { ...state, creating: false }),
    ),
    on(actions.createMultipleDeviationsFailure, (state, { error }) => ({ ...state, creating: false, error })),

    // UPDATE MULTIPLE
    on(actions.updateMultipleDeviations, (state) => ({ ...state, updating: true })),
    on(actions.updateMultipleDeviationsSuccess, (state, { data }) =>
        adapter.upsertMany(data, { ...state, updating: false }),
    ),
    on(actions.updateMultipleDeviationsFailure, (state, { error }) => ({ ...state, updating: false, error })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
