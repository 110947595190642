import { createAction, props } from '@ngrx/store';
import { Baseline } from './model';

export const loadBaselines = createAction('[Sway Baseline] Load', props<{ _tenant: string }>());

export const loadBaselinesSuccess = createAction(
    '[Sway Baseline] Load Success',
    props<{ _tenant: string; data: Baseline[] }>(),
);

export const loadBaselinesFailure = createAction(
    '[Sway Baseline] Load Failure',
    props<{ _tenant: string; error: any }>(),
);

export const createBaseline = createAction(
    '[Sway Baseline] Create',
    props<{ _tenant: string; data: Partial<Baseline> }>(),
);

export const createBaselineSuccess = createAction(
    '[Sway Baseline] Create Success',
    props<{ _tenant: string; data: Baseline }>(),
);

export const createBaselineFailure = createAction(
    '[Sway Baseline] Create Failure',
    props<{ _tenant: string; error: any }>(),
);

export const updateBaseline = createAction(
    '[Sway Baseline] Update',
    props<{ _tenant: string; data: Partial<Baseline>; update_auto_remediation?: boolean }>(),
);

export const updateBaselineSuccess = createAction(
    '[Sway Baseline] Update Baselines Success',
    props<{ _tenant: string; data: Baseline }>(),
);

export const updateBaselineFailure = createAction(
    '[Sway Baseline] Update Failure',
    props<{ _tenant: string; error: any }>(),
);

export const deleteBaseline = createAction('[Sway Baseline] Delete', props<{ _tenant: string; baselineId: string }>());

export const deleteBaselineSuccess = createAction(
    '[Sway Baseline] Delete Success',
    props<{ _tenant: string; baselineId: string }>(),
);

export const deleteBaselineFailure = createAction(
    '[Sway Baseline] Delete Failure',
    props<{ _tenant: string; error: any }>(),
);

export const detachBaseline = createAction(
    '[Sway Baseline] detach baseline item',
    props<{ _tenant: string; template_item_id: string; delete_baseline: boolean }>(),
);

export const deleteBaselineTemplate = createAction(
    '[Sway Baseline] Delete Baseline Template',
    props<{ _tenant: string; template_id: string }>(),
);

export const deleteBaselineTemplateSuccess = createAction(
    '[Sway Baseline] Delete Baseline Template Success',
    props<{ _tenant: string; baselines: Array<string> }>(),
);

export const deleteBaselineTemplateFailure = createAction(
    '[Sway Baseline] Delete Baseline Template Failure',
    props<{ _tenant: string; error: any }>(),
);

export const detachBaselineTemplate = createAction(
    '[Sway Baseline] detach Baseline Template',
    props<{ _tenant: string; template_id: string }>(),
);

export const upsertBaselineItemsSuccess = createAction(
    '[Sway Baseline] upsert Baseline Template success',
    props<{ _tenant: string; baselines: Baseline[] }>(),
);

export const upsertBaselineItems = createAction(
    '[Sway Baseline] upsert Baseline Template',
    props<{ _tenant: string }>(),
);

export const detachBaselineTemplateSuccess = createAction(
    '[Sway Baseline] detach Baseline Template Success',
    props<{ _tenant: string; baselines: Array<Baseline> }>(),
);

export const detachBaselineTemplateFailure = createAction(
    '[Sway Baseline] detach Baseline Template Failure',
    props<{ _tenant: string; error: any }>(),
);
