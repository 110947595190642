import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import * as fromRoot from 'src/app/stores/root.store';
import { Changes, ChangeType } from '../interfaces/changes.interface';
import { ChangesLog } from '../stores/client/octiga/changes/model';

function formatCasMailboxFields(fields: { [key: string]: boolean }): {
    [key: string]: boolean;
} {
    const keyValue = Object.entries(fields)[0];
    const newKey = keyValue[0]
        .split(/(?=[A-Z])/)
        .slice(0, -1)
        .join('');
    return { [newKey]: keyValue[1] };
}

@Injectable({
    providedIn: 'root',
})
export class ChangesService {
    actor: string;
    tenant: string;

    constructor(public store: Store<fromRoot.State>) {
        this.store
            .pipe(
                select(fromRoot.selectSession),
                filter((state) => state.isAuthenticated && !!state.session.clientId),
                distinctUntilChanged((a, b) => a.session.clientId === b.session.clientId),
            )
            .subscribe((data) => {
                this.actor = data.session.upn;
                this.tenant = data.session.clientId;
            });
    }

    formatChangesObjectToDB(params: { user: string; fields?: any }, changeType: ChangeType): Changes {
        const timestamp = new Date().toISOString();

        const item = {
            tenant: this.tenant,
            tenant_user: `${this.tenant}_${params.user}`,
            tenant_actor: `${this.tenant}_${this.actor}`,
            timestamp_user_actor_type: `${timestamp}_${this.tenant}_${params.user}_${this.actor}_${changeType}`,
            timestamp,
            actor: this.actor,
            user: params.user,
            type: changeType,
        };

        if (params.fields) {
            item['fields'] = changeType === 'cas-mailbox' ? formatCasMailboxFields(params.fields) : params.fields;
        }

        return item;
    }

    setChangesTitle(type: string): string {
        if (type === 'cas-mailbox') {
            return 'Remote Access';
        } else {
            return type.replace('-', ' ');
        }
    }

    setChangesDescription(change: ChangesLog) {
        switch (change.type) {
        case 'cas-mailbox':
            var field = Object.entries(change.fields)[0];
            var status = field[1] ? 'Enabled' : 'Disabled';
            return `${status} ${field[0]} on ${change.user}.`;
        case 'inbox-rule':
            var status = change.fields['isEnabled'] ? 'Enabled' : 'Disabled';
            return `${status} '${change.fields['displayName']}' rule on ${change.user}.`;
        case 'owa-forwarding':
            const forwardingAddress = change.fields['ForwardingAddress']
                ? change.fields['ForwardingAddress']
                : change.fields['ForwardingSmtpAddress'];
            return `Deleted OWA Auto-Forwarding Rule to '${forwardingAddress}' on ${change.user}.`;
        case 'reset-password':
            return `Reset Password for ${change.user}.`;
        case 'mfa':
            return `Enabled MFA for ${change.user}.`;
        case 'transport-rule':
            // TODO: replace company with tenant's organization name
            return `${
                change.fields['operation'][0].toUpperCase() + change.fields['operation'].substring(1)
            }d company's transport rule - '${change.fields['ruleName']}'. `;
        case 'mailbox':
        case 'user':
        case 'user-license':
            return `${
                change.fields['operation'][0].toUpperCase() + change.fields['operation'].substring(1)
            }d ${change.type.replace('-', ' ')} - '${change.user}'. `;
        case 'admin-role':
            return `${
                change.fields['operation'][0].toUpperCase() + change.fields['operation'].substring(1)
            }d administrator role - '${change.fields['name']}' from ${change.user}. `;
        case 'shared-mailbox':
            var sendAs = !!change.fields['SendAs'] && change.fields['SendAs'].length > 0 ? true : false;
            var fullAccess = !!change.fields['FullAccess'] && change.fields['FullAccess'].length > 0 ? true : false;
            var desc = `${
                change.fields['operation'][0].toUpperCase() + change.fields['operation'].substring(1)
            }d mailbox ${change.user} to `;
            if (sendAs) {
                fullAccess
                    ? (desc += `${change.fields['SendAs'].join(', ')} with "send as" permission \n and `)
                    : (desc += `${change.fields['SendAs'].join(', ')} with "send as" permission.`);
            }
            if (fullAccess) {
                desc += `${change.fields['SendAs'].join(', ')} with "full access" permission.`;
            }
            return desc;
        case 'sharepoint-sharing':
            return `${
                change.fields['operation'][0].toUpperCase() + change.fields['operation'].substring(1)
            }d Sharepoint Sharing configuaration. `;
        case 'sharing-policy':
            return `${
                change.fields['operation'][0].toUpperCase() + change.fields['operation'].substring(1)
            }d Calendar and Contacts ${change.type.replace('-', ' ')}.`;
        }
    }

    listOfDates(start: string, end: string): string[] {
        let listDates = [];
        const startDate = start.split('T')[0];
        const endDate = end.split('T')[0];
        const dateMove = new Date(startDate);
        var strDate = startDate;
        if (strDate === endDate) {
            listDates = [startDate];
        }

        while (strDate < endDate) {
            var strDate = dateMove.toISOString().slice(0, 10);
            listDates.push(strDate);
            dateMove.setDate(dateMove.getDate() + 1);
        }

        return listDates;
    }
}
