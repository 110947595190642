import { Domain } from '@microsoft/microsoft-graph-types-beta';
import { sample } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Component, Input, OnInit } from '@angular/core';
import { DKIMSigningConfig } from 'src/app/stores/client/powershell/exo/dkim-signing-config/model';
import { BaseComponent } from '../../base/base.component';
import { filter, map } from 'rxjs';
import { client } from 'src/app/stores/client';
@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../styles/baseline-style.scss'],
})
export class BaselineComponent extends BaseComponent implements OnInit {
    @Input() data: DKIMSigningConfig[];
    default_domain: Domain;
    dkimForDefaultDomain;
    constructor(private store: Store<any>) {
        super();
    }

    ngOnChanges(): void {
        this.store
            .pipe(
                select(client(this.tenant_id).graph.domains.all),
                filter((i) => !!i),
                map((domains) => domains.filter((domain) => !!domain.isVerified).find((res) => res.isDefault)),
                sample(
                    this.store.select(client(this.tenant_id).graph.domains.status).pipe(
                        map((res) => res.loaded),
                        filter((res) => !!res),
                    ),
                ),
            )
            .subscribe((res) => {
                this.default_domain = res;
                this.dkimForDefaultDomain = this.data[0];

                if (!this.default_domain || !this.dkimForDefaultDomain) {
                    this.baselineErrorChange.next({ remediate: true, save: false });
                } else {
                    this.baselineErrorChange.next({ remediate: false, save: false });
                }
            });
    }

    ngOnInit(): void {
        this.store
            .pipe(
                select(client(this.tenant_id).graph.domains.all),
                filter((i) => !!i),
                map((domains) => domains.filter((domain) => !!domain.isVerified).find((res) => res.isDefault)),
                sample(
                    this.store.select(client(this.tenant_id).graph.domains.status).pipe(
                        map((res) => res.loaded),
                        filter((res) => !!res),
                    ),
                ),
            )
            .subscribe((res) => {
                this.default_domain = res;
                this.dkimForDefaultDomain = this.data[0];

                if (!this.default_domain || !this.dkimForDefaultDomain) {
                    this.baselineErrorChange.next({ remediate: true, save: false });
                } else {
                    this.baselineErrorChange.next({ remediate: false, save: false });
                }
            });

        const schema = JSON.parse(JSON.stringify(this.baseline.schema));
        const value = schema?.contains?.properties?.Enabled?.const;
        this.control.setValue(value);

        this.control.valueChanges.subscribe((value) => {
            schema.contains.properties.Enabled.const = value;

            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema)),
            });
        });
    }
}
