import { AppRoleAssignment } from '@microsoft/microsoft-graph-types-beta';
import { createAction, props } from '@ngrx/store';

export const loadAppRoleAssignments = createAction(
    '[Graph/AppRoleAssignment] Load',
    props<{ _tenant: string; id: string }>(),
);

export const loadAppRoleAssignmentsSuccess = createAction(
    '[Graph/AppRoleAssignment] Load Success',
    props<{ _tenant: string; roles: AppRoleAssignment[] }>(),
);

export const loadAppRoleAssignmentsFailure = createAction(
    '[Graph/AppRoleAssignment] Load Failure',
    props<{ _tenant: string; error: any }>(),
);
