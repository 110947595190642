import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, map, combineLatest, first, switchMap } from 'rxjs';
import { client } from 'src/app/stores/client';
import { AntiPhishPolicy } from 'src/app/stores/client/powershell/exo/anti-phish-policy/model';
import { fetchExoQuarantinePolicy } from 'src/app/stores/client/powershell/exo/quarantine-policy/actions';
import { QuarantinePolicy } from 'src/app/stores/client/powershell/exo/quarantine-policy/model';
import { selectSession } from 'src/app/stores/root.store';
import { BaseComponent } from '../../base/base.component';

const Description = {
    EnableFirstContactSafetyTips:
        'The EnableFirstContactSafetyTips parameter specifies whether to enable or disable the safety tip that\'s shown when recipients first receive an email from a sender or do not often receive email from a sender ',
    AuthenticationFailAction:
        'This setting is part of spoof protection. The AuthenticationFailAction parameter specifies the action to take when the message fails composite authentication.',
    SpoofQuarantineTag:
        'The SpoofQuarantineTag specifies the quarantine policy that\'s used on messages that are quarantined by spoof intelligence.',
    EnableSpoofIntelligence:
        'This setting is part of spoof protection. The EnableSpoofIntelligence parameter specifies whether to enable or disable antispoofing protection for the policy.',
    EnableViaTag:
        'This setting is part of spoof protection. The EnableViaTag parameter enables or disables adding the via tag to the From address in Outlook (chris@contso.com via fabrikam.com).',
    EnableUnauthenticatedSender:
        'This setting is part of spoof protection. The EnableUnauthenticatedSender parameter enables or disables unauthenticated sender identification in Outlook.  A question mark (?) is applied to the sender\'s photo if the message does not pass SPF or DKIM checks AND the message does not pass DMARC or composite authentication.',
};

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../styles/baseline-style.scss'],
})
export class BaselineComponent extends BaseComponent implements OnInit, OnChanges {
    description = Description;
    defaultPolicy: AntiPhishPolicy;

    tenant_id$: Observable<string> = this.store.pipe(
        select(selectSession),
        map((sess) => sess.session.clientId),
    );

    quarantinePolicylookup$ = this.getQuarantinePolicy();

    constructor(
        private fb: FormBuilder,
        private store: Store<any>,
    ) {
        super();
    }

    ngOnInit(): void {
        this.dispatcher();
        const schema = JSON.parse(JSON.stringify(this.baseline.schema));
        this.form = this.initForm(schema);
        this.defaultPolicy = this.data.find((res) => res.IsDefault);

        this.form.valueChanges.subscribe((res) => {
            schema.contains.properties.EnableFirstContactSafetyTips['const'] = res.EnableFirstContactSafetyTips;
            schema.contains.properties.AuthenticationFailAction['const'] = res.AuthenticationFailAction;
            schema.contains.properties.SpoofQuarantineTag['const'] = res.SpoofQuarantineTag;
            schema.contains.properties.EnableSpoofIntelligence['const'] = res.EnableSpoofIntelligence;
            schema.contains.properties.EnableViaTag['const'] = res.EnableViaTag;
            schema.contains.properties.EnableUnauthenticatedSender['const'] = res.EnableUnauthenticatedSender;

            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema)),
            });
        });
    }

    dispatcher() {
        this.tenant_id$.subscribe((res) => {
            this.store.dispatch(fetchExoQuarantinePolicy({ _tenant: res }));
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.defaultPolicy = this.data.find((res) => res.IsDefault);
    }

    initForm(schema: any): FormGroup {
        return this.fb.group({
            EnableFirstContactSafetyTips: [schema?.contains?.properties?.EnableFirstContactSafetyTips?.const],
            AuthenticationFailAction: [schema?.contains?.properties?.AuthenticationFailAction?.const],
            SpoofQuarantineTag: [schema?.contains?.properties?.SpoofQuarantineTag?.const],
            EnableSpoofIntelligence: [schema?.contains?.properties?.EnableSpoofIntelligence?.const],
            EnableViaTag: [schema?.contains?.properties?.EnableViaTag?.const],
            EnableUnauthenticatedSender: [schema?.contains?.properties?.EnableUnauthenticatedSender?.const],
        });
    }

    getQuarantinePolicy(): Observable<QuarantinePolicy[]> {
        return this.tenant_id$.pipe(
            switchMap((tid) =>
                combineLatest([
                    this.store.select(client(tid).powershell.exoQuarantinePolicy.all),
                    this.store.select(client(tid).powershell.exoQuarantinePolicy.status),
                ]),
            ),
            first(([data, status]) => status.loaded),
            map(([data]) => data),
        );
    }

    get formValue() {
        return this.form.value;
    }
}
