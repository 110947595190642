<form [formGroup]="form" class="mat-typography" *ngIf="defaultPolicy && form">
    <table>
        <tr>
            <th>
                Field
            </th>

            <th>
                Current Value
            </th>

            <th>
                Desired Value
            </th>

            <th>
                Status
            </th>
        </tr>

        <tr>
            <td>
                <p class="field-header">
                    Enable Internal Sender Admin Notifications  <mat-icon matTooltip=" Enable this to alert an administrator if internal senders are blocked from sending emails because malware sending has been detected from the internal sender's email account."  class="suffix-icon" matSuffix>info</mat-icon>
                </p>
            </td>

            <td>
                {{defaultPolicy?.EnableInternalSenderAdminNotifications ? 'Enabled' : 'Disabled'}}
            </td>

            <td>
                <div class="form">
                    <div class="field boolean">
                        <mat-form-field appearance="fill">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="EnableInternalSenderAdminNotifications">
                                <mat-option [value]="true">Enable</mat-option>
                                <mat-option [value]="false">Disable</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </td>

            <td>
                <sway-baseline-status
                    [hasError]="defaultPolicy.EnableInternalSenderAdminNotifications !== formValue.EnableInternalSenderAdminNotifications">
                </sway-baseline-status>
            </td>
        </tr>

        <tr [class.require-item]="formValue.EnableInternalSenderAdminNotifications === true && !formValue.InternalSenderAdminAddress">
            <td>
                <p class="field-header">
                    Notification Email Address  <mat-icon  matTooltip="Administrator email address that will be notified about undelivered messages from internal senders when the internal senders are blocked because malware sending has been detected." class="suffix-icon" matSuffix>info</mat-icon>

                </p>
            </td>

            <td>
                {{defaultPolicy.InternalSenderAdminAddress}}
            </td>

            <td>
                <app-single-filtered-list [initial]="formValue.InternalSenderAdminAddress"
                    (selected)="onSelectEmail($event)" [placeholder]="'Notification Email Address'"
                    [options]="emailsLookups"></app-single-filtered-list>

                    <mat-error *ngIf="form.get('InternalSenderAdminAddress').hasError('email')">
                        This value must either be blank or a valid email address.
                    </mat-error>
            </td>

            <td>
                <sway-baseline-status
                    *ngIf="!defaultPolicy.InternalSenderAdminAddress && !formValue.InternalSenderAdminAddress; else showError"
                    [hasError]="false"></sway-baseline-status>

                <ng-template #showError>
                    <sway-baseline-status
                        [hasError]="defaultPolicy.InternalSenderAdminAddress !== formValue.InternalSenderAdminAddress?.trim()">
                    </sway-baseline-status>
                </ng-template>
            </td>

        </tr>
    </table>
</form>