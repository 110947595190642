import { combineReducers } from '@ngrx/store';
import * as roles from './roles/reducer';
import * as subs from './subscriptions/reducer';

export const featureKey = 'status';

export interface State {
    [roles.featureKey]: roles.State;
    [subs.featureKey]: subs.State;
}

export const initialState = {
    [roles.featureKey]: roles.initialState,
    [subs.featureKey]: subs.initialState,
};

export const reducer = combineReducers({
    [roles.featureKey]: roles.reducer,
    [subs.featureKey]: subs.reducer,
});

export function CompareRoles(desired: string, actual: string) {
    if (desired === actual) {
        return true;
    }
    // elevate 'actual' value to see if it matches the 'ReadWrite' version
    if (desired === actual.replace('.Read.', '.ReadWrite.')) {
        return true;
    }
    return false;
}
