import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base/base.component';
import { CAPRestrictAzureAccessBaselineSchema } from './model';
import { BehaviorSubject, filter, skip } from 'rxjs';
import { ConditionalAccessPolicy } from '@microsoft/microsoft-graph-types-beta';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { InitCapBaselineFormItem } from '../shared/cap-form';

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../../styles/baseline-style.scss'],
})
export class BaselineComponent extends BaseComponent implements OnInit {
    schemaFunc = CAPRestrictAzureAccessBaselineSchema;
    schema$: BehaviorSubject<any>;
    policy$: BehaviorSubject<ConditionalAccessPolicy> = new BehaviorSubject(null);

    constructor(private fb: FormBuilder) {
        super();
    }

    initForm(schema): FormGroup {
        return InitCapBaselineFormItem(schema, this.fb);
    }

    ngOnInit(): void {
        this.form = this.initForm(this.baseline.schema);

        this.schema$ = new BehaviorSubject(this.baseline.schema);

        this.grantController.valueChanges.subscribe((grant) => {
            const schema = JSON.parse(JSON.stringify(this.baseline.schema));
            schema.contains.properties.grantControls.properties.builtInControls.items.const = grant;
            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema)),
            });
        });

        this.schema$
            .pipe(
                filter((res) => !!res),
                skip(1),
            )
            .subscribe((schema) => {
                // skip first emit

                this.baselineChange.next({
                    ...this.baseline,
                    schema: JSON.parse(JSON.stringify(schema)),
                });
            });
    }

    onBaselineErrorChange(e) {
        this.baselineErrorChange.next(e);
    }

    get grantController() {
        return this.form?.get('grant') as FormControl;
    }
}
