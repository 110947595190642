import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap, Observable, of, retry, switchMap, throwError } from 'rxjs';
import { EncodeStateParam } from 'src/app/modules/auth/utils/state-param';
import { client } from 'src/app/stores/client';
import { reloadGraphRoles } from 'src/app/stores/client/octiga/status/roles/actions';
import { selectSession } from 'src/app/stores/root.store';
import { environment } from 'src/environments/environment';
import { PermissionDialogComponent } from './permission-dialog/permission-dialog.component';

export function BuildAuthorisationLink(tenant: string, msp_id: string) {
    const return_url = environment.host + '/auth/permission-redirect';

    const params: { [key: string]: string } = {
        client_id: environment.oauth.client.client_id,
        redirect_uri: environment.oauth.client.authorise_redirect_uri,
        scope: 'https://graph.microsoft.com/.default',
        response_type: 'code',
        response_mode: 'query',
        state: EncodeStateParam({ return_url, msp_id }),
    };
    const path = `https://login.microsoftonline.com/${tenant}/adminconsent?`;
    const query = Object.keys(params)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
    return path + query;
}

@Injectable({
    providedIn: 'root',
})
export class PermissionGuard implements CanActivate, CanActivateChild {
    constructor(
        private store: Store,
        private dialog: MatDialog,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        const scopes = route.data.scopes as string[];
        const tenant_id = route.params.tenant;
        const consented = route.queryParams.consented;

        if (consented) {
            return this.hasPermissions(tenant_id, scopes).pipe(
                mergeMap((res) => {
                    console.log('res', res);
                    if (!res) {
                        this.store.dispatch(reloadGraphRoles({ _tenant: tenant_id }));
                        return throwError(() => 'not propagated');
                    }
                    return of(res);
                }),
                retry({
                    count: 5,
                    delay: 3000,
                }),
            );
        }

        return this.hasPermissions(tenant_id, scopes).pipe(
            switchMap((res) => (res ? of(true) : this.showDialog(route, state))),
        );
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.canActivate(childRoute, state);
    }

    private getSession() {
        return this.store.select(selectSession).pipe(filter((sess) => !!sess.session?.clientId));
    }

    private hasPermissions(tenant: string, scopes: string[]) {
        return this.store.select(client(tenant).octiga.status.roles.status).pipe(
            filter((status) => status.loaded),
            switchMap((_) => this.store.select(client(tenant).octiga.status.roles.hasScopes(scopes))),
        );
    }

    private showDialog(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const tenant_id = route.params.tenant;

        return this.getSession().pipe(
            switchMap((sess) => {
                const url = `${state.url}${state.url.indexOf('?') < 0 ? '?' : '&'}consented=true`;
                const ref = this.dialog.open(PermissionDialogComponent, {
                    width: '40rem',
                    data: {
                        link: BuildAuthorisationLink(tenant_id, sess.session.msp_id),
                        tenant: tenant_id,
                    },
                });
                return ref.afterClosed().pipe(
                    map((_) => {
                        if (this.router.url === '/') {
                            return this.router.createUrlTree(['msp', 'dashboard']);
                        }
                        return false;
                    }),
                );
            }),
        );
    }
}
