import { createAction, props } from '@ngrx/store';

export const loadMspCwmPriorities = createAction('[Msp/CWM] Load Msp Cwm Priorities');

export const loadMspCwmPrioritiesSuccess = createAction(
    '[Msp/CWM] Load Msp Cwm Priorities Success',
    props<{ data: any }>(),
);

export const loadMspCwmPrioritiesFailure = createAction(
    '[Msp/CWM] Load Msp Cwm Priorities Failure',
    props<{ error: any }>(),
);
