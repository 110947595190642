import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';

export interface OkDialogData extends MatDialogConfig {
    title: string;
    description?: string;
    boldMessage?: string;
    close_button_text?: string;
    close_button_class?: string;
    confirm_button_text?: string;
    confirm_button_class?: string;
}

@Component({
    selector: 'app-ok-dialog',
    templateUrl: './ok-dialog.component.html',
    styleUrls: ['./ok-dialog.component.scss'],
})
export class OkDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<OkDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: OkDialogData,
    ) {}

    ngOnInit() {}

    closeClick(): void {
        this.dialogRef.close();
    }
}
