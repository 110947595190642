import { updateCasMailbox } from 'src/app/stores/client/powershell/exo/cas-mailbox/actions';
import { CasMailbox } from 'src/app/stores/client/powershell/exo/cas-mailbox/model';
import { BooleanFormComponent, BooleanValidatorComponent } from '../generic/boolean';

export const fieldName = 'EwsEnabled';
export const href =
    'https://learn.microsoft.com/en-us/exchange/client-developer/exchange-web-services/how-to-control-access-to-ews-in-exchange';
export { BooleanFormComponent as FormComponent, BooleanValidatorComponent as ValidatorComponent };

export function Remediate(_tenant: string, current: CasMailbox, schema: any) {
    if (schema.properties[fieldName]?.const === true) {
        const data = {
            Identity: current.ExternalDirectoryObjectId,
            [fieldName]: true,
        };
        return updateCasMailbox({ _tenant, data, field: fieldName });
    }

    if (schema.properties[fieldName]?.const === false) {
        const data = {
            Identity: current.ExternalDirectoryObjectId,
            [fieldName]: false,
        };
        return updateCasMailbox({ _tenant, data, field: fieldName });
    }
}
