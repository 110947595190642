import { createAction, props } from '@ngrx/store';
import { QuarantinePolicy } from './model';

export const fetchExoQuarantinePolicy = createAction(
    '[Client/Pwsh/Exo] Fetch QuarantinePolicy',
    props<{ _tenant: string }>(),
);

export const fetchExoQuarantinePolicySuccess = createAction(
    '[Client/Pwsh/Exo] Fetch QuarantinePolicy Success',
    props<{ _tenant: string; data: QuarantinePolicy[] }>(),
);

export const fetchExoQuarantinePolicyFailure = createAction(
    '[Client/Pwsh/Exo] Fetch QuarantinePolicy Failure',
    props<{ _tenant: string; error: any }>(),
);
