import { createAction, props } from '@ngrx/store';
import { IP } from './model';

export const loadIps = createAction('[MspIP] Load');

export const loadIpsSuccess = createAction('[MspIP] Load Success', props<{ ips: IP[] }>());

export const loadIpsFailure = createAction('[MspIP] Load Failure', props<{ error: any }>());

export const createIp = createAction('[MspIP] Create', props<{ ip: Partial<IP> }>());

export const createIpSuccess = createAction('[MspIP] Create Success', props<{ ip: IP }>());

export const createIpFailure = createAction('[MspIP] Create Failure', props<{ error: any }>());

export const deleteIp = createAction('[MspIP] Delete', props<{ id: string }>());

export const deleteIpSuccess = createAction('[MspIP] Delete Success', props<{ id: string }>());

export const deleteIpFailure = createAction('[MspIP] Delete Failure', props<{ id: string; error: any }>());
