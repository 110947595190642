import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SpinnerService {
    spinnerSubject$ = new BehaviorSubject<boolean>(null);
    spinner$ = this.spinnerSubject$.asObservable();

    startSpinner() {
        this.spinnerSubject$.next(true);
    }

    stopSpinner() {
        this.spinnerSubject$.next(false);
    }

    getSpinnerStatus(): Observable<boolean> {
        return this.spinner$;
    }
}
