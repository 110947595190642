import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'app-spinner',
    template: `
        <div class="spinner">
            <p>loading.</p>
            <mat-spinner></mat-spinner>
            <span>Please wait while we load your msp</span>
        </div>
    `,
    styles: [
        `
            .spinner {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            span {
                z-index: 10;
            }
            mat-spinner {
                margin-top: 1.5rem;
                z-index: 100;
            }
        `,
    ],
})
export class SpinnerComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
