import { Injectable } from '@angular/core';
import { catchError, map, ReplaySubject, throwError } from 'rxjs';

function Base64ToArrayBuffer(base64: string) {
    const binary_string = atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
}

@Injectable({
    providedIn: 'root',
})
export class BrotliService {
    public brotli = new ReplaySubject<any>();

    private decoder = new TextDecoder();

    constructor() {
        this.init();
    }

    private async init() {
        const brotli = await import('brotli-dec-wasm');
        this.brotli.next(brotli);
    }

    public decompress<T = any>(data: string) {
        return this.brotli.pipe(
            map((brotli) => {
                const buff_array = Base64ToArrayBuffer(data); // base64 string to buffer (compressed)

                const decompressed = brotli.brotliDec(buff_array); // compressed buffer to uncompressed buffer

                const decoded = this.decoder.decode(decompressed); // uncompressed buffer to utf-8 string

                return JSON.parse(decoded) as T;
            }),
            catchError((error) => {
                console.error(error);
                return throwError(() => new Error(error));
            }),
        );
    }
}
