import { createAction, props } from '@ngrx/store';

export const createCustomer = createAction('[Billing] Create Customer', props<{ data: { email: string } }>());

export const createCustomerSuccess = createAction('[Billing] Create Customer Success', props<{ data: any }>());

export const createCustomerFailure = createAction('[Billing] Create Customer Failure', props<{ error: any }>());

export const createSubscription = createAction(
    '[Billing] Create Subscription',
    props<{ data: { items: Array<{ price: string }> } }>(),
);

export const createSubscriptionSuccess = createAction('[Billing] Create Subscription Success', props<{ data: any }>());

export const createSubscriptionFailure = createAction('[Billing] Create Subscription Failure', props<{ error: any }>());

export const setPaymentMethod = createAction(
    '[Billing] Set Payment Method',
    props<{ data: { paymentMethodId: string } }>(),
);

export const setPaymentMethodSuccess = createAction('[Billing] Set Payment Method Success', props<{ data: any }>());

export const setPaymentMethodFailure = createAction('[Billing] Set Payment Method Failure', props<{ error: any }>());

export const updateCustomer = createAction('[Billing] Update Customer', props<{ data: { email: string } }>());

export const updateCustomerSuccess = createAction('[Billing] Update Customer Success', props<{ data: any }>());

export const updateCustomerFailure = createAction('[Billing] Update Customer Failure', props<{ error: any }>());
