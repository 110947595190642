import { createAction, props } from '@ngrx/store';
import { OrganizationConfig } from './model';

export const fetchExoOrganizationConfig = createAction(
    '[Client/Pwsh/Exo] Fetch OrganizationConfig',
    props<{ _tenant: string }>(),
);

export const fetchExoOrganizationConfigSuccess = createAction(
    '[Client/Pwsh/Exo] Fetch OrganizationConfig Success',
    props<{ _tenant: string; data: OrganizationConfig }>(),
);

export const fetchExoOrganizationConfigFailure = createAction(
    '[Client/Pwsh/Exo] Fetch OrganizationConfig Failure',
    props<{ _tenant: string; error: any }>(),
);

export const updateExoOrganizationConfig = createAction(
    '[Client/Pwsh/Exo] Update OrganizationConfig',
    props<{ _tenant: string; data: Partial<OrganizationConfig> }>(),
);

export const updateExoOrganizationConfigSuccess = createAction(
    '[Client/Pwsh/Exo] Update OrganizationConfig Success',
    props<{ _tenant: string; data: Partial<OrganizationConfig> }>(),
);

export const updateExoOrganizationConfigFailure = createAction(
    '[Client/Pwsh/Exo] Update OrganizationConfig Failure',
    props<{ _tenant: string; error: any }>(),
);
