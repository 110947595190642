export const Tooltips = {
    osMinimumVersion: 'Select the oldest OS version a device can have.',
    osMaximumVersion: 'Select the newest OS version a device can have.',
    osMinimumBuildVersion:
        'Select the oldest OS version a mobile device can have. The operating system version is defined as major.minor.build.revision.',
    osMaximumBuildVersion:
        'Select the newest OS version a mobile device can have. The operating system version is defined as major.minor.build.revision.',

    passwordRequired:
        'This setting specifies whether to require users to enter a password before access is granted to information on their devices. Recommended value: Require.',
    passwordBlockSimple:
        'This setting specifies whether to block mobile devices from using simple password sequences, such as 1234 or 1111. Recommended Value: Block',
};
