import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, distinct, map, mergeMap } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';

import * as actions from './actions';
import { RemoteDomain } from './model';
import { retry } from 'src/app/pipes/retry.pipe';

@Injectable()
export class ExoRemoteDomainEffects {
    fetchRemoteDomains$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.fetchExoRemoteDomain),
            distinct((action) => action._tenant),
            mergeMap((action) =>
                this.get(action._tenant).pipe(
                    retry(3000, 3, 'remote domain timeout'),
                    map((data: RemoteDomain[]) =>
                        actions.fetchExoRemoteDomainSuccess({
                            _tenant: action._tenant,
                            data: data.find((d) => d.Identity === 'Default'),
                        }),
                    ),
                    catchError((error) => of(actions.fetchExoRemoteDomainFailure({ _tenant: action._tenant, error }))),
                ),
            ),
        ),
    );

    private get(tenant: string): Observable<RemoteDomain[]> {
        return this.ajax.get(tenant, '/api/microsoft/powershell/exo/remote-domain');
    }

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateExoRemoteDomain),
            mergeMap((action) =>
                this.put(action._tenant, action.data).pipe(
                    map((_) => actions.updateExoRemoteDomainSuccess({ _tenant: action._tenant, data: action.data })),
                    catchError((error) =>
                        of(
                            actions.updateExoRemoteDomainFailure({
                                _tenant: action._tenant,
                                error: { message: error.message, trace: error.trace },
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    private put(tenant: string, body: Partial<RemoteDomain>): Observable<RemoteDomain[]> {
        return this.ajax.put(tenant, '/api/microsoft/powershell/exo/remote-domain', body);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
    ) {}
}
