import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, createSelector, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { TargetUser } from './model';

export const featureKey = 'TargetUser';

export interface State extends EntityState<TargetUser>, Status {}

export const adapter: EntityAdapter<TargetUser> = createEntityAdapter<TargetUser>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(actions.loadTargetUser, (state) => ({ ...state, loading: true })),
    on(actions.loadTargetUserSuccess, (state, action) =>
        adapter.setAll(action.users, { ...state, loading: false, loaded: true }),
    ),
    on(actions.loadTargetUserFailure, (state, { error }) => ({ ...state, error })),

    on(actions.createTargetUser, (state) => ({ ...state, creating: true })),
    on(actions.createTargetUserSuccess, (state, action) => adapter.addOne(action.user, { ...state, creating: false })),
    on(actions.createTargetUserFailure, (state, { error }) => ({ ...state, error, creating: false })),

    on(actions.deleteTargetUser, (state) => ({ ...state, deleting: true })),
    on(actions.deleteTargetUserSuccess, (state, action) => adapter.removeOne(action.id, { ...state, deleting: false })),
    on(actions.deleteTargetUserFailure, (state, { error }) => ({ ...state, error, deleting: false })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectEntity = (id: string) => createSelector(selectEntities, (entities) => entities[id]);
