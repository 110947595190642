import { createAction, props } from '@ngrx/store';

import { ExoTransportRule } from './model';

export const loadExoTransportRules = createAction('[Pwsh/Exo] Load TransportRules', props<{ _tenant: string }>());

export const loadExoTransportRulesSuccess = createAction(
    '[Pwsh/Exo] Load TransportRules Success',
    props<{ _tenant: string; data: ExoTransportRule[] }>(),
);

export const loadExoTransportRulesFailure = createAction(
    '[Pwsh/Exo] Load TransportRules Failure',
    props<{ _tenant: string; error: any }>(),
);

export const updateExoTransportRule = createAction(
    '[Pwsh/Exo] Update ExoTransportRule',
    props<{ _tenant: string; exoTransportRule: Partial<ExoTransportRule> }>(),
);

export const updateExoTransportRuleFailure = createAction(
    '[Pwsh/Exo] Update ExoTransportRule Failure',
    props<{ _tenant: string; error: any }>(),
);

// Customize: mircosoft call
export const createExoTransportRule = createAction(
    '[Pwsh/Exo] Create ExoTransportRule',
    props<{ _tenant: string; exoTransportRule: Partial<ExoTransportRule> }>(),
);

export const createExoTransportRuleFailure = createAction(
    '[Pwsh/Exo] Create ExoTransportRule Failure',
    props<{ _tenant: string; error: any }>(),
);

export const deleteExoTransportRule = createAction(
    '[Pwsh/Exo] Delete ExoTransportRule',
    props<{ _tenant: string; Identity: string }>(),
);

export const deleteExoTransportRuleSuccess = createAction(
    '[Pwsh/Exo] Delete ExoTransportRule Success',
    props<{ _tenant: string; identity: string }>(),
);

export const deleteExoTransportRuleFailure = createAction(
    '[Pwsh/Exo] Delete ExoTransportRule Failure',
    props<{ _tenant: string; error: any }>(),
);

export const enableExoTransportRule = createAction(
    '[Pwsh/Exo] Enable ExoTransportRule',
    props<{ _tenant: string; Identity: string }>(),
);

export const enableExoTransportRuleSuccess = createAction(
    '[Pwsh/Exo] Enable ExoTransportRule Success',
    props<{ _tenant: string; exoTransportRule: ExoTransportRule }>(),
);

export const enableExoTransportRuleFailure = createAction(
    '[Pwsh/Exo] Enable ExoTransportRule Failure',
    props<{ _tenant: string; error: any }>(),
);

export const disableExoTransportRule = createAction(
    '[Pwsh/Exo] Disable ExoTransportRule',
    props<{ _tenant: string; Identity: string }>(),
);

export const disableExoTransportRuleSuccess = createAction(
    '[Pwsh/Exo] Disable ExoTransportRule Success',
    props<{ _tenant: string; exoTransportRule: ExoTransportRule }>(),
);

export const disableExoTransportRuleFailure = createAction(
    '[Pwsh/Exo] Disable ExoTransportRule Failure',
    props<{ _tenant: string; error: any }>(),
);
