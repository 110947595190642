import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, distinct, map, mergeMap } from 'rxjs/operators';
import { MspAjaxService } from 'src/app/services/ajax/msp-ajax.service';
import * as actions from './actions';
import { EventRule } from './model';

@Injectable()
export class MspEventRulesEffects {
    loadEventRules$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadEventRules),
            distinct((action) => action.type),
            mergeMap(() =>
                this.get().pipe(
                    map((rules) => actions.loadEventRulesSuccess({ rules })),
                    catchError((error) => of(actions.loadEventRulesFailure({ error }))),
                ),
            ),
        ),
    );

    createEventRule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.createEventRule),
            mergeMap(({ rule }) =>
                this.post(rule).pipe(
                    map((rule) => actions.createEventRuleSuccess({ rule })),
                    catchError((error) => of(actions.createEventRuleFailure({ error }))),
                ),
            ),
        ),
    );

    updateEventRule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateEventRule),
            mergeMap(({ rule }) =>
                this.put(rule.id, rule).pipe(
                    map((updated) => actions.updateEventRuleSuccess({ rule: updated, previous: rule })),
                    catchError((error) => of(actions.updateEventRuleFailure({ error }))),
                ),
            ),
        ),
    );

    deleteEventRule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteEventRule),
            mergeMap(({ rule }) =>
                this.delete(rule.id).pipe(
                    map((_) => actions.deleteEventRuleSuccess({ rule })),
                    catchError((error) => of(actions.deleteEventRuleFailure({ error }))),
                ),
            ),
        ),
    );

    get() {
        return this.ajax.get<EventRule[]>('/api/octiga/v2/msp/event-rule');
    }

    post(body: Partial<EventRule>) {
        return this.ajax.post<EventRule>('/api/octiga/v2/msp/event-rule', body);
    }

    put(id: string, body: Partial<EventRule>) {
        return this.ajax.put<EventRule>(`/api/octiga/v2/msp/event-rule/${id}`, body);
    }

    delete(id: string) {
        return this.ajax.delete<any>(`/api/octiga/v2/msp/event-rule/${id}`);
    }

    constructor(
        private actions$: Actions,
        private ajax: MspAjaxService,
    ) {}
}
