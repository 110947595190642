import { User } from '@microsoft/microsoft-graph-types-beta';
import { combineLatest, map, Observable, take, takeWhile, tap } from 'rxjs';
import { ServicePrincipal_AppRoleAssignments } from './client/graph/servicePrincipal/model';

// Summary
export function CustomActionDeduplicateOperator<T>(action: T, fetched_num_days: Observable<number>) {
    return fetched_num_days.pipe(
        takeWhile((fetched_num_days) => action['num_days'] > fetched_num_days),
        map((_) => action),
    );
}

export function OctigaServicePrincipalActionDeduplicateOperator<T>(
    action: T,
    data: Observable<ServicePrincipal_AppRoleAssignments[]>,
) {
    return data.pipe(
        takeWhile((data) => data === undefined || data.length === 0),
        map((_) => action),
    );
}

export function InboxRulesActionDeduplicateOperator<T>(
    action: T,
    loaded: Observable<boolean>,
    current_users: Observable<any>,
    total_users: Observable<any>,
) {
    return combineLatest([loaded, current_users, total_users.pipe(map((users) => users.filter(UserHasMailbox)))]).pipe(
        tap(([loaded, current_users, total_users]) => {
            console.log('loaded', loaded, 'current', current_users.length + '/' + total_users.length);
        }),
        takeWhile(([loaded, current_users, total_users]) => !loaded || current_users.length !== total_users.length),
        take(1),
        map((_) => action),
    );
}

export function UserHasMailbox(user: User): boolean {
    const exchangePlans = user.assignedPlans.filter(
        (plan) => plan.service.toLowerCase() === 'exchange' && plan.capabilityStatus !== 'Deleted',
    );

    return user.mail !== null && user.userType !== 'Guest' && exchangePlans.length > 0;
}
