import { createAction, props } from '@ngrx/store';
import { EventRule } from './model';

export const loadEventRules = createAction('[MspEventRules] Load');

export const loadEventRulesSuccess = createAction('[MspEventRules] Load Success', props<{ rules: EventRule[] }>());

export const loadEventRulesFailure = createAction('[MspEventRules] Load Failure', props<{ error: any }>());

export const createEventRule = createAction('[MspEventRules] Create', props<{ rule: Partial<EventRule> }>());

export const createEventRuleSuccess = createAction('[MspEventRules] Create Success', props<{ rule: EventRule }>());

export const createEventRuleFailure = createAction('[MspEventRules] Create Failure', props<{ error: any }>());

// "previous" rule is important for checking what summaries to update

export const updateEventRule = createAction(
    '[MspEventRules] Update',
    props<{ rule: EventRule; previous: EventRule }>(),
);

export const updateEventRuleSuccess = createAction(
    '[MspEventRules] Update Success',
    props<{ rule: EventRule; previous: EventRule }>(),
);

export const updateEventRuleFailure = createAction('[MspEventRules] Update Failure', props<{ error: any }>());

export const deleteEventRule = createAction('[MspEventRules] Delete', props<{ rule: EventRule }>());

export const deleteEventRuleSuccess = createAction('[MspEventRules] Delete Success', props<{ rule: EventRule }>());

export const deleteEventRuleFailure = createAction('[MspEventRules] Delete Failure', props<{ error: any }>());
