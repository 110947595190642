import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TokenService {
    private token: string | null = null;
    private exp: number | null = null;
    private refreshingToken = false;
    private refreshTokenSubject = new BehaviorSubject<string | null>(null);

    constructor(private httpClient: HttpClient) {}

    private isTokenExpired(): boolean {
        const currentTime = Math.floor(Date.now() / 1000);
        return !this.exp || this.exp < currentTime;
    }

    public getToken(): BehaviorSubject<string> {
        if (!this.token || this.isTokenExpired()) {
            this.refreshingToken = true;
            this.refreshTokenSubject.next(null);

            this.httpClient.get<{ jwt: string; exp: number }>('/api/microsoft/oauth/token').subscribe(
                ({ jwt, exp }) => {
                    this.refreshingToken = false;
                    this.refreshTokenSubject.next(jwt);
                },
                (error) => {
                    this.refreshingToken = false;
                    this.refreshTokenSubject.next(null);
                },
            );
        }

        return this.refreshTokenSubject;
    }
}
