import { createAction, props } from '@ngrx/store';

export const generateTasks = createAction(
    '[Root/ETL] Generate Tasks',
    props<{ _tenant: string; scanType: string; force?: boolean; start?: string; end?: string }>(),
);

export const generateTasksSuccess = createAction(
    '[Root/ETL] Generate Tasks Success',
    props<{ _tenant: string; data: any }>(),
);

export const generateTasksFailure = createAction(
    '[Root/ETL] Generate Tasks Failure',
    props<{ _tenant: string; error: any }>(),
);
