interface ConditionalAccessConditionSetSchema {
    applications?: any;
    clientApplications?: any;
    clientAppTypes?: any;
    devices?: any;
    deviceStates?: any;
    locations?: any;
    platforms?: any;
    servicePrincipalRiskLevels?: any;
    signInRiskLevels?: any;
    userRiskLevels?: any;
    users?: any;
}

export function getCAPBaselineDisplayName(schema) {
    return schema?.contains?.properties?.displayName?.const || schema?.not?.contains?.properties?.displayName?.const;
}

export interface ConditionalAccessPolicySchema {
    conditions?: ConditionalAccessConditionSetSchema;
    displayName?: string;
    grantControls?: any;
    sessionControls?: any;
    state?: any;
}

export function createCapPolicySchema(form, policy: ConditionalAccessPolicySchema = {}) {
    const include = {
        config: form.includeUsersConfig,
        roles: form.includeRoles,
        users: form.includeUsers,
        GuestsOrExternalUsers: form.includeGuestsOrExternalUsers,
        groups: form.includeGroups,
    };

    const exclude = {
        roles: form.excludeRoles,
        users: form.excludeUsers,
        groups: form.excludeGroups,
        GuestsOrExternalUsers: form.excludeGuestsOrExternalUsers,
    };

    const settings = {
        ...form.excludeUserSettings,
        ...form.includeUserSettings,
    };

    return {
        type: 'array',
        contains: {
            type: 'object',
            properties: {
                state: {
                    type: 'string',
                    const: form.state,
                },

                displayName: {
                    type: 'string',
                    const: form.displayName,
                },

                conditions: {
                    type: 'object',
                    properties: {
                        users: {
                            type: 'object',
                            properties: {
                                includeRoles: getItems(include.roles, include.config, settings.includeRoles),
                                includeGuestsOrExternalUsers: getIncludeGuestsOrExternalUsers(
                                    include.GuestsOrExternalUsers,
                                    settings.includeGuestsOrExternalUsers,
                                    include.config,
                                ),
                                includeUsers: getIncludeUsersItems(
                                    include.users,
                                    include.config,
                                    settings.includeUsers,
                                ),
                                includeGroups: getItems(include.groups, include.config, settings.includeGroups),

                                excludeRoles: getItems(exclude.roles, 'GuestsOrExternalUsers', settings.excludeRoles),
                                excludeUsers: getItems(exclude.users, 'GuestsOrExternalUsers', settings.excludeUsers),
                                excludeGroups: getItems(
                                    exclude.groups,
                                    'GuestsOrExternalUsers',
                                    settings.excludeGroups,
                                ),

                                excludeGuestsOrExternalUsers: getIncludeGuestsOrExternalUsers(
                                    exclude.GuestsOrExternalUsers,
                                    settings.excludeGuestsOrExternalUsers,
                                    'GuestsOrExternalUsers',
                                ),
                            },
                        },

                        clientAppTypes: policy.conditions?.clientAppTypes,
                        platforms: policy.conditions?.platforms,
                        applications: policy.conditions?.applications,
                        signInRiskLevels: policy.conditions?.signInRiskLevels,
                        locations: policy.conditions?.locations,
                    },
                },

                sessionControls: policy.sessionControls,
                grantControls: policy.grantControls,
            },

            required: ['state', 'displayName'],
        },
    };
}

function getIncludeUsersItems(
    items: string[],
    includeUsersConfig: 'All' | 'None' | 'GuestsOrExternalUsers',
    isIncluded: boolean,
) {
    if (includeUsersConfig === 'GuestsOrExternalUsers') {
        if (items.length > 0 && isIncluded) {
            return {
                type: 'array',
                items: {
                    enum: [...items],
                    type: 'string',
                },
                minItems: items.length,
                maxItems: items.length,
                uniqueItems: true,
            };
        } else {
            return {
                type: 'array',
                maxItems: 0,
            };
        }
    } else if (includeUsersConfig === 'All' || includeUsersConfig === 'None') {
        return {
            type: 'array',
            maxItems: 1,
            minItems: 1,
            items: {
                type: 'string',
                enum: [includeUsersConfig],
            },
        };
    }
}

function getItems(items: string[], includeUsersConfig: 'All' | 'None' | 'GuestsOrExternalUsers', isIncluded: boolean) {
    if (includeUsersConfig === 'All' || includeUsersConfig === 'None' || !isIncluded) {
        return {
            type: 'array',
            maxItems: 0,
        };
    } else if (items.length > 0) {
        return {
            type: 'array',
            items: {
                enum: [...items],
                type: 'string',
            },
            minItems: items.length,
            maxItems: items.length,
            uniqueItems: true,
        };
    } else {
        return {
            type: 'array',
            maxItems: 0,
        };
    }
}

function getIncludeGuestOrExternalUsersPattern(items: string[]) {
    let reg = '';

    if (items instanceof Array) {
        items.forEach((item) => {
            reg += `(?=.*${item})`;
        });
    }

    return reg;
}

function getIncludeGuestsOrExternalUsers(
    includeGuestsOrExternalUsers: string[],
    isIncluded: boolean,
    includeUsersConfig: string,
) {
    if (includeUsersConfig === 'All' || includeUsersConfig === 'None') {
        return {
            type: 'null',
        };
    } else if (includeGuestsOrExternalUsers?.length > 0 && isIncluded) {
        return {
            type: 'object',

            properties: {
                guestOrExternalUserTypes: {
                    type: 'string',
                    pattern: getIncludeGuestOrExternalUsersPattern(includeGuestsOrExternalUsers),
                    minLength: includeGuestsOrExternalUsers ? includeGuestsOrExternalUsers?.join(',')?.length : 0,
                    maxLength: includeGuestsOrExternalUsers ? includeGuestsOrExternalUsers?.join(',')?.length : 0,
                },
            },

            required: ['guestOrExternalUserTypes'],
        };
    } else {
        return {
            type: 'null',
        };
    }
}

export function NoCapSchema(displayName: string) {
    return {
        type: 'array',
        not: {
            contains: {
                type: 'object',
                properties: {
                    displayName: {
                        type: 'string',
                        const: displayName,
                    },
                },
            },
        },
    };
}
