import { Injectable } from '@angular/core';
import { AppRoleAssignment } from '@microsoft/microsoft-graph-types-beta';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, last, map, mergeMap } from 'rxjs/operators';
import { retry } from 'src/app/pipes/retry.pipe';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as actions from './actions';

@Injectable()
export class GraphAppRoleAssignmentsEffects {
    loadGraphAppRoleAssignments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadAppRoleAssignments),
            mergeMap(({ _tenant, id }) =>
                this.get(_tenant, id).pipe(
                    retry(3000, 3, 'graph timeout'),
                    last(),
                    map((data: any) => actions.loadAppRoleAssignmentsSuccess({ _tenant, roles: data.value })),
                    catchError((error) => of(actions.loadAppRoleAssignmentsFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    private get(tenant: string, id: string) {
        return this.ajax.get<{ value: AppRoleAssignment[] }>(
            tenant,
            `/api/microsoft/graph/servicePrincipals/${id}/appRoleAssignments`,
        );
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
    ) {}
}
