import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, of } from 'rxjs';
import { catchError, concatMap, expand, map, mergeMap, reduce } from 'rxjs/operators';

import { MspAjaxService } from 'src/app/services/ajax/msp-ajax.service';
import { updateMsp } from '../../msp/actions';
import * as actions from './actions';

@Injectable()
export class DattoConnectionEffects {
    getDattoData() {
        return combineLatest([
            this.ajax.get('/api/octiga/v2/datto/tickets/entityinformation/fields').pipe(map((res) => res.fields)),
            this.ajax.get('/api/octiga/v2/datto/ticketnotes/entityinformation/fields').pipe(map((res) => res.fields)),
            this.getCompanies(),
        ]).pipe(map(([tickets, ticketsNotes, companies]) => ({ tickets, ticketsNotes, companies })));
    }

    getCompanies() {
        return this.ajax.get('/api/octiga/v2/datto/companies').pipe(
            expand((res) => {
                if (res.pageDetails?.nextPageUrl) {
                    return this.ajax.get(res.pageDetails.nextPageUrl);
                }
                return EMPTY;
            }),
            reduce((acc, data: any) => acc.concat(data.items), []),
        );
    }

    loadMspDattoConnection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadMspDattoConnection),
            mergeMap(() =>
                this.ajax.get('/api/octiga/v2/msp/datto').pipe(
                    concatMap((data) => {
                        return of(actions.loadMspDattoConnectionSuccess({ data }));
                    }),
                    catchError((error) => {
                        return of(actions.loadMspDattoConnectionFailure({ error }));
                    }),
                ),
            ),
        ),
    );

    loadMspDattoConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadMspDattoConfig),
            mergeMap(() =>
                this.getDattoData().pipe(
                    // map(res=> res.fields),
                    map((data) => actions.loadMspDattoConfigSuccess({ data })),
                    catchError((error) => {
                        this.store.dispatch(updateMsp({ data: { datto: false } }));
                        return of(actions.loadMspDattoConfigFailure({ error }));
                    }),
                ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private ajax: MspAjaxService,
        private store: Store<any>,
    ) {}
}
