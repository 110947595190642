import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConditionalAccessPolicy, DirectoryRole, Group, User } from '@microsoft/microsoft-graph-types-beta';
import { ErrorObject } from 'ajv';
import { BehaviorSubject, take } from 'rxjs';
import { ManageItemsWrapperDialogComponent } from '../manage-items-wrapper-dialog/manage-items-wrapper-dialog.component';
import { CapItem, RowType } from '../model';

interface Config {
    title: string;
    allListTitle: string;
    desiredListTitle: string;
    allListComparativeTitle: string;
    type: RowType;
}

@Component({
    selector: 'sway-cap-include-items',
    templateUrl: './cap-include-items.component.html',
    styleUrls: ['./cap-include-items.component.scss'],
})
export class CapIncludeItemsComponent implements OnInit {
    @Input() readOnly;
    @Input() title: string;
    @Input() form: FormGroup;
    @Input() override: string[];
    @ViewChild('includedRolesRef') includedRolesRef;

    includeRolesDialogConfig = {
        title: 'Roles',
        allListTitle: 'Available roles',
        desiredListTitle: 'Included roles in baseline',
        allListComparativeTitle: 'Current tenant roles',
        type: RowType.includeRoles,
    };

    includedRoles = [];

    includeUsersDialogConfig = {
        title: 'Users',
        allListTitle: 'Available users',
        desiredListTitle: 'Included users in baseline',
        allListComparativeTitle: 'Current tenant users',
        type: RowType.includeUsers,
    };

    includeGroupsDialogConfig = {
        title: 'Groups',
        allListTitle: 'Available groups',
        desiredListTitle: 'Included groups in baseline',
        allListComparativeTitle: 'Current tenant groups',
        type: RowType.includeGroups,
    };

    errors = [];

    @Input() usersLookup: User[]; // TODO change
    @Input() groupsLookup: Group[]; // TODO change
    @Input() rolesLookup: DirectoryRole[]; // TODO change

    @Input() policy: ConditionalAccessPolicy;
    @Input() errors$: BehaviorSubject<ErrorObject<string, Record<string, any>, unknown>[]>;

    constructor(private dialog: MatDialog) {}

    ngOnInit(): void {
        this.errors$.subscribe((errors) => {
            const includeRoles = !!errors.find(
                (res) =>
                    res.instancePath.includes('users/includeRoles') ||
                    res.schemaPath.includes('properties/includeRoles'),
            );
            const includeUsers = !!errors.find(
                (res) =>
                    res.instancePath.includes('users/includeUsers') ||
                    res.schemaPath.includes('properties/includeUsers'),
            );
            const includeGroups = !!errors.find(
                (res) =>
                    res.instancePath.includes('users/includeGroups') ||
                    res.schemaPath.includes('properties/includeGroups'),
            );
            const includeGuestsOrExternalUsers = !!errors.find(
                (res) =>
                    res.instancePath.includes('users/includeGuestsOrExternalUsers') ||
                    res.schemaPath.includes('properties/includeGuestsOrExternalUsers'),
            );

            this.errors = [
                { type: 'includeUsers', value: includeUsers },
                { type: 'includeRoles', value: includeRoles },
                { type: 'includeGroups', value: includeGroups },
                { type: 'includeGuestsOrExternalUsers', value: includeGuestsOrExternalUsers },
            ].filter((res) => !!res.value);
        });
    }
    viewIncludedRoles() {
        const roles = this.form.get('includeRoles').value;
        if (roles?.length > 0) {
            this.includedRoles = this.rolesLookup.filter((role) => roles.find((res) => res === role.id));

            this.dialog.open(this.includedRolesRef, {
                maxHeight: '80vh',
                autoFocus: false,
            });
        }
    }

    manageItems(type: 'includeUsers' | 'includeGroups' | 'includeRoles') {
        let lookup: any = this.usersLookup;

        if (type === 'includeGroups') {
            lookup = this.groupsLookup;
        } else if (type === 'includeRoles') {
            lookup = this.rolesLookup;
        }

        let config: Config = this.includeUsersDialogConfig;

        if (type === 'includeGroups') {
            config = this.includeGroupsDialogConfig;
        } else if (type === 'includeRoles') {
            config = this.includeRolesDialogConfig;
        }

        const policyItems = this.policy?.conditions?.users[type] || [];

        const allItems: CapItem[] = lookup.map((res) => {
            return {
                displayName: res.displayName,
                id: res.id,
                inBaselineSchema: !!this.form.get(type).value?.some((b_role) => b_role === res.id),
                inPolicy: !!policyItems?.some((b_role) => b_role === res.id),
            };
        });

        const dialogRef = this.dialog.open(ManageItemsWrapperDialogComponent, {
            data: {
                allItems: allItems,
                ...config,
            },
            maxHeight: '90vh',
            width: '70%',
        });

        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((res: CapItem[]) => {
                if (res instanceof Array) {
                    const schemaItems = res.filter((res) => res.inBaselineSchema) || [];

                    this.form.get(type).setValue(schemaItems.map((res) => res.id));
                }
            });
    }

    get includeUserSettings() {
        return this.form.get('includeUserSettings').value;
    }

    get includeUserSettingsController(): FormGroup {
        return this.form.get('includeUserSettings') as FormGroup;
    }

    get includeUsers(): string[] {
        return this.form.get('includeUsers').value || [];
    }

    get includeUsersConfig(): string {
        return this.form.get('includeUsersConfig').value || [];
    }

    get includeUsersConfigController(): FormControl {
        return this.form.get('includeUsersConfig') as FormControl;
    }

    get includeGuestsOrExternalUsersController(): FormControl {
        return this.form.get('includeGuestsOrExternalUsers') as FormControl;
    }
}
