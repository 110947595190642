import { createAction, props } from '@ngrx/store';
import { EOPProtectionPolicy } from './model';

export const loadEOPProtectionPolicy = createAction(
    '[Client/Pwsh/Exo] Load EOPProtectionPolicy',
    props<{ _tenant: string }>(),
);

export const loadEOPProtectionPolicySuccess = createAction(
    '[Client/Pwsh/Exo] Load EOPProtectionPolicy Success',
    props<{ _tenant: string; data: EOPProtectionPolicy[] }>(),
);

export const loadEOPProtectionPolicyFailure = createAction(
    '[Client/Pwsh/Exo] Load EOPProtectionPolicy Failure',
    props<{ _tenant: string; error: any }>(),
);

export const enableExoEOPProtectionPolicy = createAction(
    '[Client/Pwsh/Exo] Enable EOPProtectionPolicy Policy',
    props<{ _tenant: string; data: Partial<EOPProtectionPolicy> }>(),
);

export const enableExoEOPProtectionPolicySuccess = createAction(
    '[Client/Pwsh/Exo] Enable EOPProtectionPolicy Policy Success',
    props<{ _tenant: string; data: EOPProtectionPolicy }>(),
);

export const enableExoEOPProtectionPolicyFailure = createAction(
    '[Client/Pwsh/Exo] Update EOPProtectionPolicy Policy Failure',
    props<{ _tenant: string; error: any }>(),
);

export const disableExoEOPProtectionPolicy = createAction(
    '[Client/Pwsh/Exo] Disable EOPProtectionPolicy Policy',
    props<{ _tenant: string; data: Partial<EOPProtectionPolicy> }>(),
);

export const disableExoEOPProtectionPolicySuccess = createAction(
    '[Client/Pwsh/Exo] Disable EOPProtectionPolicy Policy Success',
    props<{ _tenant: string; data: EOPProtectionPolicy }>(),
);

export const disableExoEOPProtectionPolicyFailure = createAction(
    '[Client/Pwsh/Exo] Disable EOPProtectionPolicy Policy Failure',
    props<{ _tenant: string; error: any }>(),
);
