import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, expand, map, mergeMap, reduce } from 'rxjs/operators';
import { MspAjaxService } from 'src/app/services/ajax/msp-ajax.service';
import { updateMsp } from '../../../../msp/actions';
import * as actions from './actions';

@Injectable()
export class CwmStatusesEffects {
    loadMspCWMConfigStatuses$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadMspCwmStatuses),
            mergeMap((action) =>
                this.getStatuses(action.board_id).pipe(
                    map((res) => actions.loadMspCwmStatusesSuccess({ data: res, board_id: action.board_id })),
                    catchError((error) => {
                        this.store.dispatch(updateMsp({ data: { cwm: false } }));
                        return of(actions.loadMspCwmStatusesFailure({ error }));
                    }),
                ),
            ),
        ),
    );

    getStatuses(board_id: number): Observable<Array<any>> {
        return this.mspAjax.get(`/api/octiga/v2/cwm/service/boards/${board_id}/statuses`).pipe(
            expand((res) => {
                if (res.nextPageUrl) {
                    return this.mspAjax.get('/api/octiga/v2/cwm' + res.nextPageUrl.split('/v4_6_release/apis/3.0')[1]);
                }
                return EMPTY;
            }),
            reduce((acc, res: any) => acc.concat(res.data), []),
        );
    }

    constructor(
        private actions$: Actions,
        private mspAjax: MspAjaxService,
        private store: Store<any>,
    ) {}
}
