import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from '../../../../status.interface';
import * as actions from './actions';
import { TransportConfig } from './model';

export const featureKey = 'exoTransportConfig';

export interface State extends Status {
    data: TransportConfig;
}

export const initialState: State = {
    data: undefined,
    ...initialStatus,
};

export const reducer = createReducer(
    initialState,

    on(actions.fetchExoTransportConfigSuccess, (state, { data }) => ({ ...state, loaded: true, data, error: null })),
    on(actions.fetchExoTransportConfigFailure, (state, { error }) => ({ ...state, error })),
    on(actions.updateExoTransportConfig, (state) => ({ ...state, updating: true, error: null })),
    on(actions.updateExoTransportConfigSuccess, (state, { data }) => ({
        ...state,
        updating: false,
        error: null,
        data: { ...state.data, ...data },
    })),
    on(actions.updateExoTransportConfigFailure, (state, { error }) => ({ ...state, updating: false, error })),
);

export const selectItem = (state: State) => state.data;
