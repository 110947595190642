import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, distinct, map, mergeMap } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as UserActions from './actions';

@Injectable()
export class UserEffects {
    loadUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.loadUsers),
            distinct((action) => action._tenant),
            mergeMap(({ _tenant }) =>
                this.ajax.get(_tenant, '/api/octiga/v2/tenant/user').pipe(
                    map((data) => UserActions.loadUsersSuccess({ _tenant, data })),
                    catchError((error) => of(UserActions.loadUsersFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    updateUserNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updateUserNotifications),
            mergeMap((action) =>
                this.ajax
                    .put(action._tenant, `/api/octiga/v2/tenant/user/${action.id}/alerts`, action.notifications)
                    .pipe(
                        map((user) => UserActions.updateUserNotificationsSuccess({ _tenant: action._tenant, user })),
                        catchError((error) =>
                            of(UserActions.updateUserNotificationsFailure({ _tenant: action._tenant, error })),
                        ),
                    ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
    ) {}
}
