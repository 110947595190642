import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConditionalAccessPolicy, DirectoryRole } from '@microsoft/microsoft-graph-types-beta';
import { Store, select } from '@ngrx/store';
import { Subscription, combineLatest, first, map, sample, switchMap, take } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { client } from 'src/app/stores/client';
import { AdminRoles } from 'src/app/stores/client/graph/conditional-access/modet';
import {
    selectGraphDirectoryRoleTemplates,
    selectGraphDirectoryRoleTemplatesStatus,
    selectSession,
} from 'src/app/stores/root.store';
import { BaseComponent } from '../../../base/base.component';
import { NoPolicySchema, PolicySchema } from './model';
import { displayName } from './remediate';

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../../styles/baseline-style.scss'],
})
export class BaselineComponent extends BaseComponent implements OnInit, OnDestroy {
    isSecurityDefaultsEnabled: boolean;
    allDirectoryRoles: DirectoryRole[] = [];
    isLoaded = false;

    constructor(
        private store: Store<any>,
        private dialog: MatDialog,
        private fb: FormBuilder,
    ) {
        super();
    }

    MFA: ConditionalAccessPolicy;
    subs: Subscription[] = [];

    securityDefaults$ = this.store.select(selectSession).pipe(
        map((sess) => sess.session.clientId),

        switchMap((tid) =>
            combineLatest([
                this.store.select(client(tid).graph.securityDefaults.item),
                this.store.select(client(tid).graph.securityDefaults.status),
            ]),
        ),
        first(([data, status]) => status.loaded),
        map(([data]) => data),
    );

    ngOnInit(): void {
        let schema = JSON.parse(JSON.stringify(this.baseline.schema));
        this.form = this.initForm(schema);
        const directorRoleTemplatesLoaded$ = this.store
            .select(selectGraphDirectoryRoleTemplatesStatus)
            .pipe(map((status) => status.loaded));

        const directorRoleTemplates$ = this.store
            .select(selectGraphDirectoryRoleTemplates)
            .pipe(sample(directorRoleTemplatesLoaded$))
            .pipe(take(1));

        const dataChanged$ = this.store.pipe(select(client(this.tenant_id).graph.conditionalAccessPolicy.all));

        const selectPolicies$ = this.store.select(selectSession).pipe(
            map((sess) => sess.session.clientId),
            switchMap((tid) =>
                combineLatest([
                    this.store.select(client(tid).graph.users.all),
                    this.store.select(client(tid).graph.users.status),
                ]),
            ),
            first(([data, status]) => status.loaded),
            map(([data]) => data),
            take(1),
        );

        const sub = combineLatest([
            this.securityDefaults$,
            this.form.valueChanges.pipe(startWith(this.form.value)),
            selectPolicies$,
            directorRoleTemplates$,
            dataChanged$,
        ]).subscribe(([securityDefaults, form, users, directoryRoles, data]) => {
            this.allDirectoryRoles = directoryRoles;
            this.isSecurityDefaultsEnabled = securityDefaults.isEnabled;
            this.setMfa(data);

            if (this.isSecurityDefaultsEnabled) {
                this.baselineErrorChange.next({
                    remediate: true,
                    save: false,
                });
            } else {
                if (form.state === 'delete') {
                    schema = JSON.parse(JSON.stringify(NoPolicySchema));
                    this.baselineErrorChange.next({
                        remediate: false,
                        save: false,
                    });
                } else {
                    schema = JSON.parse(JSON.stringify(PolicySchema(form.state, AdminRoles)));

                    const service_users = users.filter(
                        (u) => u.userPrincipalName && u.userPrincipalName.startsWith('octiga_service_user'),
                    );

                    const excludeUsers = {
                        type: 'array',
                        items: {
                            type: 'string',
                            enum: service_users.map((u) => u.id),
                        },
                    };

                    if (service_users.length > 0) {
                        schema.contains.properties.conditions.properties.users.properties.excludeUsers = excludeUsers;
                    } else {
                        delete schema.contains.properties.conditions.properties.users.properties.excludeUsers;
                    }

                    this.baselineErrorChange.next({
                        remediate: false,
                        save: false,
                    });
                }
            }

            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema)),
            });

            this.isLoaded = true;
        });

        this.subs.push(sub);
    }

    initForm(schema): FormGroup {
        const isNoPolicySchema = !!schema?.not?.contains;

        if (isNoPolicySchema) {
            return this.fb.group({
                state: ['delete'],
                includeRoles: [],
            });
        } else {
            return this.fb.group({
                state: [schema?.contains?.properties?.state?.const],
                includeRoles: [
                    schema?.contains?.properties?.conditions?.properties?.users?.properties?.includeRoles?.items?.enum,
                ],
            });
        }
    }

    setMfa(data) {
        if (data instanceof Array) {
            this.MFA = data.find((res) => res.displayName === displayName);
        }
    }

    ngOnDestroy(): void {
        this.subs.forEach((sub) => {
            sub.unsubscribe();
        });
    }
}
