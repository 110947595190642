import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { SwayGroup } from './model';

export const featureKey = 'swayGroup';

export interface State extends EntityState<SwayGroup>, Status {}

export const adapter: EntityAdapter<SwayGroup> = createEntityAdapter<SwayGroup>({
    selectId: (group) => group.id,
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(actions.reloadSwayGroups, (state) => ({ ...state, loaded: false })),

    on(actions.loadSwayGroupsSuccess, (state, action) => adapter.setAll(action.data, { ...state, loaded: true })),
    on(actions.createGroupSuccess, (state, action) => adapter.upsertOne(action.data, state)),
    on(actions.updateSwayGroupSuccess, (state, action) =>
        adapter.updateOne({ id: action.data.id, changes: action.data }, state),
    ),
    on(actions.deleteGroupSuccess, (state, action) => adapter.removeOne(action.group_id, state)),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
