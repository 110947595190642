export { BaselineComponent } from './baseline.component';
export { Remediate } from './remediate';
export const href = 'https://learn.microsoft.com/en-us/powershell/module/exchange/new-transportrule?view=exchange-ps';
export const BlockAutoForwardingIdentityName = 'Automatic Forwarding - Block';

export function NoTransportRuleSchema() {
    return {
        type: 'array',
        not: {
            contains: {
                type: 'object',
                properties: {
                    Identity: {
                        type: 'string',
                        const: BlockAutoForwardingIdentityName,
                    },
                },
            },
        },
    };
}

export function setTransportRulePolicySchema(form) {
    return {
        type: 'array',
        contains: {
            type: 'object',
            properties: {
                Identity: {
                    type: 'string',
                    const: form.Identity,
                },
                State: {
                    type: 'string',
                    const: form.State,
                },
                FromScope: {
                    type: 'string',
                    const: form.FromScope,
                },
                SentToScope: {
                    type: 'string',
                    const: form.SentToScope,
                },
                MessageTypeMatches: {
                    type: 'string',
                    const: 'AutoForward',
                },
                RejectMessageEnhancedStatusCode: {
                    type: 'string',
                    const: '5.7.' + form.RejectMessageEnhancedStatusCode,
                },
                RejectMessageReasonText: {
                    type: 'string',
                    const: form.RejectMessageReasonText,
                },
                ExceptIfSentTo: getEnumItem(form.ExceptIfSentTo),
                ExceptIfRecipientDomainIs: getEnumItem(form.ExceptIfRecipientDomainIs),
                GenerateIncidentReport: setItem(form.GenerateIncidentReport),
                Priority: {
                    type: 'number',
                    const: form.Priority,
                },
            },
        },
    };
}

function getEnumItem(items: Array<string>) {
    if (items?.length > 0) {
        return {
            type: 'array',
            items: {
                enum: [...items],
                type: 'string',
            },
            minItems: items.length,
            maxItems: items.length,
            uniqueItems: true,
        };
    } else {
        return {
            type: 'null',
        };
    }
}
function setItem(item) {
    if (item) {
        return {
            type: 'string',
            const: item,
        };
    } else {
        return {
            type: 'null',
        };
    }
}
