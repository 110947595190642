import { Injectable } from '@angular/core';
import { IdentitySecurityDefaultsEnforcementPolicy } from '@microsoft/microsoft-graph-types-beta';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, distinct, mergeMap, last, map } from 'rxjs/operators';
import { retry } from 'src/app/pipes/retry.pipe';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as actions from './actions';

@Injectable()
export class SecurityDefaultsEnforcementPolicyEffects {
    loadSecurityDefaults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadSecurityDefaultsPolicy),
            distinct((action) => action._tenant),
            mergeMap(({ _tenant }) =>
                this.ajax
                    .get<IdentitySecurityDefaultsEnforcementPolicy>(
                        _tenant,
                        '/api/microsoft/graph/policies/identitySecurityDefaultsEnforcementPolicy',
                    )
                    .pipe(
                        retry(3000, 3, 'sescurity defaults policy timeout'),
                        last(),
                        map((policy: IdentitySecurityDefaultsEnforcementPolicy) =>
                            actions.loadSecurityDefaultsPolicySuccess({
                                _tenant,
                                policy,
                            }),
                        ),
                        catchError((error) =>
                            of(
                                actions.loadSecurityDefaultsFailure({
                                    _tenant,
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    updateSecurityDefaults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateSecurityDefaults),
            mergeMap(({ _tenant, isEnabled }) =>
                this.ajax
                    .patch<IdentitySecurityDefaultsEnforcementPolicy>(
                        _tenant,
                        '/api/microsoft/graph/policies/identitySecurityDefaultsEnforcementPolicy',
                        { isEnabled },
                    )
                    .pipe(
                        retry(3000, 3, 'updating sescurity defaults policy timeout'),
                        last(),
                        map(() =>
                            actions.updateSecurityDefaultsSuccess({
                                _tenant,
                                isEnabled,
                            }),
                        ),
                        catchError((error) =>
                            of(
                                actions.updateSecurityDefaultsFailure({
                                    _tenant,
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
    ) {}
}
