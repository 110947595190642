import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap, map, take } from 'rxjs/operators';
import { MspAjaxService } from 'src/app/services/ajax/msp-ajax.service';
import * as actions from './actions';

@Injectable()
export class ReportCategoryEffects {
    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadReportCategories),
            take(1),
            mergeMap(() =>
                this.get().pipe(
                    map((data) => actions.loadReportCategoriesSuccess({ data })),
                    catchError((error) => of(actions.loadReportCategoriesFailure({ error }))),
                ),
            ),
        ),
    );

    /**
     * @description
     * get all categories with baseline name
     */
    private get() {
        return this.ajax.get('/api/report/categories');
    }

    constructor(
        private actions$: Actions,
        private ajax: MspAjaxService,
    ) {}
}
