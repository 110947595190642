import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ErrorObject } from 'ajv';
import { Subscription } from 'rxjs';
import { TemplateItem } from 'src/app/modules/sway/store/templates/items/model';
import { Baseline } from 'src/app/stores/client/sway/baseline/model';

@Component({ template: '' })
export class BaseComponent implements AfterViewInit, OnDestroy {
    @Input() baselineTemplateItem: TemplateItem;
    @Input() baseline: Baseline;
    @Input() data: any;
    @Input() meta_data: any;
    @Output() baselineChange = new EventEmitter<Baseline>();
    @Output() baselineErrorChange = new EventEmitter<{ remediate: boolean; save: boolean }>();
    @Input() readOnly;
    @Input() inactive;
    @Input() tenant_id: string;
    form = new FormGroup({});
    control = new FormControl({});

    field?: string; // if present, component with select 'field' from data

    @Input() errors: ErrorObject<string, Record<string, any>, unknown>[] = [];

    subs: Subscription[] = [];

    ngAfterViewInit(): void {
        if (this.readOnly) {
            this.form.disable({ emitEvent: false });

            this.control.disable({ emitEvent: false });
            this.baselineTemplateItem.override?.forEach((item) => {
                if (item && this.form && this.form.get(item)) {
                    this.form.get('excludeUserSettings')?.get(item)?.enable({ emitEvent: false }); // only for caps
                    this.form.get('includeUserSettings')?.get(item)?.enable({ emitEvent: false }); // only for caps

                    this.form.get('assignments')?.get(item)?.enable({ emitEvent: false }); // only for compliance policies

                    this.form.get(item).enable({ emitEvent: false });
                }
            });
        }

        const sub2 = this.control.valueChanges.subscribe((res) => {
            // disable save and remediate if you have any errors
            const hasError = this.control.errors;

            if (hasError) {
                this.baselineErrorChange.next({ remediate: true, save: true });
            } else {
                this.baselineErrorChange.next({ remediate: false, save: false });
            }
        });

        this.subs.push(sub2);
    }

    ngOnDestroy(): void {
        this.subs.forEach((sub) => {
            sub.unsubscribe();
        });
    }
}
