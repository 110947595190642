import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { SharePointDetails } from './model';

export const featureKey = 'graphSpoSharePointDetails';

export interface State extends EntityState<SharePointDetails>, Status {}

export const adapter: EntityAdapter<SharePointDetails> = createEntityAdapter<SharePointDetails>({
    selectId: (site) => site.siteUrl,
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(actions.loadSPOSiteUsageSuccess, (state, action) =>
        adapter.setAll(action.data, { ...state, loaded: true, error: null }),
    ),
    on(actions.loadSPOSiteUsageFailure, (state, { error }) => ({ ...state, loaded: true, error })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
