import { createAction, props } from '@ngrx/store';

export const loadGraphRoles = createAction('[Tenant Status] Load Graph Roles', props<{ _tenant: string }>());

export const reloadGraphRoles = createAction(
    '[Tenant Status] Reload Graph Roles',
    props<{ _tenant: string; oldRoles?: string[] }>(),
);

export const loadGraphRolesSuccess = createAction(
    '[Tenant Status] Load Graph Roles Success',
    props<{ _tenant: string; data: string[] }>(),
);

export const loadGraphRolesFailure = createAction(
    '[Tenant Status] Load Graph Roles Failure',
    props<{ _tenant: string; error: any }>(),
);
