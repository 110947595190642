import { MalwareFilterPolicy } from 'src/app/interfaces/powershell/exo/malware-filter-policy.interface';
import { updateExoMalwareFilterPolicy } from 'src/app/stores/client/powershell/exo/malware-filter-policy/actions';
export const Identity = 'Default';

export function Remediate(_tenant: string, data: MalwareFilterPolicy[], schema: any) {
    return updateExoMalwareFilterPolicy(
        // not finished
        {
            _tenant,
            data: {
                Identity,
                InternalSenderAdminAddress: schema?.contains?.properties?.InternalSenderAdminAddress?.const || null,
                EnableInternalSenderAdminNotifications:
                    schema.contains.properties.EnableInternalSenderAdminNotifications.const,
            },
        },
    );
}
