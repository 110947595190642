import { createReducer, on } from '@ngrx/store';
import * as actions from './actions';

export const featureKey = 'mspCwmConnection';

export interface State {
    data: any | null;
    loaded: boolean;
    error: any;
}

export const initialState: State = {
    data: null,
    loaded: false,
    error: false,
};

export const reducer = createReducer(
    initialState,
    on(actions.loadMspCWMConnectionSuccess, (state, { data }) => ({ ...state, data, loaded: true })),
    on(actions.loadMspCWMConnectionFailure, (state, { error }) => ({ ...state, error })),
);

export const selectData = (state: State) => state.data;
export const selectLoaded = (state: State) => state.loaded;
export const selectLoading = (state: State) => !state.loaded;
export const selectError = (state: State) => state.error;
