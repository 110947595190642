import { createAction, props } from '@ngrx/store';
import { Recipient } from 'src/app/interfaces/powershell/exo/recipient.interface';

export const loadRecipients = createAction('[Pwsh/Exo] Load Recipients', props<{ _tenant: string }>());

export const loadRecipientsSuccess = createAction(
    '[Pwsh/Exo] Load Recipients Success',
    props<{ _tenant: string; data: Recipient[] }>(),
);

export const loadRecipientsFailure = createAction(
    '[Pwsh/Exo] Load Recipients Failure',
    props<{ _tenant: string; error: any }>(),
);
