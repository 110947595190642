import { createAction, props } from '@ngrx/store';

export const loadMspCwmTeams = createAction('[Msp/CWM] Load Msp Cwm Teams', props<{ board_id: number }>());

export const loadMspCwmTeamsSuccess = createAction(
    '[Msp/CWM] Load Msp Cwm Teams Success',
    props<{ data: any; board_id: number }>(),
);

export const loadMspCwmTeamsFailure = createAction('[Msp/CWM] Load Msp Cwm Teams Failure', props<{ error: any }>());
