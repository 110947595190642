import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from '../../../../status.interface';
import * as actions from './actions';
import { HostedOutboundSpamFilterPolicy } from './model';

export const featureKey = 'exoHostedOutboundSpamFilterPolicy';
export interface State extends EntityState<HostedOutboundSpamFilterPolicy>, Status {}

export const adapter: EntityAdapter<HostedOutboundSpamFilterPolicy> =
    createEntityAdapter<HostedOutboundSpamFilterPolicy>({ selectId: (entity) => entity.Id });

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(actions.fetchHostedOutboundSpamFilterPolicySuccess, (state, action) =>
        adapter.setAll(action.data, { ...state, loaded: true }),
    ),
    on(actions.fetchHostedOutboundSpamFilterPolicyFailure, (state, { error }) => ({ ...state, error })),

    on(actions.updateExoHostedOutboundSpamFilterPolicy, (state) => ({ ...state, updating: true })),
    on(actions.updateExoHostedOutboundSpamFilterPolicySuccess, (state, action) =>
        adapter.upsertOne(action.data, { ...state, updating: false }),
    ),
    on(actions.updateExoHostedOutboundSpamFilterPolicyFailure, (state, { error }) => ({
        ...state,
        updating: false,
        error,
    })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
