import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { Msp } from './model';
export const featureKey = 'MspConfig';

export interface State extends EntityState<Msp>, Status {}

export const adapter: EntityAdapter<Msp> = createEntityAdapter<Msp>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,

    on(actions.loadSwayMspConfigSuccess, (state, action) =>
        adapter.upsertMany(action.data, { ...state, loaded: true }),
    ),

    on(actions.loadSwayMspConfigFailure, (state, { error }) => ({ ...state, error, updating: false })),

    on(actions.setOnboardTemplateSuccess, (state, action) =>
        adapter.updateOne(
            {
                id: action.msp_id,
                changes:
                    action.template_type === 'tenant'
                        ? { onboard_tenant_template_id: action.template_id }
                        : { onboard_group_template_id: action.template_id },
            },
            { ...state, loaded: true },
        ),
    ),

    on(actions.setOnboardTemplateFailure, (state, { error }) => ({ ...state, error, updating: false })),

    on(actions.deleteOnboardTemplateSuccess, (state, action) => adapter.removeAll({ ...state, loaded: true })),
    on(actions.deleteOnboardTemplateFailure, (state, { error }) => ({ ...state, error, updating: false })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
