import { AdminAuditLogConfig } from 'src/app/stores/client/powershell/exo/admin-audit-log-config/model';
import { updateExoOrganizationConfig } from 'src/app/stores/client/powershell/exo/organization-config/actions';

export function Remediate(_tenant: string, data: Partial<AdminAuditLogConfig>, schema: any) {
    if (schema.properties.AuditDisabled.const === false) {
        return updateExoOrganizationConfig({ _tenant, data: { AuditDisabled: false } });
    }

    if (schema.properties.AuditDisabled.const === true) {
        return updateExoOrganizationConfig({ _tenant, data: { AuditDisabled: true } });
    }
}
