import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, distinct, map, mergeMap } from 'rxjs/operators';
import { Tenant } from 'src/app/interfaces/tenant.interface';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as TenantActions from './actions';

@Injectable()
export class TenantEffects {
    loadTenant$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TenantActions.loadTenant),
            distinct((action) => action._tenant),
            mergeMap(({ _tenant }) =>
                this.getTenant(_tenant).pipe(
                    map((data: Tenant) => TenantActions.loadTenantsSuccess({ _tenant, data })),
                    catchError((error) => of(TenantActions.loadTenantFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    deleteTenantRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TenantActions.deleteTenantRequest),
            concatMap(({ _tenant }) =>
                this.ajax.delete(_tenant, '/api/octiga/v2/tenant-request').pipe(
                    map((data) => TenantActions.deleteTenantRequestSuccess({ _tenant, data })),
                    catchError((error) => of(TenantActions.deleteTenantRequestFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    private getTenant(tenant: string): Observable<Tenant> {
        return this.ajax.get(tenant, '/api/octiga/v2/tenant');
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
    ) {}
}
