import { createAction, props } from '@ngrx/store';
import { User } from '@microsoft/microsoft-graph-types-beta';

export const loadMspGraphUsers = createAction('[Msp/API] Load Msp Graph Users', props<{ msp_id: string }>());

export const loadMspGraphUsersSuccess = createAction(
    '[Msp/API] Load Msp Graph Users Success',
    props<{ msp_users: User[] }>(),
);

export const loadMspGraphUsersFailure = createAction('[Msp/API] Load Msp Graph Users Failure', props<{ error: any }>());
