import { createAction, props } from '@ngrx/store';
import { ServicePrincipal_AppRoleAssignments } from './model';

export const loadServicePrincipals = createAction('[Graph/ServicePrincipal] Load', props<{ _tenant: string }>());

export const loadServicePrincipalsSuccess = createAction(
    '[Graph/ServicePrincipal] Load Success',
    props<{ _tenant: string; data: ServicePrincipal_AppRoleAssignments[] }>(),
);

export const loadServicePrincipalsFailure = createAction(
    '[Graph/ServicePrincipal] Load Failure',
    props<{ _tenant: string; error: any }>(),
);

export const loadServicePrincipalByDisplayName = createAction(
    '[Graph/ServicePrincipal] Load by DisplayName',
    props<{ _tenant: string }>(),
);

export const loadServicePrincipalByDisplayNameSuccess = createAction(
    '[Graph/ServicePrincipal] Load by DisplayName Success',
    props<{ _tenant: string; data: ServicePrincipal_AppRoleAssignments }>(),
);

export const loadServicePrincipalByDisplayNameFailure = createAction(
    '[Graph/ServicePrincipal] Load by DisplayName Failure',
    props<{ _tenant: string; error: any }>(),
);
