import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { loadASNList, loadASNListFailure, loadASNListSuccess } from './actions';
import { RipeASN } from './model';
import { environment } from 'src/environments/environment';

@Injectable()
export class AsnListEffects {
    loadASNList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadASNList),
            take(1),
            mergeMap(() =>
                this.get().pipe(
                    map((data) => loadASNListSuccess({ data })),
                    catchError((error) => of(loadASNListFailure({ error }))),
                ),
            ),
        ),
    );

    private get(): Observable<RipeASN[]> {
        const url = environment.stage === 'development' ? '/assets/asn-list.json' : '/asn-list.json';
        return ajax.get<RipeASN[]>(url).pipe(map(({ response }) => response));
    }

    constructor(private actions$: Actions) {}
}
