import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { SpoTenant } from 'src/app/stores/client/powershell/spo/tenant/model';
import { BaseComponent } from '../../base/base.component';

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../styles/baseline-style.scss'],
})
export class BaselineComponent extends BaseComponent implements OnInit {
    @Input() data: SpoTenant;
    ngOnInit(): void {
        this.control.setValidators([Validators.required, Validators.min(0), Validators.max(730)]);
        const schema = JSON.parse(JSON.stringify(this.baseline.schema));

        const value = schema.properties?.RequireAnonymousLinksExpireInDays?.const;

        if (value !== undefined) {
            this.control.setValue(value);
        } else {
            this.control.setValue(0);
        }

        this.control.valueChanges.subscribe((value) => {
            if (value == 0) {
                delete schema.properties.RequireAnonymousLinksExpireInDays['const'];
                schema.properties.RequireAnonymousLinksExpireInDays['maximum'] = 0;
                // this is because spo returns -1 instead of zero
            } else {
                delete schema.properties.RequireAnonymousLinksExpireInDays['maximum'];

                schema.properties.RequireAnonymousLinksExpireInDays['const'] = value;
                schema.required = ['RequireAnonymousLinksExpireInDays'];
            }

            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema)),
            });
        });
    }
}
