import { createAction, props } from '@ngrx/store';
import { AdminAuditLogConfig } from './model';

export const fetchExoAdminAuditLogConfig = createAction(
    '[Client/Pwsh/Exo] Fetch AdminAuditLogConfig',
    props<{ _tenant: string }>(),
);

export const fetchExoAdminAuditLogConfigSuccess = createAction(
    '[Client/Pwsh/Exo] Fetch AdminAuditLogConfig Success',
    props<{ _tenant: string; data: AdminAuditLogConfig }>(),
);

export const fetchExoAdminAuditLogConfigFailure = createAction(
    '[Client/Pwsh/Exo] Fetch AdminAuditLogConfig Failure',
    props<{ _tenant: string; error: any }>(),
);

export const updateExoAdminAuditLogConfig = createAction(
    '[Client/Pwsh/Exo] Update AdminAuditLogConfig',
    props<{ _tenant: string; data: Partial<AdminAuditLogConfig> }>(),
);

export const updateExoAdminAuditLogConfigSuccess = createAction(
    '[Client/Pwsh/Exo] Update AdminAuditLogConfig Success',
    props<{ _tenant: string; data: AdminAuditLogConfig }>(),
);

export const updateExoAdminAuditLogConfigFailure = createAction(
    '[Client/Pwsh/Exo] Update AdminAuditLogConfig Failure',
    props<{ _tenant: string; error: any }>(),
);
