import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-monitoring-nav',
    templateUrl: './monitoring-nav.component.html',
    styleUrls: ['./monitoring-nav.component.scss'],
})
export class MonitoringNavComponent implements OnInit {
    @Input() tenant_id: string;

    constructor(private route: ActivatedRoute) {}

    ngOnInit() {}
}
