import {
    ConditionalAccessApplications,
    ConditionalAccessClientApp,
    ConditionalAccessGrantControls,
    ConditionalAccessPolicy,
} from '@microsoft/microsoft-graph-types-beta';
import { RemediateCapItem } from '../shared/cap-remediate';
import { getCAPBaselineDisplayName } from '../shared/cap-schema';

const grantControls: ConditionalAccessGrantControls = {
    operator: 'OR',
    builtInControls: ['block'],
};

const clientAppTypes: ConditionalAccessClientApp[] = ['other', 'exchangeActiveSync'];

const applications: ConditionalAccessApplications = {
    // check this
    includeApplications: ['All'],
};

export function Remediate(_tenant: string, data: ConditionalAccessPolicy[], schema: any, metaData, id) {
    let policyItem;

    if (id) {
        policyItem = data.find((res) => res.id === id);
    } else {
        policyItem = data.find((res) => res.displayName === getCAPBaselineDisplayName(schema));
    }

    return RemediateCapItem(_tenant, schema, policyItem, { grantControls, clientAppTypes, applications });
}
