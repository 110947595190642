import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from '../../../../status.interface';
import * as actions from './actions';
import { MobileDeviceMailboxPolicy } from './model';

export const featureKey = 'exoMobileDeviceMailboxPolicy';
export interface State extends EntityState<MobileDeviceMailboxPolicy>, Status {}

export const adapter: EntityAdapter<MobileDeviceMailboxPolicy> = createEntityAdapter<MobileDeviceMailboxPolicy>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(actions.fetchMobileDeviceMailboxPolicySuccess, (state, action) =>
        adapter.setAll(action.data, { ...state, loaded: true, error: null }),
    ),
    on(actions.fetchMobileDeviceMailboxPolicyFailure, (state, { error }) => ({ ...state, error })),

    on(actions.updateExoMobileDeviceMailboxPolicy, (state) => ({ ...state, updating: true, error: null })),
    on(actions.updateExoMobileDeviceMailboxPolicySuccess, (state, action) =>
        adapter.upsertOne(action.data, { ...state, updating: false, error: null }),
    ),
    on(actions.updateExoMobileDeviceMailboxPolicyFailure, (state, { error }) => ({ ...state, updating: false, error })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
