import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, distinct, map, mergeMap, tap } from 'rxjs/operators';
import { MspAjaxService } from 'src/app/services/ajax/msp-ajax.service';
import * as actions from './actions';
import { Geo } from './model';

@Injectable()
export class MspGeoEffects {
    loadGeos$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadGeos),
            distinct((action) => action.type),
            mergeMap(() =>
                this.get().pipe(
                    map(({ records }) => actions.loadGeosSuccess({ geos: records })),
                    catchError((error) => of(actions.loadGeosFailure({ error }))),
                ),
            ),
        ),
    );

    createGeo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.createGeo),
            mergeMap(({ geo }) =>
                this.post(geo).pipe(
                    map((geo) => actions.createGeoSuccess({ geo })),
                    catchError((error) => of(actions.createGeoFailure({ error }))),
                ),
            ),
        ),
    );

    updateGeo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateGeo),
            mergeMap(({ id, geo }) =>
                this.put(id, geo).pipe(
                    map((geo) => actions.updateGeoSuccess({ geo })),
                    catchError((error) => of(actions.updateGeoFailure({ error }))),
                ),
            ),
        ),
    );

    deleteGeo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteGeo),
            mergeMap(({ id }) =>
                this.delete(id).pipe(
                    map((_) => actions.deleteGeoSuccess({ id })),
                    catchError((error) => of(actions.deleteGeoFailure({ id, error }))),
                ),
            ),
        ),
    );

    get() {
        return this.ajax.get<{ records: Geo[] }>('/api/octiga/v2/msp/geo');
    }

    post(body: Partial<Geo>) {
        return this.ajax.post('/api/octiga/v2/msp/geo', body);
    }

    put(id: string, body: Partial<Geo>) {
        return this.ajax.put<Geo>(`/api/octiga/v2/msp/geo/${id}`, body);
    }

    delete(id: string) {
        return this.ajax.delete<Geo>(`/api/octiga/v2/msp/geo/${id}`);
    }

    constructor(
        private actions$: Actions,
        private ajax: MspAjaxService,
    ) {}
}
