import { createAction, props } from '@ngrx/store';
import { ASN } from './model';

export const loadASNs = createAction('[MspAsn] Load');

export const loadASNsSuccess = createAction('[MspAsn] Load Success', props<{ asns: ASN[] }>());

export const loadASNsFailure = createAction('[MspAsn] Load Failure', props<{ error: any }>());

export const createASN = createAction('[MspAsn] Create', props<{ asn: Partial<ASN> }>());

export const createAsnSuccess = createAction('[MspAsn] Create Success', props<{ asn: ASN }>());

export const createASNFailure = createAction('[MspAsn] Create Failure', props<{ error: any }>());

export const deleteASN = createAction('[MspAsn] Delete', props<{ id: string }>());

export const deleteAsnSuccess = createAction('[MspAsn] Delete Success', props<{ id: string }>());

export const deleteAsnFailure = createAction('[MspAsn] Delete Failure', props<{ id: string; error: any }>());
