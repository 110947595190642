import { createAction, props } from '@ngrx/store';
import { InputIp } from './model';

export const loadInputIp = createAction('[EventRuleInputIp] Load');

export const loadInputIpSuccess = createAction('[EventRuleInputIp] Load Success', props<{ data: InputIp[] }>());

export const loadInputIpFailure = createAction('[EventRuleInputIp] Load Failure', props<{ error: any }>());

export const createInputIp = createAction('[EventRuleInputIp] Create', props<{ data: InputIp }>());

export const createInputIpSuccess = createAction('[EventRuleInputIp] Create Success', props<{ data: InputIp }>());

export const createInputIpFailure = createAction('[EventRuleInputIp] Create Failure', props<{ error: any }>());

export const deleteInputIp = createAction('[EventRuleInputIp] Delete', props<{ id: string }>());

export const deleteInputIpSuccess = createAction('[EventRuleInputIp] Delete Success', props<{ id: string }>());

export const deleteInputIpFailure = createAction(
    '[EventRuleInputIp] Delete Failure',
    props<{ id: string; error: any }>(),
);
