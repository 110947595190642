import { AssignedLicense, PasswordProfile, User } from '@microsoft/microsoft-graph-types-beta';
import { createAction, props } from '@ngrx/store';

export const loadGraphUsers = createAction('[Graph/User] Get Graph Users', props<{ _tenant: string }>());

export const reloadGraphUsers = createAction('[Graph/User] Reload Graph Users', props<{ _tenant: string }>());

export const loadGraphUsersSuccess = createAction(
    '[Graph/User] Load Graph Users Success',
    props<{ _tenant: string; users: User[] }>(),
);

export const loadGraphUsersFailure = createAction(
    '[Graph/User] Load Graph Users Failure',
    props<{ _tenant: string; error: any }>(),
);

export const loadGraphUserSignInDatesFailure = createAction(
    '[Graph/User] Load Graph Users Failure',
    props<{ _tenant: string }>(),
);

export const createGraphUser = createAction(
    '[Graph/User] Create Graph Users',
    props<{ _tenant: string; user: User }>(),
);

export const createGraphUsersSuccess = createAction(
    '[Graph/User] Create Graph Users Success',
    props<{ _tenant: string; user: User }>(),
);

export const createGraphUsersFailure = createAction(
    '[Graph/User] Create Graph Users Failure',
    props<{ _tenant: string; error: any }>(),
);

export const resetUserPassword = createAction(
    '[Graph/User] Reset User Password',
    props<{ _tenant: string; userPrincipalName: string; passwordProfile: PasswordProfile }>(),
);

export const resetUserPasswordSuccess = createAction(
    '[Graph/User] Reset User Password Successful',
    props<{ _tenant: string; userPrincipalName: string }>(),
);

export const resetUserPasswordFailure = createAction(
    '[Graph/User] Reset User Password Failure',
    props<{ _tenant: string; userPrincipalName: string; error: any }>(),
);

export const revokeUserSignInSession = createAction(
    '[Graph/User] Revoke User Signin Session',
    props<{ _tenant: string; userPrincipalName: string }>(),
);

export const revokeUserSignInSessionSuccess = createAction(
    '[Graph/User] Revoke User Signin Session Success',
    props<{ _tenant: string; userPrincipalName: string }>(),
);

export const revokeUserSignInSessionFailure = createAction(
    '[Graph/User] Revoke User Signin Session Failure',
    props<{ _tenant: string; userPrincipalName: string; error: any }>(),
);

export const deleteGraphUsers = createAction(
    '[Graph/User] Delete Graph Users',
    props<{ _tenant: string; ids: string[] }>(),
);

export const deleteGraphUsersSuccess = createAction(
    '[Graph/User] Delete Graph Users Success',
    props<{ _tenant: string; ids: string[] }>(),
);

export const deleteGraphUsersFailure = createAction(
    '[Graph/User] Delete Graph Users Failure',
    props<{ _tenant: string; error: any }>(),
);

export const assignLicense = createAction(
    '[Graph/assignLicense] assign license',
    props<{ _tenant: string; userId: string; addLicenses: Array<AssignedLicense>; removeLicenses: Array<string> }>(),
);

export const assignLicenseSuccess = createAction(
    '[Graph/assignLicense] assign license Success',
    props<{ _tenant: string; user: Partial<User> }>(),
);

export const assignLicenseFailure = createAction(
    '[Graph/assignLicense] assign license Failure',
    props<{ _tenant: string; error: any }>(),
);

export const updateGraphUser = createAction(
    '[Graph/updateGraphUser] update graph  User',
    props<{ _tenant: string; userId: string; data: Partial<User> }>(),
);

export const updateGraphUserSuccess = createAction(
    '[Graph/updateGraphUser] update graph User Success',
    props<{ _tenant: string; userId: string; data: Partial<User> }>(),
);

export const updateGraphUserFailure = createAction(
    '[Graph/updateGraphUser] update graph User Failure',
    props<{ _tenant: string; error: any }>(),
);

export const createGraphUsersWithRole = createAction(
    '[Graph/User] create Graph Users with role',
    props<{ _tenant: string; users: User[]; roleTemplateId: string }>(),
);

export const createGraphUsersWithRoleSuccess = createAction(
    '[Graph/User] create Graph Users with role Success',
    props<{ _tenant: string; users: User[] }>(),
);
