export interface StateParam {
    return_url?: string;
    redirect_uri?: string;
    msp?: boolean;
    msp_id?: string;
    stay_signed_in?: boolean;
}

export function EncodeStateParam(state: StateParam): string {
    return encodeURIComponent(JSON.stringify(state));
}
