import { Injectable } from '@angular/core';
import CryptoES from 'crypto-es';

@Injectable({
    providedIn: 'root',
})
export class CryptoService {
    constructor() {}

    /**
     *   Function to hash a string
     */
    public hash(message: string): string {
        const hashed = CryptoES.MD5(message);
        return hashed.toString();
    }
}
