import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { selectSession } from '../../stores/root.store';
import { HandleAjaxError } from './util';

@Injectable({
    providedIn: 'root',
})
export class MspAjaxService {
    constructor(
        private store: Store,
        private http: HttpClient,
    ) {}

    public get<T = any>(url: string): Observable<T> {
        return this.ajax<T>('GET', url);
    }

    public put<T = any, P = any>(url: string, body?: P): Observable<T> {
        return this.ajax<T>('PUT', url, body);
    }

    public post<T = any, P = any>(url: string, body?: P): Observable<T> {
        return this.ajax<T>('POST', url, body);
    }

    public patch<T = any, P = any>(url: string, body?: P): Observable<T> {
        return this.ajax<T>('PATCH', url, body);
    }

    public delete<T = any>(url: string): Observable<T> {
        return this.ajax<T>('DELETE', url);
    }

    private ajax<T, P = any>(method: string, url: string, body?: P): Observable<T> {
        return this.store.select(selectSession).pipe(
            take(1),
            switchMap(({ session }) => {
                const mspId = session?.msp_id;

                const headers = new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                    'msp-id': mspId || '',
                });

                return this.http.request<T>(method, url, { headers, body }).pipe(
                    catchError(HandleAjaxError), // Ensure this is compatible with HttpClient
                );
            }),
        );
    }
}
