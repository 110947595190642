import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription, combineLatest, sample } from 'rxjs';
import { filter, first, map, switchMap, take } from 'rxjs/operators';
import { client } from 'src/app/stores/client';
import { loadBaselines } from 'src/app/stores/client/sway/baseline/actions';
import { selectSession, selectSwaySpecAll } from 'src/app/stores/root.store';
import { loadSpecs } from 'src/app/stores/root/sway/spec/actions';
import { SwaySpec } from 'src/app/stores/root/sway/spec/model';
import { CardComponent } from '../card.component';
import { loadDeviations } from './../../../../../stores/client/sway/deviation/deviation.actions';
import { selectSwaySpecStatus } from './../../../../../stores/root.store';

@Component({
    selector: 'app-sway',
    templateUrl: './sway.component.html',
    styleUrls: ['./sway.component.scss'],
})
export class SwayComponent extends CardComponent implements OnInit, OnDestroy {
    tenantDeviations$: BehaviorSubject<{ danger: number; info: number }> = new BehaviorSubject({
        danger: -1,
        info: -1,
    });

    isLoading = true;
    isDevLoaded = false;
    sub: Subscription;

    userDeviations: number;
    groupDeviations: number;
    baselineAuditCount: number = 0;

    constructor(
        private router: Router,
        private store: Store<any>,
    ) {
        super();
    }
    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }

    ngOnInit(): void {
        const spec_status$ = this.store.pipe(select(selectSwaySpecStatus)).pipe(filter((res) => res.loaded));
        const specs$ = this.store.pipe(select(selectSwaySpecAll)).pipe(sample(spec_status$));

        this.dispatcher();

        const deviations = (tenant: string) => this.store.pipe(select(client(tenant).sway.deviations.allActive));
        const deviations_status = (tenant: string) =>
            this.store.pipe(select(client(tenant).sway.deviations.status)).pipe(map((res) => res.loaded));

        const baselines = (tenant: string) => this.store.pipe(select(client(tenant).sway.baselines.all));
        const baselines_status = (tenant: string) =>
            this.store.pipe(select(client(tenant).sway.baselines.status)).pipe(map((res) => res.loaded));

        this.sub = this.store
            .pipe(
                select(selectSession),
                filter((sess) => !!sess.session.clientId),
                take(1),
                switchMap((sess) =>
                    combineLatest([
                        specs$,
                        deviations(sess.session.clientId),
                        baselines(sess.session.clientId).pipe(sample(baselines_status(sess.session.clientId))),
                        deviations_status(sess.session.clientId),
                        baselines_status(sess.session.clientId),
                    ]),
                ),
                // sample(ready$)
            )
            .subscribe(([specs, dev, bas, dev_status, base_status]) => {
                const isLoaded = base_status && dev_status;
                this.isLoading = false;
                this.isDevLoaded = isLoaded;

                const tenantBaselines = bas.filter((res) => res.type === 'tenant');
                const tenantDeviations = dev.filter((res) => res.type === 'tenant');

                // const groupBaselines = bas.filter(res => !!res.group_id)
                // const groupDeviations = new Set([...dev.filter(res=> res.type === 'group').map(res=> res.spec_id)]).size
                // const userDeviations = dev.filter(res => !!res.group_id)

                if (isLoaded) {
                    this.tenantDeviations$.next({
                        danger: tenantDeviations.length,
                        info: tenantBaselines.length - tenantDeviations.length,
                    });

                    this.groupDeviations = new Set([
                        ...dev.filter((res) => res.type === 'group').map((res) => res.spec_id),
                    ]).size;
                    this.userDeviations = dev.filter((res) => !!res.group_id)?.length;
                }
            });
    }

    dispatcher() {
        this.store.dispatch(loadSpecs());
        this.store
            .select(selectSession)
            .pipe(
                first((sess) => !!sess?.session?.clientId),
                map((sess) => sess.session.clientId),
            )
            .subscribe((_tenant) => {
                this.store.dispatch(loadBaselines({ _tenant }));
                this.store.dispatch(loadDeviations({ _tenant }));
            });
    }

    goToGroup() {
        this.store
            .pipe(
                select(selectSession),
                filter((sess) => !!sess.session.clientId),
                take(1),
            )
            .subscribe((sess) =>
                this.router.navigate(['client', sess.session.clientId, 'sway', 'baselines', 'group'], {
                    queryParams: { returnUrl: 'dashboard' },
                }),
            );
    }

    goToTenant() {
        this.store
            .pipe(
                select(selectSession),
                filter((sess) => !!sess.session.clientId),
                take(1),
            )
            .subscribe((sess) =>
                this.router.navigate(['client', sess.session.clientId, 'sway', 'baselines', 'tenant'], {
                    queryParams: { returnUrl: 'dashboard' },
                }),
            );
    }

    goToBaselineAudit() {
        this.store
            .pipe(
                select(selectSession),
                filter((sess) => !!sess.session.clientId),
                take(1),
            )
            .subscribe((sess) =>
                this.router.navigate(['client', sess.session.clientId, 'sway', 'baselines', 'baseline-audit'], {
                    queryParams: { returnUrl: 'dashboard' },
                }),
            );
    }

    hasDeviated(deviations, spec: SwaySpec) {
        return deviations.some((d) => d.spec_id === spec.id);
    }
}
