import { createReducer, on } from '@ngrx/store';
import { Status, initialStatus } from '../../../../status.interface';
import * as actions from './actions';
import { CsOAuthConfiguration } from './model';

export const featureKey = 'teamsCsOAuthConfiguration';

export interface State extends Status {
    data: CsOAuthConfiguration;
}

export const initialState: State = {
    data: undefined,
    ...initialStatus,
};

export const reducer = createReducer(
    initialState,

    on(actions.fetchCsOAuthConfigurationSuccess, (state, { data }) => ({ ...state, loaded: true, data })),
    on(actions.fetchCsOAuthConfigurationFailure, (state, { error }) => ({ ...state, error })),
    on(actions.updateCsOAuthConfiguration, (state) => ({ ...state, updating: true })),
    on(actions.updateCsOAuthConfigurationSuccess, (state, { data }) => ({
        ...state,
        data: { ...state.data, ...data },
        updating: false,
    })),
    on(actions.updateCsOAuthConfigurationFailure, (state, { error }) => ({ ...state, error, updating: false })),
);

export const selectItem = (state: State) => state.data;
