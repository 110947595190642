import { Group } from '@microsoft/microsoft-graph-types-beta';
import { createAction, props } from '@ngrx/store';

export const loadGroups = createAction('[Graph/Group] Load', props<{ _tenant: string }>());

export const loadGroupsSuccess = createAction(
    '[Graph/Group] Load Success',
    props<{ _tenant: string; groups: Group[] }>(),
);

export const loadGroupsFailure = createAction('[Graph/Group] Load Failure', props<{ _tenant: string; error: any }>());
