import { createAction, props } from '@ngrx/store';
import { CurrentPostureCounts } from './model';

export const loadReportCurrentPostureCount = createAction(
    '[Report/Baseline Current Posture Count] Load',
    props<{ _tenant: string }>(),
);

export const loadReportCurrentPostureCountSuccess = createAction(
    '[Report/Baseline Current Posture Count] Load Success',
    props<{ _tenant: string; data: CurrentPostureCounts }>(),
);

export const loadReportCurrentPostureCountFailure = createAction(
    '[Report/Baseline Current Posture Count] Load Failure',
    props<{ _tenant: string; error: any }>(),
);
