export function DevConfig(host: string, production = true) {
    return {
        production,
        host,
        stage: 'development',
        appId: '2dcdfd03-dd43-4836-9e8f-03d668f460b5',
        bugsnag: {
            api_key: 'de4f46b3edd5e63b600ac1eb6d2c7751',
        },
        principalDisplayName: 'Octiga Cloud Security - Dev',
        stripe: {
            pk: 'pk_test_51HH61vETpOxjeWDyLDTuOoiHrrG7n8zxUepGqJjyDBk2txhFdeSmwxc3TbgRomLQS2dIEOSuItCknzbtaSkWHQP200s1hfm9OZ',
            prices: {
                premium: 'price_1HP6yKETpOxjeWDytx40TnVz',
            },
        },
        oauth: {
            client: {
                client_id: '2dcdfd03-dd43-4836-9e8f-03d668f460b5',
                authenticate_redirect_uri: `${host}/api/microsoft/oauth/authenticate`,
                authorise_redirect_uri: `${host}/api/microsoft/oauth/authorise`,
                return_url: `${host}/dashboard`,
            },
            msp: {
                client_id: '1265e19a-a324-4a6e-b96f-00e673ce2b44',
                authenticate_redirect_uri: `${host}/api/microsoft/oauth/authenticate/msp`,
                return_url: `${host}/msp/dashboard`,
            },
        },
    };
}

export const environment = DevConfig('https://devoctiga.com');
