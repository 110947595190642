import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as UpdateDateListActions from './actions';
import { UpdateDateList } from './model';

export const featureKey = 'updateDateLists';

export interface State extends EntityState<UpdateDateList> {
    fetched: boolean;
}

export const adapter: EntityAdapter<UpdateDateList> = createEntityAdapter<UpdateDateList>({
    selectId: (d) => d.start,
    sortComparer: (a, b) => a.start.localeCompare(b.start),
});

export const initialState: State = adapter.getInitialState({
    fetched: false,
});

export const reducer = createReducer(
    initialState,
    on(UpdateDateListActions.addUpdateDateList, (state, action) => adapter.addOne(action.updateDateList, state)),
    on(UpdateDateListActions.upsertUpdateDateList, (state, action) => adapter.upsertOne(action.updateDateList, state)),
    on(UpdateDateListActions.addUpdateDateLists, (state, action) => adapter.addMany(action.updateDateLists, state)),
    on(UpdateDateListActions.upsertUpdateDateLists, (state, action) =>
        adapter.upsertMany(action.updateDateLists, { ...state, fetched: true }),
    ),
    on(UpdateDateListActions.updateUpdateDateList, (state, action) => adapter.updateOne(action.updateDateList, state)),
    on(UpdateDateListActions.updateUpdateDateLists, (state, action) =>
        adapter.updateMany(action.updateDateLists, state),
    ),
    on(UpdateDateListActions.deleteUpdateDateList, (state, action) => adapter.removeOne(action.id, state)),
    on(UpdateDateListActions.deleteUpdateDateLists, (state, action) => adapter.removeMany(action.ids, state)),
    on(UpdateDateListActions.loadUpdateDateLists, (state, action) => adapter.setAll(action.updateDateLists, state)),
    on(UpdateDateListActions.clearUpdateDateLists, (state) => adapter.removeAll(state)),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const getFetched = (state: State) => state.fetched;
