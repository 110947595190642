import { createAction, props } from '@ngrx/store';

export const loadMspCwmCompanies = createAction('[Msp/CWM] Load Msp Cwm Companies');

export const loadMspCwmCompaniesSuccess = createAction(
    '[Msp/CWM] Load Msp Cwm Companies Success',
    props<{ data: any }>(),
);

export const loadMspCwmCompaniesFailure = createAction(
    '[Msp/CWM] Load Msp Cwm Companies Failure',
    props<{ error: any }>(),
);
