import { createAction, props } from '@ngrx/store';
import { InputCountry } from './model';

export const loadInputCountry = createAction('[EventRuleInputCountry] Load');

export const loadInputCountrySuccess = createAction(
    '[EventRuleInputCountry] Load Success',
    props<{ data: InputCountry[] }>(),
);

export const loadInputCountryFailure = createAction('[EventRuleInputCountry] Load Failure', props<{ error: any }>());

export const createInputCountry = createAction('[EventRuleInputCountry] Create', props<{ data: InputCountry }>());

export const createInputCountrySuccess = createAction(
    '[EventRuleInputCountry] Create Success',
    props<{ data: InputCountry }>(),
);

export const createInputCountryFailure = createAction(
    '[EventRuleInputCountry] Create Failure',
    props<{ error: any }>(),
);

export const deleteInputCountry = createAction('[EventRuleInputCountry] Delete', props<{ id: string }>());

export const deleteInputCountrySuccess = createAction(
    '[EventRuleInputCountry] Delete Success',
    props<{ id: string }>(),
);

export const deleteInputCountryFailure = createAction(
    '[EventRuleInputCountry] Delete Failure',
    props<{ id: string; error: any }>(),
);
