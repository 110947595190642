import { createAction, props } from '@ngrx/store';
import { HostedOutboundSpamFilterPolicy } from './model';

export const fetchHostedOutboundSpamFilterPolicy = createAction(
    '[Client/Pwsh/Exo] Fetch Hosted Outbound Spam Filter Policy',
    props<{ _tenant: string }>(),
);

export const fetchHostedOutboundSpamFilterPolicySuccess = createAction(
    '[Client/Pwsh/Exo] Fetch Hosted Outbound Spam Filter Policy Success',
    props<{ _tenant: string; data: HostedOutboundSpamFilterPolicy[] }>(),
);

export const fetchHostedOutboundSpamFilterPolicyFailure = createAction(
    '[Client/Pwsh/Exo] Fetch Hosted Outbound Spam Filter Policy Failure',
    props<{ _tenant: string; error: any }>(),
);

export const updateExoHostedOutboundSpamFilterPolicy = createAction(
    '[Client/Pwsh/Exo] Update Hosted Outbound Spam Filter Policy',
    props<{ _tenant: string; data: Partial<HostedOutboundSpamFilterPolicy> }>(),
);

export const updateExoHostedOutboundSpamFilterPolicySuccess = createAction(
    '[Client/Pwsh/Exo] Update Hosted Outbound Spam Filter Policy Success',
    props<{ _tenant: string; data: HostedOutboundSpamFilterPolicy }>(),
);

export const updateExoHostedOutboundSpamFilterPolicyFailure = createAction(
    '[Client/Pwsh/Exo] Update Hosted Outbound Spam Filter Policy Failure',
    props<{ _tenant: string; error: any }>(),
);
