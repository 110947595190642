import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ChangesLog } from './model';
import * as ChangesActions from './actions';

export const featureKey = 'changes';

export interface State extends EntityState<ChangesLog> {
    // additional entities state properties
    loaded: boolean;
    fetchedDates: string[];
}

export const adapter: EntityAdapter<ChangesLog> = createEntityAdapter<ChangesLog>({
    selectId: (c) => c.timestamp_user_actor_type,
});

export const initialState: State = adapter.getInitialState({
    // additional entity state properties
    loaded: false,
    fetchedDates: [],
});

export const reducer = createReducer(
    initialState,

    on(ChangesActions.fetchChangesSuccess, (state, action) =>
        adapter.upsertMany(action.changes, {
            ...state,
            loaded: true,
            fetchedDates: [...new Set(state.fetchedDates.concat(action.fetchedDates).sort())],
        }),
    ),
    on(ChangesActions.addChange, (state, action) => adapter.addOne(action.change, state)),
    on(ChangesActions.upsertChange, (state, action) => adapter.upsertOne(action.change, state)),
    on(ChangesActions.addChanges, (state, action) => adapter.addMany(action.changes, state)),
    on(ChangesActions.upsertChanges, (state, action) => adapter.upsertMany(action.changes, { ...state })),
    on(ChangesActions.updateChange, (state, action) => adapter.updateOne(action.change, state)),
    on(ChangesActions.updateChanges, (state, action) => adapter.updateMany(action.changes, state)),
    on(ChangesActions.deleteChange, (state, action) => adapter.removeOne(action.id, state)),
    on(ChangesActions.deleteChanges, (state, action) => adapter.removeMany(action.ids, state)),
    on(ChangesActions.clearChanges, (state) => adapter.removeAll(state)),
);

export const selectLoading = (state: State) => !state.loaded;
export const selectFetchedDates = (state: State) => state.fetchedDates;

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
