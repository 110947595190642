import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, expand, map, mergeMap, reduce } from 'rxjs/operators';
import { updateMsp } from 'src/app/modules/msp/store/msp/actions';
import { MspAjaxService } from 'src/app/services/ajax/msp-ajax.service';
import * as actions from './actions';

@Injectable()
export class CwmSubTypesEffects {
    loadMspCWMConfigSubTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadMspCwmSubTypes),
            mergeMap((action) =>
                this.getSubTypes(action.board_id, action.type_id).pipe(
                    map((data) =>
                        actions.loadMspCwmSubTypesSuccess({ data, board_id: action.board_id, type_id: action.type_id }),
                    ),
                    catchError((error) => {
                        this.store.dispatch(updateMsp({ data: { cwm: false } }));
                        return of(actions.loadMspCwmSubTypesFailure({ error }));
                    }),
                ),
            ),
        ),
    );

    getSubTypes(board_id: number, type_id: number) {
        return this.mspAjax
            .get(
                `/api/octiga/v2/cwm/service/boards/${board_id}/subTypes?conditions=typeAssociationIds contains ${type_id}`,
            )
            .pipe(
                expand((res) => {
                    if (res.nextPageUrl) {
                        return this.mspAjax.get(
                            '/api/octiga/v2/cwm' + res.nextPageUrl.split('/v4_6_release/apis/3.0')[1],
                        );
                    }
                    return EMPTY;
                }),
                reduce((acc, res: any) => acc.concat(res.data), []),
            );
    }

    constructor(
        private actions$: Actions,
        private mspAjax: MspAjaxService,
        private store: Store<any>,
    ) {}
}
