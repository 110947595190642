import { Injectable } from '@angular/core';
import { User } from '@microsoft/microsoft-graph-types-beta';
import { select, Store } from '@ngrx/store';
import { Action, TypedAction } from '@ngrx/store/src/models';
import { JSONSchema7 } from 'json-schema';
import { map, Observable, of, sample } from 'rxjs';
import { client } from 'src/app/stores/client';
import { fetchGraphAuthenticationMethods } from 'src/app/stores/client/graph/authentication/methods/actions';
import { loadGroups } from 'src/app/stores/client/graph/group/actions';
import { loadGraphUsers } from 'src/app/stores/client/graph/user/user.actions';
import { fetchCasMailboxes } from 'src/app/stores/client/powershell/exo/cas-mailbox/actions';
import { CasMailbox } from 'src/app/stores/client/powershell/exo/cas-mailbox/model';
import { fetchMailboxes } from 'src/app/stores/client/powershell/exo/mailbox/actions';
import { Baseline } from 'src/app/stores/client/sway/baseline/model';
import { EntityItemStatus, Status } from 'src/app/stores/status.interface';
import { loadDirectoryRoles } from './../../../../stores/client/graph/directoryRoles/actions';
import { SwaySpec } from './../../../../stores/root/sway/spec/model';
import * as MFA from './baselines/custom/Mfa';
import * as PerUserMfaState from './baselines/graph/perUserMfa';
import * as ActiveSyncEnabled from './baselines/exo/ActiveSyncEnabled';
import * as EwsEnabled from './baselines/exo/EwsEnabled';
import * as ImapEnabled from './baselines/exo/ImapEnabled';
import * as MapiEnabled from './baselines/exo/MapiEnabled';
import * as OutlookMobileEnabled from './baselines/exo/OutlookMobileEnabled';
import * as OwaEnabled from './baselines/exo/OwaEnabled';
import * as Pop3Enabled from './baselines/exo/Pop3Enabled';
import * as SmtpClientAuthDisabled from './baselines/exo/SmtpClientAuthDisabled';
import * as AccountEnabled from './baselines/graph/AccountEnabled';
import * as DormantUsers from './baselines/graph/DormantUsers';
import { fetchGraphPerUserMfaState } from 'src/app/stores/client/graph/authentication/perUserMfaState/actions';
import { loadConditionalAccessPolicy } from 'src/app/stores/client/graph/conditional-access/actions';
import { SERVICE_PLAN_KEY } from 'src/app/services/check-service-plans.service';

export interface UserExclusion {
    type:
        | 'missing_data'
        | 'group_baseline_override'
        | 'tenant_baseline_override'
        | 'user_baseline_override'
        | 'other'
        | 'sign-in_blocked'
        | 'guest_user'
        | 'missing_plans';
    desc: string;
    icon: 'arrow_upward' | 'not_interested' | 'remove' | 'person' | 'close' | 'thumb_down' | 'groups';
    link?: string;
    color?: string;
    class?: 'na-icon';
}

const ExchangePlans: SERVICE_PLAN_KEY[] = [
    'fc52cc4b-ed7d-472d-bbe7-b081c23ecc56', // EXCHANGE ONLINE PLAN 1
    '9aaf7827-d63c-4b61-89c3-182f06f82e5c', // EXCHANGE STANDARD
    'efb87545-963c-4e0d-99df-69c6916d9eb0', // EXCHANGE ONLINE PLAN 2
];

const MicrosoftEntraP1OrP2Plans: SERVICE_PLAN_KEY[] = [
    '41781fb2-bc02-4b7c-bd55-b576c07bb09d', //  Microsoft Entra ID P1
    'eec0eb4f-6444-4f95-aba0-50c24d67f998', //  Microsoft Entra ID P2
];

const ExchangeOnlineKioskPlan: SERVICE_PLAN_KEY[] = ['4a82b400-a79f-41a4-b4e2-e94f5787b113'];

interface RegistryItem {
    // TODO, karim too much props
    tag: string;
    form: any;
    validator: any;
    fetch_data: Array<Action>;
    select_data: Observable<any>;
    select_status: Observable<Status>;
    select_entity_status: (entity: any) => Observable<EntityItemStatus>;
    createRemediationAction?: (
        _tenant: string,
        data: any,
        schema: JSONSchema7,
    ) => TypedAction<any> | Array<TypedAction<any>>;
    matcher: (user: User, data: any) => boolean;
    // TODO: deprecate field
    schemaField: string;
    currentValueField: string;
    mapCurrentValue: (value: any) => any;
    excluded?: (data: any, specs: SwaySpec[], baselines: Baseline[]) => UserExclusion; // excluded users from checker
    href?: string;
    plans?: Array<SERVICE_PLAN_KEY | SERVICE_PLAN_KEY[]>; // nested array for OR plans
    alert?: { title: string }; // TODO, karim shouldn't be any
    currentValueStr?: string;
    scopes?: string[];
    getSchemaValue: (schema: any, field) => any;
}

export function Zipper(
    matcher: (user: User, data: any) => boolean,
): <T = any>(users: User[], data: T[]) => [User, T | null][] {
    return function <T = any>(users: User[], data: T[]) {
        const results: [User, T | null][] = [];
        if (data && users instanceof Array) {
            for (const user of users) {
                const matched = data.find((item) => matcher(user, item));
                results.push([user, matched || null]);
            }
            return results;
        }
    };
}

@Injectable({
    providedIn: 'root',
})
export class GroupSpecRegistryService {
    public dispatcher(_tenant: string): void {
        // TODO, karim.
        this.store
            .select(client(_tenant).graph.users.all)
            .pipe(sample(this.store.select(client(_tenant).graph.users.status).pipe(map((s) => s.loaded))))
            .subscribe((users) => {
                const ids = users.map((user) => user.id!);
                if (ids.length > 0) {
                    this.store.dispatch(fetchGraphAuthenticationMethods({ _tenant, ids }));
                    this.store.dispatch(fetchGraphPerUserMfaState({ _tenant, ids }));
                }
            });
    }

    constructor(private store: Store<any>) {}

    public get(tenant: string, tag: string): RegistryItem | null {
        const items = this.registry.map((fn) => fn(tenant));
        const item = items.find((item) => item.tag == tag);

        if (item === undefined) {
            console.error('Registry item not found: ' + tag);
            return;
        }

        return item;
    }

    public getAll(tenant: string) {
        return this.registry.map((fn) => fn(tenant));
    }

    private registry: Array<(tid: string) => RegistryItem> = [
        (tenant: string) => ({
            tag: 'EwsEnabled',
            form: EwsEnabled.FormComponent,
            // plans: ExchangePlans,
            plans: [...ExchangePlans],
            validator: EwsEnabled.ValidatorComponent,
            fetch_data: [fetchCasMailboxes({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.exoCasMailbox.all)),
            select_status: this.store.pipe(select(client(tenant).powershell.exoCasMailbox.status)),
            select_entity_status: (entity: any) =>
                this.store.pipe(
                    select(client(tenant).powershell.exoCasMailbox.entityStatus(entity, EwsEnabled.fieldName)),
                ),
            createRemediationAction: EwsEnabled.Remediate,
            matcher: (u: User, i: CasMailbox) => u.id === i.ExternalDirectoryObjectId,
            schemaField: EwsEnabled.fieldName,
            currentValueField: EwsEnabled.fieldName,
            href: EwsEnabled.href,
            mapCurrentValue: (value) => (value === true ? 'Enabled' : value === false ? 'Disabled' : '-'),
            getSchemaValue: (schema, field) => schema.properties[field]?.['const'],
        }),

        (tenant: string) => ({
            tag: 'ImapEnabled',
            form: ImapEnabled.FormComponent,
            plans: [...ExchangePlans],

            validator: ImapEnabled.ValidatorComponent,
            fetch_data: [fetchCasMailboxes({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.exoCasMailbox.all)),
            select_status: this.store.pipe(select(client(tenant).powershell.exoCasMailbox.status)),
            select_entity_status: (entity: any) =>
                this.store.pipe(
                    select(client(tenant).powershell.exoCasMailbox.entityStatus(entity, ImapEnabled.fieldName)),
                ),
            createRemediationAction: ImapEnabled.Remediate,
            matcher: (u: User, i: CasMailbox) => u.id === i.ExternalDirectoryObjectId,
            schemaField: ImapEnabled.fieldName,
            currentValueField: ImapEnabled.fieldName,
            href: ImapEnabled.href,
            mapCurrentValue: (value) => (value === true ? 'Enabled' : value === false ? 'Disabled' : '-'),
            getSchemaValue: (schema, field) => schema.properties[field]?.['const'],
        }),

        (tenant: string) => ({
            tag: 'OwaEnabled',
            form: OwaEnabled.FormComponent,
            validator: OwaEnabled.ValidatorComponent,
            plans: [...ExchangePlans, ...ExchangeOnlineKioskPlan],

            fetch_data: [fetchCasMailboxes({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.exoCasMailbox.all)),
            select_status: this.store.pipe(select(client(tenant).powershell.exoCasMailbox.status)),
            select_entity_status: (entity: any) =>
                this.store.pipe(
                    select(client(tenant).powershell.exoCasMailbox.entityStatus(entity, OwaEnabled.fieldName)),
                ),
            createRemediationAction: OwaEnabled.Remediate,
            matcher: (u: User, i: CasMailbox) => u.id === i.ExternalDirectoryObjectId,
            schemaField: OwaEnabled.fieldName,
            currentValueField: OwaEnabled.fieldName,
            href: OwaEnabled.href,
            mapCurrentValue: (value) => (value === true ? 'Enabled' : value === false ? 'Disabled' : '-'),
            getSchemaValue: (schema, field) => schema.properties[field]?.['const'],
        }),

        (tenant: string) => ({
            tag: 'Pop3Enabled',
            form: Pop3Enabled.FormComponent,
            plans: [...ExchangePlans, ...ExchangeOnlineKioskPlan],

            validator: Pop3Enabled.ValidatorComponent,
            fetch_data: [fetchCasMailboxes({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.exoCasMailbox.all)),
            select_status: this.store.pipe(select(client(tenant).powershell.exoCasMailbox.status)),
            select_entity_status: (entity: any) =>
                this.store.pipe(
                    select(client(tenant).powershell.exoCasMailbox.entityStatus(entity, Pop3Enabled.fieldName)),
                ),
            createRemediationAction: Pop3Enabled.Remediate,
            matcher: (u: User, i: CasMailbox) => u.id === i.ExternalDirectoryObjectId,
            schemaField: Pop3Enabled.fieldName,
            currentValueField: Pop3Enabled.fieldName,
            href: Pop3Enabled.href,
            mapCurrentValue: (value) => (value === true ? 'Enabled' : value === false ? 'Disabled' : '-'),
            getSchemaValue: (schema, field) => schema.properties[field]?.['const'],
        }),

        (tenant: string) => ({
            tag: 'OutlookMobileEnabled',
            form: OutlookMobileEnabled.FormComponent,
            plans: [...ExchangePlans, ...ExchangeOnlineKioskPlan],
            validator: OutlookMobileEnabled.ValidatorComponent,
            fetch_data: [fetchCasMailboxes({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.exoCasMailbox.all)),
            select_status: this.store.pipe(select(client(tenant).powershell.exoCasMailbox.status)),
            select_entity_status: (entity: any) =>
                this.store.pipe(
                    select(
                        client(tenant).powershell.exoCasMailbox.entityStatus(entity, OutlookMobileEnabled.fieldName),
                    ),
                ),
            createRemediationAction: OutlookMobileEnabled.Remediate,
            matcher: (u: User, i: CasMailbox) => u.id === i.ExternalDirectoryObjectId,
            schemaField: OutlookMobileEnabled.fieldName,
            currentValueField: OutlookMobileEnabled.fieldName,
            href: '',
            mapCurrentValue: (value) => (value === true ? 'Enabled' : value === false ? 'Disabled' : '-'),
            getSchemaValue: (schema, field) => schema.properties[field]?.['const'],
        }),

        (tenant: string) => ({
            tag: 'MapiEnabled',
            form: MapiEnabled.FormComponent,
            validator: MapiEnabled.ValidatorComponent,
            fetch_data: [fetchCasMailboxes({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.exoCasMailbox.all)),
            plans: [...ExchangePlans],
            select_status: this.store.pipe(select(client(tenant).powershell.exoCasMailbox.status)),
            select_entity_status: (entity: any) =>
                this.store.pipe(
                    select(client(tenant).powershell.exoCasMailbox.entityStatus(entity, MapiEnabled.fieldName)),
                ),
            createRemediationAction: MapiEnabled.Remediate,
            matcher: (u: User, i: CasMailbox) => u.id === i.ExternalDirectoryObjectId,
            schemaField: MapiEnabled.fieldName,
            currentValueField: MapiEnabled.fieldName,
            href: MapiEnabled.href,
            mapCurrentValue: (value) => (value === true ? 'Enabled' : value === false ? 'Disabled' : '-'),
            getSchemaValue: (schema, field) => schema.properties[field]?.['const'],
        }),

        (tenant: string) => ({
            tag: 'ActiveSyncEnabled',
            form: ActiveSyncEnabled.FormComponent,
            validator: ActiveSyncEnabled.ValidatorComponent,
            plans: [...ExchangePlans, ...ExchangeOnlineKioskPlan],
            fetch_data: [fetchCasMailboxes({ _tenant: tenant })],
            select_data: this.store.pipe(select(client(tenant).powershell.exoCasMailbox.all)),
            select_status: this.store.pipe(select(client(tenant).powershell.exoCasMailbox.status)),
            select_entity_status: (entity: any) =>
                this.store.pipe(
                    select(client(tenant).powershell.exoCasMailbox.entityStatus(entity, ActiveSyncEnabled.fieldName)),
                ),
            createRemediationAction: ActiveSyncEnabled.Remediate,
            matcher: (u: User, i: CasMailbox) => u.id === i.ExternalDirectoryObjectId,
            schemaField: ActiveSyncEnabled.fieldName,
            currentValueField: ActiveSyncEnabled.fieldName,
            href: ActiveSyncEnabled.href,
            mapCurrentValue: (value) => (value === true ? 'Enabled' : value === false ? 'Disabled' : '-'),
            getSchemaValue: (schema, field) => schema.properties[field]?.['const'],
        }),

        (tenant: string) => ({
            tag: 'SmtpClientAuthDisabled',
            form: SmtpClientAuthDisabled.FormComponent,
            validator: SmtpClientAuthDisabled.ValidatorComponent,
            fetch_data: [fetchCasMailboxes({ _tenant: tenant })],
            plans: [...ExchangePlans, ...ExchangeOnlineKioskPlan],

            select_data: this.store.pipe(select(client(tenant).powershell.exoCasMailbox.all)),
            select_status: this.store.pipe(select(client(tenant).powershell.exoCasMailbox.status)),
            select_entity_status: (entity: any) =>
                this.store.pipe(
                    select(
                        client(tenant).powershell.exoCasMailbox.entityStatus(entity, SmtpClientAuthDisabled.fieldName),
                    ),
                ),
            createRemediationAction: SmtpClientAuthDisabled.Remediate,
            matcher: (u: User, i: CasMailbox) => u.id === i.ExternalDirectoryObjectId,
            schemaField: SmtpClientAuthDisabled.fieldName,
            currentValueField: SmtpClientAuthDisabled.fieldName,
            href: SmtpClientAuthDisabled.href,
            mapCurrentValue: (value) =>
                value === false ? 'Enabled' : value === true ? 'Disabled' : 'Controlled by Global SMTP Policy.',
            getSchemaValue: (schema, field) => schema.properties[field]?.['const'],
        }),

        (tenant: string) => ({
            tag: 'MFA',
            form: MFA.FormComponent,
            validator: MFA.ValidatorComponent,
            schemaField: MFA.fieldName,
            currentValueField: MFA.fieldName,
            select_data: MFA.selectData(this.store, tenant),
            select_status: MFA.select_status(this.store, tenant),
            fetch_data: [
                loadDirectoryRoles({ _tenant: tenant }),
                loadGraphUsers({ _tenant: tenant }),
                loadConditionalAccessPolicy({ _tenant: tenant }),
                fetchMailboxes({ _tenant: tenant }),
                loadGroups({ _tenant: tenant }),
            ],

            excluded: MFA.exclude,
            select_entity_status: (entity: any) => of({ updating: false, deleting: false, error: false }), // don't care , we don't remediate
            matcher: (u: User, i: User) => u.id === i.id,
            href: MFA.href,
            mapCurrentValue: (value) => (value === true ? 'Enrolled' : value === false ? 'Not Enrolled' : '-'),
            getSchemaValue: (schema, field) => schema.properties[field]?.['const'],
        }),

        (tenant: string) => ({
            tag: 'PerUserMfaState',
            form: PerUserMfaState.FormComponent,
            validator: PerUserMfaState.ValidatorComponent,
            schemaField: PerUserMfaState.fieldName,
            currentValueField: PerUserMfaState.fieldName,
            select_data: PerUserMfaState.fetch_data(this.store, tenant),
            select_status: PerUserMfaState.select_status(this.store, tenant),
            fetch_data: [
                loadDirectoryRoles({ _tenant: tenant }),
                loadGraphUsers({ _tenant: tenant }),
                fetchMailboxes({ _tenant: tenant }),
                loadConditionalAccessPolicy({ _tenant: tenant }),
                loadGroups({ _tenant: tenant }),
            ],
            createRemediationAction: PerUserMfaState.Remediate,
            excluded: PerUserMfaState.exclude,
            select_entity_status: (entity: any) => of({ updating: false, deleting: false, error: false }), // TODO, karim. fix entity status.
            matcher: (u: User, i: User) => u.id === i.id,
            href: PerUserMfaState.href,
            mapCurrentValue: (value) => value,
            getSchemaValue: (schema, field) =>
                schema.properties[field]?.['enum'] ? 'Enabled' : schema.properties[field]?.['const'],
        }),

        (tenant: string) => ({
            tag: 'AccountEnabled',
            form: AccountEnabled.FormComponent,
            validator: AccountEnabled.ValidatorComponent,
            schemaField: AccountEnabled.fieldName,
            currentValueField: AccountEnabled.fieldName,
            select_data: AccountEnabled.fetch_data(this.store, tenant),
            select_status: AccountEnabled.select_status(this.store, tenant),
            excluded: AccountEnabled.exclude,
            createRemediationAction: AccountEnabled.Remediate,
            href: AccountEnabled.href,

            fetch_data: [loadGraphUsers({ _tenant: tenant }), fetchMailboxes({ _tenant: tenant })],
            select_entity_status: (entity: any) => of({ updating: false, deleting: false, error: false }), // don't care

            matcher: (u: User, i: User) => u.id === i.id,
            mapCurrentValue: (value) => (value === true ? 'Allowed' : value === false ? 'Blocked' : '-'),
            getSchemaValue: (schema, field) => schema.properties[field]?.['const'],
        }),

        (tenant: string) => ({
            tag: 'DormantUsers',
            form: DormantUsers.FormComponent,
            validator: DormantUsers.ValidatorComponent,
            schemaField: DormantUsers.schemaFieldName,
            currentValueField: DormantUsers.currentValueFieldName,
            select_data: DormantUsers.fetch_data(this.store, tenant),
            select_status: DormantUsers.select_status(this.store, tenant),
            excluded: DormantUsers.excludeFnForRegularUsers,
            scopes: ['AuditLog.Read.All'],
            // plans : [...MicrosoftEntraP1OrP2Plans],
            createRemediationAction: DormantUsers.Remediate,

            alert: {
                title: 'You can remediate this issue by logging into Office 365 to prevent the user(s) from becoming dormant, or by blocking the user(s) from signing in by clicking the "Proceed" button below.',
            },

            // href: DormantUsers.href,
            currentValueStr: 'Last Login | Creation Date',
            fetch_data: [loadGraphUsers({ _tenant: tenant }), loadDirectoryRoles({ _tenant: tenant })],

            select_entity_status: (entity: any) => of({ updating: false, deleting: false, error: false }), //TODO, karim
            mapCurrentValue: (value) => value,

            matcher: (u: User, i: User) => u.id === i.id,
            getSchemaValue: (schema, field) => schema.properties[field]?.['maximum'],
        }),

        (tenant: string) => ({
            tag: 'DormantAdmins',
            form: DormantUsers.FormComponent,
            validator: DormantUsers.ValidatorComponent,
            schemaField: DormantUsers.schemaFieldName,
            currentValueField: DormantUsers.currentValueFieldName,
            select_data: DormantUsers.fetch_data(this.store, tenant),
            select_status: DormantUsers.select_status(this.store, tenant),
            excluded: DormantUsers.excludeFnForAdmins,
            currentValueStr: 'Last Login | Creation Date',
            scopes: ['AuditLog.Read.All'],
            // plans : [...MicrosoftEntraP1OrP2Plans],

            createRemediationAction: DormantUsers.Remediate,
            // href: DormantUsers.href,
            alert: {
                title: 'You can remediate this issue by logging into Office 365 to prevent the user(s) from becoming dormant, or by blocking the user(s) from signing in by clicking the "Proceed" button below.',
            },
            fetch_data: [loadGraphUsers({ _tenant: tenant }), loadDirectoryRoles({ _tenant: tenant })],

            select_entity_status: (entity: any) => of({ updating: false, deleting: false, error: false }), //TODO, karim

            matcher: (u: User, i: User) => u.id === i.id,
            mapCurrentValue: (value) => value,
            getSchemaValue: (schema, field) => schema.properties[field]?.['maximum'],
        }),
    ];
}
