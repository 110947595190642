import { AdminAuditLogConfig } from 'src/app/stores/client/powershell/exo/admin-audit-log-config/model';
import { updateExoTransportConfig } from 'src/app/stores/client/powershell/exo/transport-config/actions';

export function Remediate(_tenant: string, data: Partial<AdminAuditLogConfig>, schema: any) {
    if (schema.properties.SmtpClientAuthenticationDisabled.const === false) {
        return updateExoTransportConfig({ _tenant, data: { SmtpClientAuthenticationDisabled: false } });
    }

    if (schema.properties.SmtpClientAuthenticationDisabled.const === true) {
        return updateExoTransportConfig({ _tenant, data: { SmtpClientAuthenticationDisabled: true } });
    }
}
