import { createAction, props } from '@ngrx/store';

export const loadMspCwmTypes = createAction('[Msp/CWM] Load Msp Cwm Types', props<{ board_id: number }>());

export const loadMspCwmTypesSuccess = createAction(
    '[Msp/CWM] Load Msp Cwm Types Success',
    props<{ data: any; board_id: number }>(),
);

export const loadMspCwmTypesFailure = createAction('[Msp/CWM] Load Msp Cwm Types Failure', props<{ error: any }>());
