import { Component, Input, OnInit } from '@angular/core';
import { BaselineValidatorComponent } from '../../abstract/baseline-validator.component';

@Component({
    selector: 'sway-validator',
    templateUrl: './validator.component.html',
    styleUrls: ['./validator.component.scss'],
})
export class ValidatorComponent extends BaselineValidatorComponent implements OnInit {
    @Input() data: any;

    ngOnInit(): void {
        console.log(this.schema);
        console.log(this.data);
    }
}
