import { createAction, props } from '@ngrx/store';

export const loadOrganization = createAction('[Root/Graph] Load Graph Organization', props<{ _tenant: string }>());

export const loadOrganizationSuccess = createAction(
    '[Root/Graph] Load Graph Organization Success',
    props<{ _tenant: string; data: any }>(),
);

export const loadOrganizationFailure = createAction(
    '[Root/Graph] Load Graph Organization Failure',
    props<{ _tenant: string; error: any }>(),
);
