import { createAction, props } from '@ngrx/store';
import { InputGeo } from './model';

export const loadInputGeo = createAction('[EventRuleInputGeo] Load');

export const loadInputGeoSuccess = createAction('[EventRuleInputGeo] Load Success', props<{ data: InputGeo[] }>());

export const loadInputGeoFailure = createAction('[EventRuleInputGeo] Load Failure', props<{ error: any }>());

export const createInputGeo = createAction('[EventRuleInputGeo] Create', props<{ data: InputGeo }>());

export const createInputGeoSuccess = createAction('[EventRuleInputGeo] Create Success', props<{ data: InputGeo }>());

export const createInputGeoFailure = createAction('[EventRuleInputGeo] Create Failure', props<{ error: any }>());

export const deleteInputGeo = createAction('[EventRuleInputGeo] Delete', props<{ id: string }>());

export const deleteInputGeoSuccess = createAction('[EventRuleInputGeo] Delete Success', props<{ id: string }>());

export const deleteInputGeoFailure = createAction(
    '[EventRuleInputGeo] Delete Failure',
    props<{ id: string; error: any }>(),
);
