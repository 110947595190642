import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import {
    loadReportCurrentPostureCount,
    loadReportCurrentPostureCountFailure,
    loadReportCurrentPostureCountSuccess,
} from './actons';
import { CurrentPostureCounts } from './model';

export const featureKey = 'reportCurrentPostureCount';

export interface State extends Status {
    data: CurrentPostureCounts;
}

export const initialState: State = {
    data: null,
    ...initialStatus,
};

export const reducer = createReducer(
    initialState,
    on(loadReportCurrentPostureCount, (state) => ({ ...state, loading: true })),
    on(loadReportCurrentPostureCountSuccess, (state, { data }) => ({ ...state, data, loading: false, loaded: true })),
    on(loadReportCurrentPostureCountFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false,
        loaded: false,
    })),
);

export const selectData = (state: State) => state.data;
