import { updateExoRemoteDomain } from 'src/app/stores/client/powershell/exo/remote-domain/actions';
import { RemoteDomain } from 'src/app/stores/client/powershell/exo/remote-domain/model';

export function Remediate(_tenant: string, data: Partial<RemoteDomain>, schema: any) {
    if (schema.properties.AutoForwardEnabled.const === false) {
        return updateExoRemoteDomain({ _tenant, data: { AutoForwardEnabled: false, Identity: 'Default' } });
    }

    if (schema.properties.AutoForwardEnabled.const === true) {
        return updateExoRemoteDomain({ _tenant, data: { AutoForwardEnabled: true, Identity: 'Default' } });
    }
}
