import { createAction, props } from '@ngrx/store';

export const loadSPOSiteUsage = createAction(
    '[Graph/SPO SharePoint Details] Load Spo Site ',
    props<{ _tenant: string }>(),
);

export const loadSPOSiteUsageSuccess = createAction(
    '[Graph/SPO SharePoint Details] Load Spo Site Success',
    props<{ _tenant: string; data: any }>(),
);

export const loadSPOSiteUsageFailure = createAction(
    '[Graph/SPO SharePoint Details] Load Spo Site Failure',
    props<{ _tenant: string; error: string }>(),
);
