import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { nextStep } from '../../store/actions/stepper.actions';
import * as fromBilling from '../../store/reducers';

export interface SubscriptionPlan {
    title: string;
    description: string;
    color: string;
    price: string;
    features: string[];
    disabled: boolean;
}

@Component({
    selector: 'billing-subscription-plan',
    templateUrl: './subscription-plan.component.html',
    styleUrls: ['./subscription-plan.component.scss'],
})
export class SubscriptionPlanComponent implements OnInit {
    @Input() plan: SubscriptionPlan;
    // @Input() selected: boolean;
    // @Output() selectedChange  = new EventEmitter<boolean>();

    constructor(private store: Store<fromBilling.State>) {}

    ngOnInit(): void {}

    public next() {
        this.store.dispatch(nextStep());
    }
}
