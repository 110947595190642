import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { User } from 'src/app/interfaces/octiga/user.interface';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as UserActions from './actions';

export const featureKey = 'octigaUser';

export interface State extends EntityState<User>, Status {}

const selectId = (user: User) => `${user.id}`;

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
    selectId,
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,

    on(UserActions.loadUsersSuccess, (state, { data }) => {
        state = { ...state, loaded: true };
        return adapter.setAll(data, state);
    }),
    on(UserActions.loadUsersFailure, (state, { error }) => ({ ...state, error })),
    on(UserActions.upsertUser, (state, action) => adapter.upsertOne(action.user, state)),
    on(UserActions.updateUserNotifications, (state) => ({ ...state })),
    on(UserActions.updateUserNotificationsSuccess, (state, action) => adapter.upsertOne(action.user, state)),
    on(UserActions.updateUserNotificationsFailure, (state, { error }) => ({ ...state, error })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
