import { ConditionalAccessPolicySchema, createCapPolicySchema } from '../shared/cap-schema';

function grantControls(grant) {
    return {
        type: 'object',
        properties: {
            builtInControls: {
                type: 'array',
                items: {
                    type: 'string',
                    const: grant,
                },
            },
        },
    };
}

const platforms = {
    type: 'object',
    properties: {
        includePlatforms: {
            type: 'array',
            items: {
                enum: ['All', 'all'],
                type: 'string',
            },
            minItems: 1,
            maxItems: 1,
            uniqueItems: true,
        },

        excludePlatforms: {
            type: 'array',
            items: {
                enum: ['android', 'iOS', 'macOS', 'windows'],
                type: 'string',
            },
            minItems: 4,
            maxItems: 4,
            uniqueItems: true,
        },
    },
};

function policy(form): ConditionalAccessPolicySchema {
    return {
        grantControls: grantControls(form.grant || 'block'),

        conditions: {
            platforms,
        },
    };
}

export const blockUnSupportedDeviceBaselineSchema = (form) => {
    return createCapPolicySchema(form, policy(form));
};
