import { Injectable } from '@angular/core';
import { Domain, Recipient, User } from '@microsoft/microsoft-graph-types-beta';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { InboxRule } from '../interfaces/powershell/exo/inbox-rule.interface';
import { MailboxFolderService } from './mailbox-folder.service';
import { CryptoService } from '../modules/shared/services/crypto.service';
import { Mailbox } from '../stores/client/powershell/exo/mailbox/model';

@Injectable({
    providedIn: 'root',
})
export class MailboxRuleService {
    constructor(
        private mailboxFolderService: MailboxFolderService,
        private crypto: CryptoService,
    ) {}

    public risky_folders: string[] = ['rss', 'delete', 'trash', 'junk', 'spam'];
    public risky_keywords: string[] = [
        'invoice',
        'bank detail',
        'payment detail',
        'account detail',
        'credit card',
        'billing',
        'iban',
    ];

    moveForwardDeleteActionCheck(rule: InboxRule): boolean {
        return (
            !!rule.actions.forwardTo ||
            !!rule.actions.forwardAsAttachmentTo ||
            !!rule.actions.redirectTo ||
            !!rule.actions.moveToFolder ||
            rule.actions.delete ||
            rule.actions.permanentDelete
        );
    }

    // use when no actions but the rule has condition(s)
    voteContainsFraudWordConditions(containWords: string[], votes: Array<string>) {
        const found = this.risky_keywords.some((w) => containWords.join(',').toLowerCase().indexOf(w) >= 0);
        if (found) {
            votes.push('warning');
        }
    }

    voteContainsFraudWord(containWords: string[], votes: Array<string>, rule_type: string, moveToFolderVote?: string) {
        const found = this.risky_keywords.some((w) => containWords.join(',').toLowerCase().indexOf(w) >= 0);
        if (found) {
            if (rule_type === 'move') {
                if (!!moveToFolderVote && moveToFolderVote === 'warning') {
                    votes.push('critical');
                } else {
                    votes.push('danger');
                }
            }
            if (rule_type === 'delete') {
                votes.push('info');
            }
        }
    }

    voteForwardCriticality(targets: Recipient[], votes: Array<string>, domains: Domain[]): void {
        if (targets) {
            targets.forEach((rd) => {
                if (this.forwardRulesOutsideOfOrg(rd.emailAddress.address, domains)) {
                    votes.push('critical');
                } else {
                    votes.push('info');
                }
            });
        }
    }

    voteFraudWordsCriticality(rule: InboxRule, votes: Array<string>, moveToFolderVote?: string) {
        if (this.moveForwardDeleteActionCheck(rule)) {
            const rule_type =
                rule.actions.delete || rule.actions.permanentDelete
                    ? 'delete'
                    : !!rule.actions.moveToFolder
                        ? 'move'
                        : 'moveOrForward';
            if (rule.conditions) {
                if (!!rule.conditions.bodyContains) {
                    this.voteContainsFraudWord(rule.conditions.bodyContains, votes, rule_type);
                }
                if (!!rule.conditions.bodyOrSubjectContains) {
                    this.voteContainsFraudWord(
                        rule.conditions.bodyOrSubjectContains,
                        votes,
                        rule_type,
                        moveToFolderVote,
                    );
                }
                if (!!rule.conditions.subjectContains) {
                    this.voteContainsFraudWord(rule.conditions.subjectContains, votes, rule_type, moveToFolderVote);
                }
                if (!!rule.conditions.headerContains) {
                    this.voteContainsFraudWord(rule.conditions.headerContains, votes, rule_type, moveToFolderVote);
                }
            }
        }
    }

    getMoveFolderNameSeverity(tenant_id: string, user_id: string, moveToFolder: string): Observable<string> {
        return this.mailboxFolderService.getMailFolderName(tenant_id, user_id, moveToFolder).pipe(
            take(1),
            map((folderName) => {
                return this.risky_folders.some((f) => folderName.toLowerCase().indexOf(f) >= 0) ? 'warning' : 'info';
            }),
        );
    }

    async getInboxRuleSeverity(tenant_id: string, user: User, rule: InboxRule, domains: Domain[]) {
        const votes: string[] = [];
        let moveToFolderVote = '';

        if (!rule.actions || !rule.isEnabled) {
            // no actions, but have suspicious condition(s)
            if (!!rule.conditions) {
                if (!!rule.conditions.bodyContains) {
                    this.voteContainsFraudWordConditions(rule.conditions.bodyContains, votes);
                }
                if (!!rule.conditions.bodyOrSubjectContains) {
                    this.voteContainsFraudWordConditions(rule.conditions.bodyOrSubjectContains, votes);
                }
                if (!!rule.conditions.subjectContains) {
                    this.voteContainsFraudWordConditions(rule.conditions.subjectContains, votes);
                }
                if (!!rule.conditions.headerContains) {
                    this.voteContainsFraudWordConditions(rule.conditions.headerContains, votes);
                }
                return votes.includes('critical')
                    ? 'critical'
                    : votes.includes('danger')
                        ? 'danger'
                        : votes.includes('warning')
                            ? 'warning'
                            : 'info';
            } else {
                return 'info';
            }
        }

        if (rule.actions.moveToFolder) {
            const promise: Promise<string> = new Promise((res, rej) =>
                this.getMoveFolderNameSeverity(tenant_id, user.id, rule.actions.moveToFolder).subscribe((vote) =>
                    res(vote),
                ),
            );
            moveToFolderVote = await promise;
            if (moveToFolderVote === 'warning') {
                votes.push('warning');
                // return Promise.resolve('warning');
            }
            votes.push('info');
        }
        if (rule.actions.delete) votes.push('info');
        if (rule.actions.permanentDelete) votes.push('danger');
        this.voteForwardCriticality(rule.actions.redirectTo, votes, domains);
        this.voteForwardCriticality(rule.actions.forwardAsAttachmentTo, votes, domains);
        this.voteForwardCriticality(rule.actions.forwardTo, votes, domains);
        this.voteFraudWordsCriticality(rule, votes, moveToFolderVote);
        return votes.includes('critical')
            ? 'critical'
            : votes.includes('danger')
                ? 'danger'
                : votes.includes('warning')
                    ? 'warning'
                    : 'info';
    }

    async setSeverity(
        tenant_id: string,
        users: Array<{ user: User; rules: Array<InboxRule> }>,
        domains: Domain[],
        dismiss_ids: string[],
    ): Promise<BehaviorSubject<Array<{ user: User; rules: Array<InboxRule> }>>> {
        const users$: BehaviorSubject<Array<{ user: User; rules: Array<InboxRule> }>> = new BehaviorSubject([]);
        for (let index = 0; index < users.length; index++) {
            const rules = users[index].rules.slice();
            for (let j = 0; j < rules.length; j++) {
                const rule = { ...rules[j] };
                rule.severity = await this.getInboxRuleSeverity(tenant_id, users[index].user, rule, domains);
                rule.dismiss = dismiss_ids.includes(this.crypto.hash(users[index].user.id + '_' + rule.id));
                rules[j] = rule;
            }
            users[index].rules = rules;
        }
        users$.next(users);
        return users$;
    }

    setOWA(
        ps_mailboxes: Mailbox[],
        mailbox: { user: User; rules: Array<InboxRule> },
        domains: Domain[],
        dismiss_ids: string[],
    ) {
        const ps_mbox = ps_mailboxes.find((ps) => mailbox.user.userPrincipalName === ps.UserPrincipalName);
        if (ps_mbox && (ps_mbox.ForwardingSmtpAddress || ps_mbox.ForwardingAddress)) {
            mailbox['owa'] = {
                DeliverToMailboxAndForward: ps_mbox.DeliverToMailboxAndForward,
                ForwardingSmtpAddress: ps_mbox.ForwardingSmtpAddress,
                ForwardingAddress: ps_mbox.ForwardingAddress,
                Severity: ps_mbox.ForwardingSmtpAddress
                    ? this.setOWASeverity(ps_mbox, domains)
                    : ps_mbox.ForwardingAddress
                        ? 'warning'
                        : undefined,
                Dismiss: dismiss_ids.includes(this.crypto.hash(mailbox.user.id + '_OWAForwarding')),
            };
        } else {
            if (mailbox['owa']) {
                delete mailbox['owa'];
            }
        }
        return mailbox;
    }

    private setOWASeverity(rule: Mailbox, domains: Domain[]) {
        const email = !!rule.ForwardingAddress ? rule.ForwardingAddress : (rule.ForwardingSmtpAddress as string);
        if (this.forwardRulesOutsideOfOrg(email, domains) === true) {
            return 'critical';
        } else {
            return 'warning';
        }
    }

    private forwardRulesOutsideOfOrg(email: string, domains: Domain[]): boolean {
        return !domains.some((d) => {
            if (email === undefined || email.split('@')[1] === undefined) return false;
            else {
                return email.split('@')[1].toLowerCase() === d.id?.toLowerCase();
            }
        });
    }
}
