import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { MspAjaxService } from 'src/app/services/ajax/msp-ajax.service';
import * as BillingActions from '../actions/billing.actions';

@Injectable()
export class BillingEffects {
    createCustomer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BillingActions.createCustomer),
            mergeMap((action) =>
                this.ajax.post('/api/billing/stripe/customer', action.data).pipe(
                    map((data) => BillingActions.createCustomerSuccess({ data })),
                    catchError((error) => of(BillingActions.createCustomerFailure({ error }))),
                ),
            ),
        ),
    );

    createSubscription$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BillingActions.createSubscription),
            mergeMap((action) =>
                this.ajax.post('/api/billing/stripe/subscription', action.data).pipe(
                    map((data) => BillingActions.createSubscriptionSuccess({ data })),
                    catchError((error) => of(BillingActions.createSubscriptionFailure({ error }))),
                ),
            ),
        ),
    );

    setPaymentMethod$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BillingActions.setPaymentMethod),
            mergeMap((action) =>
                this.ajax.post('/api/billing/stripe/payment', action.data).pipe(
                    map((data) => BillingActions.setPaymentMethodSuccess({ data })),
                    catchError((error) => of(BillingActions.setPaymentMethodFailure({ error }))),
                ),
            ),
        ),
    );

    constructor(
        private actions$: Actions,
        private ajax: MspAjaxService,
    ) {}
}
