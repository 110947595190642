import { SubscribedSku } from '@microsoft/microsoft-graph-types-beta';
import { createAction, props } from '@ngrx/store';

export const loadSubscribedSkus = createAction('[Graph/SubscribedSkus] Load', props<{ _tenant: string }>());

export const loadSubscribedSkusSuccess = createAction(
    '[Graph/SubscribedSkus] Load Success',
    props<{ _tenant: string; skus: SubscribedSku[] }>(),
);

export const loadSubscribedSkusFailure = createAction(
    '[Graph/SubscribedSkus] Load Failure',
    props<{ _tenant: string; error: any }>(),
);
