import { select, Store } from '@ngrx/store';
import { combineLatest, map, Observable, skipUntil } from 'rxjs';
import { MacOsCompliancePolicySchema } from 'src/app/modules/sway/tools/schemas/compliance-policies/MacOsCompliancePolicy/model';
import { client } from 'src/app/stores/client';
import { PolicyAssignment } from 'src/app/stores/client/graph/compliance-policies/policies/modet';
import { selectSwaySpecStatus } from 'src/app/stores/root.store';
export { BaselineComponent } from './baseline.component';
export { Remediate } from './remediate';
export const href = 'https://learn.microsoft.com/en-us/mem/intune/protect/compliance-policy-create-windows';

export function selectData(store: Store, tenant: string, tag: string): Observable<PolicyAssignment[]> {
    const baseline$ = store.pipe(select(client(tenant).sway.baselines.bySpecTag(tag)));
    const compliancePolicies$ = store.pipe(select(client(tenant).graph.compliancePolicies.all));

    const baselineStatus$ = store.pipe(select(client(tenant).sway.baselines.status));
    const swayStatus$ = store.pipe(select(selectSwaySpecStatus));
    const compliancePoliciesStatus$ = store.pipe(select(client(tenant).graph.compliancePolicies.status));

    // const policies$ = select(client(tenant).graph.compliancePolicies.windows10.all);

    const ready$ = combineLatest([baselineStatus$, swayStatus$, compliancePoliciesStatus$]).pipe(
        map((statuses) => statuses.every((status) => status.loaded)),
    );

    return combineLatest([compliancePolicies$, baseline$]).pipe(
        skipUntil(ready$),
        map(([policies, baseline]) => {
            if (!baseline) return [];
            const displayName = baseline?.schema?.contains?.properties?.policy?.properties?.displayName.const;
            const result = policies.filter((res) => res?.policy?.displayName === displayName);

            return result;
        }),
    );
}

export function getTargetGroupsIds(schema: MacOsCompliancePolicySchema, type): string[] {
    const items =
        schema?.contains?.properties?.assignments?.items?.properties?.target?.oneOf?.filter(
            (res) => res?.properties?.['@odata.type']?.const === type,
        ) || [];
    const result = items?.map((res) => res.properties.groupId.const);

    return result;
}

export function getPolicyAssignments(schema: MacOsCompliancePolicySchema) {
    const items = schema.contains.properties?.assignments?.items?.properties?.target?.oneOf;

    //  filter(res => res?.properties?.['@odata.type']?.const === targetType);

    const result = items?.map((schemaItem) => {
        const data_type = schemaItem.properties?.['@odata.type'].const;
        const groupId = schemaItem?.properties?.groupId?.const;

        return {
            '@odata.type': '#microsoft.graph.deviceCompliancePolicyAssignment',
            // id will be set in the effect
            target: {
                '@odata.type': data_type,
                groupId,
            },
        };
    });

    return result || [];
}
