
<mat-hint color="warn" class="main-hint block" *ngIf="!policy?.id">
    There is no {{formValue?.displayName}}, when you click remediate you are going to create new compliance policy with the desired
    values of the baseline
</mat-hint>

<mat-error *ngIf="formErrors">
    Please fix the baseline errors
</mat-error>


<form [formGroup]="form" *ngIf="loaded">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        Basics
                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status [hasError]="!policy?.id || errorString.includes('/policy/description')"></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td class="field">

                                Display Name
                            </td>

                            <td colspan="3">
                                {{formValue?.displayName}}
                            </td>
                        </tr>


                        <tr>
                            <td class="field">

                                Description
                            </td>

                            <td>
                                {{policy?.description}}
                            </td>

                            <td>
                                <mat-form-field appearance="outline">
                                    <textarea formControlName="description" matInput> </textarea>
                                </mat-form-field>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/description')"></sway-baseline-status>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>


    <div class="mb-6"></div>


    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        Device Health

                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status
                        [hasError]="
                        !policy?.id ||
                        errorString.includes('/policy/systemIntegrityProtectionEnabled')"
                        ></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr>
                            <td class="field">

                                Require system integrity protection

                                <mat-icon
                                        [matTooltip]="'Require System integrity protection to be enabled on this device.'">info</mat-icon>
                            </td>

                            <td>
                                {{policy?.systemIntegrityProtectionEnabled ? 'Required' : 'Not Configured'}}
                            </td>

                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="systemIntegrityProtectionEnabled">
                                    Required
                                </mat-slide-toggle>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/systemIntegrityProtectionEnabled')"></sway-baseline-status>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="mb-6"></div>


    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        Device Properties
                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status
                        [hasError]="
                        !policy?.id ||

                        (errorString.includes('/policy/osMinimumVersion') ||
                        errorString.includes('/policy/osMaximumVersion') ||
                        errorString.includes('/policy/osMinimumBuildVersion') ||
                        errorString.includes('/policy/osMaximumBuildVersion')  )
                        "
                        ></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Minimum OS version <mat-icon
                                        [matTooltip]="tooltips.osMinimumVersion">info</mat-icon>
                                </div>

                            </td>

                            <td>
                                {{policy?.osMinimumVersion || '-'}}
                            </td>

                            <td>
                                <mat-form-field appearance="outline" class="full-width">

                                    <input matInput formControlName="osMinimumVersion" />

                                    <mat-error *ngIf="form.get('osMinimumVersion').hasError('max')">Must be smaller than the maximum
                                        OS version</mat-error>
                                    <mat-error *ngIf="form.get('osMinimumVersion').hasError('pattern')">Must be a valid version
                                        format. Example: 4.3.06, 7.01.4, etc.</mat-error>
                                </mat-form-field>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/osMinimumVersion')"></sway-baseline-status>

                            </td>
                        </tr>



                        <tr>
                            <td class="field">
                                <div class="td-title">
                                    Maximum OS version<mat-icon
                                        [matTooltip]="tooltips.osMaximumVersion">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.osMaximumVersion || '-'}}
                            </td>

                            <td>
                                <mat-form-field appearance="outline" class="full-width">

                                    <input matInput formControlName="osMaximumVersion" />
                                    <mat-error *ngIf="form.get('osMaximumVersion').hasError('pattern')">Must be a valid version
                                        format. Example: 4.3.06, 7.01.4, etc.</mat-error>
                                    <mat-error *ngIf="form.get('osMaximumVersion').hasError('min')">Must be greater than the minimum
                                        OS version</mat-error>

                                </mat-form-field>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/osMaximumVersion')"></sway-baseline-status>

                            </td>
                        </tr>



                        <tr>
                            <td class="field">
                                <div class="td-title">
                                    Minimum OS version for mobile devices
                                    <mat-icon
                                        [matTooltip]="tooltips.osMinimumBuildVersion">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.osMinimumBuildVersion || '-'}}
                            </td>

                            <td>
                                <mat-form-field class="full-width" appearance="outline">
                                    <input matInput formControlName="osMinimumBuildVersion" />

                                </mat-form-field>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/osMinimumBuildVersion')"></sway-baseline-status>

                            </td>
                        </tr>



                        <tr>
                            <td class="field">
                                <div class="td-title">
                                    Maximum OS version for mobile devices<mat-icon
                                        [matTooltip]="tooltips.osMaximumBuildVersion">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.osMaximumBuildVersion || '-'}}
                            </td>

                            <td>
                                <mat-form-field class="full-width" appearance="outline">

                                    <input matInput formControlName="osMaximumBuildVersion" />

                                    <mat-error *ngIf="form.get('osMaximumBuildVersion').hasError('pattern')">Must be greater than
                                        the minimum OS version for mobile devices</mat-error>
                                    <mat-error *ngIf="form.get('osMaximumBuildVersion').hasError('min')">Must be a valid version
                                        format. Example: 4.3.06, 7.01.4, etc.</mat-error>
                                </mat-form-field>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/osMaximumBuildVersion')"></sway-baseline-status>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>



    <div class="mb-6"></div>


    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        System Security (Password)


                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status
                        [hasError]="
                        !policy?.id ||

                            (errorString.includes('/policy/passwordRequired')
                            || errorString.includes('/policy/passwordBlockSimple')
                            || errorString.includes('/policy/passwordMinimumLength')
                            || errorString.includes('/policy/passwordRequiredType')
                            || errorString.includes('/policy/passwordMinimumCharacterSetCount')

                                || errorString.includes('/policy/passwordMinutesOfInactivityBeforeLock')
                                || errorString.includes('/policy/passwordExpirationDays')
                                || errorString.includes('/policy/passwordPreviousPasswordBlockCount')
                                )

                        "

                        ></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>


                        <tr>
                            <td class="field">
                                <div class="td-title">
                                    Require a password to unlock devices. <mat-icon
                                        [matTooltip]="tooltips.passwordRequired">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.passwordRequired ? 'Required' : 'Not Configured'}}
                            </td>

                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="passwordRequired">
                                    Required
                                </mat-slide-toggle>

                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('!/policy/passwordRequired!')"></sway-baseline-status>

                            </td>

                        </tr>



                        <ng-container *ngIf="passwordRequired">

                            <tr>
                                <td class="field">
                                    <div class="td-title">

                                        Simple passwords

                                        <mat-icon
                                            [matTooltip]="tooltips.passwordBlockSimple">info</mat-icon>

                                    </div>
                                </td>

                                <td>
                                    {{policy?.passwordBlockSimple ? 'Required' : 'Not Configured'}}
                                </td>

                                <td>
                                    Not Configured

                                    <mat-slide-toggle [disabled]="!passwordRequired" color="primary"
                                        formControlName="passwordBlockSimple">
                                        Block
                                    </mat-slide-toggle>
                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passwordRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passwordBlockSimple')"></sway-baseline-status>

                                </td>

                            </tr>


                            <tr>
                                <td class="field">
                                    <div class="td-title">

                                        Minimum password length <mat-icon
                                            matTooltip="This setting specifies the minimum number of digits or characters in the password. Recommended Value: 4 (4-16)">info</mat-icon>
                                    </div>
                                </td>

                                <td>
                                    {{policy?.passwordMinimumLength || '-'}}
                                </td>

                                <td>
                                    <mat-form-field appearance="outline">

                                        <input [readonly]="!passwordRequired" type="number" matInput
                                            formControlName="passwordMinimumLength" />
                                    </mat-form-field>

                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passwordRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passwordMinimumLength')"></sway-baseline-status>

                                </td>

                            </tr>



                            <tr>
                                <td class="field">
                                    <div class="td-title">

                                        Password type
                                        <mat-icon
                                            matTooltip="This setting determines the type of Password/PIN required. Device Default (Password, Numeric PIN, or Alphanumeric PIN required) Alphanumeric (Password or Alphanumberic PIN required) Numeric (Password or Numeric PIN required) Recommendations: Required password type: Alphanumeric, Password complexity: Require digits and lowercase letters">info</mat-icon>
                                    </div>
                                </td>

                                <td>
                                    {{policy?.passwordRequiredType || '-'}}
                                </td>

                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-select (valueChange)="onPasswordRequiredTypeChange()" [disabled]="!passwordRequired"
                                            formControlName="passwordRequiredType">
                                            <mat-option value="deviceDefault">Device default</mat-option>
                                            <mat-option value="alphanumeric">Alphanumeric</mat-option>
                                            <mat-option value="numeric">Numeric</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passwordRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passwordRequiredType')"></sway-baseline-status>

                                </td>

                            </tr>




                            <tr>

                                <td class="field">
                                    <div class="td-title">

                                        Number of non-alphanumeric characters in password

                                        <mat-icon matTooltip="Minimum number of non-alphanumeric characters (like #, %, !, etc.) that the password must contain.">info</mat-icon>

                                    </div>
                                </td>

                                <td>
                                    {{ policy?.passwordMinimumCharacterSetCount }}
                                </td>

                                <td>
                                    <mat-form-field appearance="outline">
                                        <input [readonly]="!passwordRequired" type="number" matInput
                                        formControlName="passwordMinimumCharacterSetCount" />

                                    </mat-form-field>
                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passwordRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passwordMinimumCharacterSetCount')"></sway-baseline-status>

                                </td>

                            </tr>





                            <tr>
                                <td class="field">
                                    <div class="td-title">
                                        maximum minutes of inactivity before password is required
                                        <mat-icon
                                            matTooltip="This setting specifies the length of time without user input after which the mobile device screen is locked. Recommended value: 15 min">info</mat-icon>
                                    </div>
                                </td>

                                <td>
                                    {{policy?.passwordMinutesOfInactivityBeforeLock || 'Not Configured'}}
                                </td>

                                <td>

                                    <mat-form-field appearance="outline">

                                        <mat-select [disabled]="!passwordRequired"
                                            formControlName="passwordMinutesOfInactivityBeforeLock">
                                            <mat-option [value]="0">No configured</mat-option>
                                            <mat-option [value]="1">1 minute</mat-option>
                                            <mat-option [value]="5">5 minutes</mat-option>
                                            <mat-option [value]="15">15 minutes</mat-option>
                                            <mat-option [value]="60">1 hour</mat-option>
                                            <mat-option [value]="240">4 hours</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passwordRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passwordMinutesOfInactivityBeforeLock')"></sway-baseline-status>

                                </td>

                            </tr>



                            <tr>
                                <td class="field">
                                    <div class="td-title">

                                        Password expiration (days) <mat-icon
                                            matTooltip="This setting specifies the number of days after which a device password must be changed. Recommended value: 41 (1-730)">info</mat-icon>
                                    </div>
                                </td>

                                <td>
                                    {{policy?.passwordExpirationDays || '-'}}
                                </td>

                                <td>
                                    <mat-form-field appearance="outline" class="full-width">
                                        <input [readonly]="!passwordRequired" type="number" matInput
                                            formControlName="passwordExpirationDays" />
                                    </mat-form-field>
                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passwordRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passwordExpirationDays')"></sway-baseline-status>

                                </td>

                            </tr>



                            <tr>
                                <td class="field">
                                    <div class="td-title">

                                        Number of previous passwords to prevent reuse <mat-icon
                                            matTooltip="This setting specifies the number of recent passwords that cannot be reused. Recommended value: 5 (1-24)">info</mat-icon>
                                    </div>
                                </td>

                                <td>
                                    {{policy?.passwordPreviousPasswordBlockCount || '-'}}
                                </td>

                                <td>
                                    <mat-form-field appearance="outline" class="full-width">
                                        <input [readonly]="!passwordRequired" type="number" matInput
                                            formControlName="passwordPreviousPasswordBlockCount" />
                                    </mat-form-field>
                                </td>

                                <td class="status">
                                    <sway-baseline-status [ignore]="!passwordRequired"
                                        [hasError]="!policy?.id || errorString.includes('/policy/passwordPreviousPasswordBlockCount')"></sway-baseline-status>
                                </td>

                            </tr>

                        </ng-container>

                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>



    <div class="mb-6"></div>






    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        Encryption
                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status
                            [hasError]="!policy?.id || errorString.includes('/policy/storageRequireEncryption')"
                        ></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Require encryption of data storage on device.

                                    <mat-icon matTooltip="Require encryption of data storage on device.">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.storageRequireEncryption ? 'Required' : 'Not Configured'}}
                            </td>


                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="storageRequireEncryption">
                                    Required
                                </mat-slide-toggle>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/storageRequireEncryption')"></sway-baseline-status>

                            </td>
                        </tr>


                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>



    <div class="mb-6"></div>



    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <div class="items-center w-full  flex justify-between">

                    <mat-panel-title class="font-bold">
                        Device Security
                    </mat-panel-title>

                    <div class="">
                        <sway-baseline-status
                            [hasError]="
                                !policy?.id ||

                                (errorString.includes('/policy/firewallEnabled!')
                                || errorString.includes('/policy/firewallBlockAllIncoming!')
                                || errorString.includes('/policy/firewallEnableStealthMode!')
                                || errorString.includes('/policy/gatekeeperAllowedAppSource!')
                                )
                            "

                        ></sway-baseline-status>
                    </div>

                </div>

            </mat-expansion-panel-header>

            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Field
                            </th>

                            <th>
                                Current Value
                            </th>

                            <th>
                                Desired Value
                            </th>

                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr>
                            <td class="field">
                                Firewall
                            </td>

                            <td>
                                {{policy?.firewallEnabled ? 'Required' : 'Not Configured'}}
                            </td>


                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="firewallEnabled">
                                    Required
                                </mat-slide-toggle>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/firewallEnabled')"></sway-baseline-status>

                            </td>
                        </tr>


                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Incoming connections


                                    <mat-icon matTooltip="Block all incoming connections except those required for basic internet services such as DHCP, Bonjour, and IPSec. This will block all sharing services.">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.firewallBlockAllIncoming ? 'Required' : 'Not Configured'}}
                            </td>


                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="firewallBlockAllIncoming">
                                    Required
                                </mat-slide-toggle>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/firewallBlockAllIncoming')"></sway-baseline-status>

                            </td>
                        </tr>



                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Stealth Mode
                                    <mat-icon
                                        matTooltip="Enabling stealth mode prevents the computer from responding to probing requests. The computer still answers incoming requests for authorized apps.">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.firewallEnableStealthMode	 ? 'Required' : 'Not Configured'}}
                            </td>


                            <td>
                                Not Configured

                                <mat-slide-toggle color="primary" formControlName="firewallEnableStealthMode">
                                    Required
                                </mat-slide-toggle>

                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/firewallEnableStealthMode')"></sway-baseline-status>

                            </td>
                        </tr>

                        <!-- Gatekeeper   -->


                        <tr>
                            <td class="field">
                                <div class="td-title">

                                    Allow apps downloaded from these locations
                                    <mat-icon
                                        matTooltip="Protect devices from malware by limiting apps from launching based on the location where they were downloaded.">info</mat-icon>
                                </div>
                            </td>

                            <td>
                                {{policy?.gatekeeperAllowedAppSource || 'Not Configured'}}
                            </td>

                            <td>
                                <mat-form-field appearance="outline">
                                    <mat-select formControlName="gatekeeperAllowedAppSource">
                                        <mat-option value="notConfigured">Not Configured</mat-option>
                                        <mat-option value="macAppStore">Mac App Store</mat-option>
                                        <mat-option value="macAppStoreAndIdentifiedDevelopers">Mac App Store and identified developers</mat-option>
                                        <mat-option value="anywhere">anywhere</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>

                            <td class="status">
                                <sway-baseline-status
                                    [hasError]="!policy?.id || errorString.includes('/policy/gatekeeperAllowedAppSource')"></sway-baseline-status>

                            </td>
                        </tr>


                    </tbody>
                </table>
            </div>
        </mat-expansion-panel>
    </mat-accordion>




    <div class="mb-6"></div>


    <app-compliance-policy-assignments
        [policy]="policy"
        [errorString]="errorString"
        [assignments]="assignments"
        [form]="form"
        [tenant_id]="tenant_id"
    > </app-compliance-policy-assignments>


    <div class="mb-6"></div>

</form>