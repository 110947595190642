import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Reputation } from 'microsoft-events/dist/interfaces/reputation.interface';
import { Severities, SeverityMap, Summary } from 'microsoft-events/dist/interfaces/summary.interface';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as SummaryActions from './actions';

export const featureKey = 'etlSummary';

export interface State extends EntityState<Summary>, Status {
    // additional entities state properties
    num_days: number;
}

export const adapter: EntityAdapter<Summary> = createEntityAdapter<Summary>({
    selectId: (item) => item.start,
    sortComparer: (a, b) => a.start.localeCompare(b.start),
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
    num_days: 0,
});

// TODO: Remove this after ipqs/dbip has ttl'd from DB
function DbipToReputation(dbip: any): Reputation {
    return {
        is_tor: dbip.proxyType === 'tor',
        is_vpn: dbip.proxyType === 'vpn',
        asn: dbip.asNumber,
        is_bot: dbip.threatDetails && dbip.threatDetails.some((threat) => threat === 'bot'),
        city: dbip.city,
        country_code: dbip.countryCode,
        is_crawler: dbip.isCrawler,
        isp: dbip.isp,
        latitude: dbip.latitude,
        longitude: dbip.longitude,
        organization: dbip.organization,
        is_proxy: dbip.isProxy,
        region: dbip.continentName,
        timezone: dbip.timeZone,
        zip_code: dbip.zipCode,
    };
}

// TODO: Remove this after ipqs/dbip has ttl'd from DB
function IpqsToReputation(ipqs: any): Reputation {
    return {
        is_tor: ipqs.active_tor,
        is_vpn: ipqs.active_vpn,
        asn: ipqs.ASN,
        is_bot: ipqs.bot_status,
        city: ipqs.city,
        country_code: ipqs.country_code,
        is_crawler: ipqs.is_crawler,
        isp: ipqs.ISP,
        latitude: ipqs.latitude,
        longitude: ipqs.longitude,
        organization: ipqs.organization,
        is_proxy: ipqs.proxy,
        region: ipqs.region,
        timezone: ipqs.timezone,
        zip_code: ipqs.zip_code,
    };
}

// TODO: Remove this after ipqs/dbip has ttl'd from DB
const OnUpsert = on(SummaryActions.upsertSummarys, (state: State, action) => {
    const summarys = [];

    let ipqs = 0;
    let dbip = 0;
    let repu = 0;

    for (const summary of action.summarys as any[]) {
        const clone: any = { ...summary, data: { ...summary.data } };

        if (!!clone.data?.ipqs) {
            const reputation = {};

            for (const [key, value] of Object.entries<any>(summary.data.ipqs)) {
                if (value.dbip === true) {
                    reputation[key] = DbipToReputation(value);
                    dbip++;
                } else {
                    reputation[key] = IpqsToReputation(value);
                    ipqs++;
                }
            }

            clone.data.reputation = reputation;
        } else {
            repu += Object.keys(clone.data.reputation).length;
        }

        summarys.push(clone);
    }

    return adapter.upsertMany(summarys, {
        ...state,
        loaded: true,
        num_days: !!action.num_days ? action.num_days : state.num_days,
    });
});

export const reducer = createReducer(initialState, OnUpsert);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export function SelectTotalSeveritiesByDate(state: State) {
    const result: { [key: string]: Severities } = {};
    for (let i = 0; i < state.ids.length; i++) {
        const id = state.ids[i] as string;
        const date = id.split('T')[0];
        const summary = state.entities[id];
        if (result[date]) {
            result[date] = CombineSeverities(result[date], summary.data.total);
        } else {
            result[date] = summary.data.total;
        }
    }
    return result;
}

export function SelectTotalSeveritiesForType(state: State, key: string) {
    const result: { [key: string]: Severities } = {};

    // for each summary
    for (let i = 0; i < state.ids.length; i++) {
        const id = state.ids[i] as string;
        const data = state.entities[id].data[key];
        // for each user, ip, country...
        const keys = Object.keys(data);
        for (let j = 0; j < keys.length; j++) {
            const data_key = keys[j];
            if (result[data_key]) {
                result[data_key] = CombineSeverities(result[data_key], data[data_key]);
            } else {
                result[data_key] = data[data_key];
            }
        }
    }

    return result;
}

export function SelectSeveritiesByTypeDateFiltered(state: State, key: string, filter: string) {
    const result: SeverityMap = {};

    for (let i = 0; i < state.ids.length; i++) {
        const id = state.ids[i] as string;
        const date = id.split('T')[0];
        const data = state.entities[id].data[key] as SeverityMap;

        // create date entry
        if (!result[date]) result[date] = ZeroSeverities();

        // if data for specific filter
        if (data[filter]) {
            // add counts if they exist
            result[date] = CombineSeverities(result[date], data[filter]);
        }
    }

    return result;
}

function CombineSeverities(a: Severities, b: Severities): Severities {
    return {
        critical: a.critical + b.critical,
        danger: a.danger + b.danger,
        info: a.info + b.info,
        warning: a.warning + b.warning,
        total: a.total + b.total,
    };
}

function ZeroSeverities(): Severities {
    return {
        critical: 0,
        danger: 0,
        info: 0,
        warning: 0,
        total: 0,
    };
}

// TODO: use correct Reputation score for event timestamp (this selector is lossy)
export function SelectAllReputation(state: State): { [key: string]: Reputation } {
    const result: { [key: string]: Reputation } = {};
    for (let i = 0; i < state.ids.length; i++) {
        const id = state.ids[i] as string;
        const summary = state.entities[id];
        const entries = Object.entries(summary.data.reputation);
        for (const [key, entity] of entries) {
            if (!result[key]) result[key] = entity;
        }
    }
    return result;
}

export const SelectNumDays = (state: any): number => {
    return state.num_days;
};
