import { Injectable } from '@angular/core';
import { MailFolder } from '@microsoft/microsoft-graph-types-beta';
import { Observable, of, catchError, map, shareReplay } from 'rxjs';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';

@Injectable({
    providedIn: 'root',
})
export class MailboxFolderService {
    private cache: { [key: string]: Observable<string> } = {};

    constructor(private ajax: TenantAjaxService) {}

    public getMailFolderName(tenant_id: string, user_id: string, folder_id: string): Observable<string> {
        const id = tenant_id + user_id + folder_id;
        if (!this.cache[id]) {
            this.cache[id] = this.requestMailFolderNameById(tenant_id, user_id, folder_id).pipe(shareReplay(1));
        }
        return this.cache[id];
    }

    public put(tenant_id: string, user_id: string, folder_id: string, folder: MailFolder | false) {
        const id = tenant_id + user_id + folder_id;
        const name = folder && folder.displayName ? folder.displayName : null;
        if (name) {
            this.cache[id] = of(name).pipe(shareReplay(1));
        }
    }

    private requestMailFolderNameById(tenant_id: string, user_id: string, folder_id: string): Observable<string> {
        return this.ajax.get(tenant_id, `/api/microsoft/graph/users/${user_id}/mailFolders/${folder_id}`).pipe(
            map((res) => {
                if (!res) {
                    return 'Unknown (Folder not found)';
                } else {
                    if (res !== null) {
                        return res.displayName;
                    } else {
                        return 'Unknown (Folder not found)';
                    }
                }
            }),
            catchError((err) => {
                return of('Unknown (Folder not found)');
            }),
        );
    }
}
