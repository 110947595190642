import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OkDialogComponent } from 'src/app/modules/shared/components/ok-dialog/ok-dialog.component';
import { selectSession } from 'src/app/stores/root.store';
import { RoleAccessService } from './roles/role-access.service';

@Injectable({
    providedIn: 'root',
})
export class RolePermissionsGuard implements CanActivate, CanActivateChild {
    constructor(
        private store: Store<any>,
        private roleAccess: RoleAccessService,
        private dialog: MatDialog,
    ) {}

    private isAuthenticatedOrRedirect(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store.pipe(select(selectSession)).pipe(
            filter((state) => state.loaded),
            map((session) => {
                const feature = next.data['feature'];
                const action = next.data['action'];
                const scope = next.data['scope'];

                const userHasRolePermissions = this.roleAccess.userHaPermission(
                    feature,
                    action,
                    session.session,
                    scope,
                );

                if (!userHasRolePermissions) {
                    this.restrictAccess();
                    // this.router.navigate(['/msp/dashboard']);
                    return false;
                } else {
                    return true;
                }
            }),
        );
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.isAuthenticatedOrRedirect(next, state);
    }

    canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.isAuthenticatedOrRedirect(next, state);
    }

    private restrictAccess(): void {
        this.dialog.open(OkDialogComponent, {
            data: {
                title: 'Insufficient Role',
                description:
                    'You do not have the required role to perform this action. If you believe you should have access, please reach out to your administrator for assistance.',
                confirm_button_text: 'Close',
            },
            width: '750px',
        });
    }
}
