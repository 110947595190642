import { createAction, props } from '@ngrx/store';
import { ConditionalAccessPolicy } from '@microsoft/microsoft-graph-types-beta';

export const loadConditionalAccessPolicy = createAction(
    '[Graph/Conditional Access] Load Conditional Access Policy ',
    props<{ _tenant: string }>(),
);

export const loadConditionalAccessPolicySuccess = createAction(
    '[Graph/Domain] Load Conditional Access Policy Success',
    props<{ _tenant: string; polices: ConditionalAccessPolicy[] }>(),
);

export const loadConditionalAccessPolicyFailure = createAction(
    '[Graph/Domain] Load Conditional Access Policy Failure',
    props<{ _tenant: string; error: any }>(),
);

export const createConditionalAccessPolicy = createAction(
    '[Graph/Conditional Access] Create Conditional Access Policy ',
    props<{ _tenant: string; policy: ConditionalAccessPolicy }>(),
);

export const createConditionalAccessPolicySuccess = createAction(
    '[Graph/Domain] Create Conditional Access Policy Success',
    props<{ _tenant: string; policy: ConditionalAccessPolicy }>(),
);

export const createConditionalAccessPolicyFailure = createAction(
    '[Graph/Domain] Create Conditional Access Policy Failure',
    props<{ _tenant: string; error: any }>(),
);

export const updateConditionalAccessPolicy = createAction(
    '[Graph/Conditional Access] Update Conditional Access Policy ',
    props<{ _tenant: string; policy: ConditionalAccessPolicy }>(),
);

export const updateConditionalAccessPolicySuccess = createAction(
    '[Graph/Domain] Update Conditional Access Policy Success',
    props<{ _tenant: string; policy: ConditionalAccessPolicy }>(),
);

export const updateConditionalAccessPolicyFailure = createAction(
    '[Graph/Domain] Update Conditional Access Policy Failure',
    props<{ _tenant: string; error: any }>(),
);

export const deleteConditionalAccessPolicy = createAction(
    '[Graph/Conditional Access] Delete Conditional Access Policy ',
    props<{ _tenant: string; id: string }>(),
);

export const deleteConditionalAccessPolicySuccess = createAction(
    '[Graph/Domain] Delete Conditional Access Polciy Success',
    props<{ _tenant: string; id: string }>(),
);

export const deleteConditionalAccessPolicyFailure = createAction(
    '[Graph/Domain] Delete Conditional Access Policy Failure',
    props<{ _tenant: string; error: any }>(),
);
