import { ActionReducer } from '@ngrx/store';

export const SESSION_EXPIRED = '[Root/Session] Session Expired';
export const LOG_OUT = '[Root/Session] Delete Session';
export const SWITCH_CLIENT_TENANT = '[Msp/API] Switch Client Tenant Success'; // Review: [Root/Session] Change Client?

export function resetState(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => {
        if ((action != null && action.type === LOG_OUT) || action.type === SESSION_EXPIRED) {
            console.log('reset root state');
            state = undefined;
            const nextState = reducer(state, action);
            return nextState;
        } else if (action != null && action.type === SWITCH_CLIENT_TENANT) {
            console.log('reset client state from RootStore', state);

            const cleaned = { ...state };
            // delete cleaned['client'];
            delete cleaned['billing'];
            delete cleaned['dashboards'];
            const newState = { ...cleaned };

            return reducer(newState, action);
        }

        try {
            return reducer(state, action);
        } catch (error: unknown) {
            console.log(error);
            console.log(action);
        }
    };
}
