import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { ExoMailboxDelegate, UpdateDelegateProps } from './model';

export const addExoMailboxDelegate = createAction(
    '[Pwsh/Exo] Add ExoMailboxDelegate',
    props<{ _tenant: string; exoMailboxDelegate: ExoMailboxDelegate }>(),
);

export const upsertExoMailboxDelegate = createAction(
    '[Pwsh/Exo] Upsert ExoMailboxDelegate',
    props<{ _tenant: string; exoMailboxDelegate: ExoMailboxDelegate }>(),
);

export const addExoMailboxDelegates = createAction(
    '[Pwsh/Exo] Add ExoMailboxDelegates',
    props<{ _tenant: string; exoMailboxDelegates: ExoMailboxDelegate[] }>(),
);

export const upsertExoMailboxDelegates = createAction(
    '[Pwsh/Exo] Upsert ExoMailboxDelegates',
    props<{ _tenant: string; exoMailboxDelegates: ExoMailboxDelegate[] }>(),
);

export const updateExoMailboxDelegate = createAction(
    '[Pwsh/Exo] Update ExoMailboxDelegate',
    props<{ _tenant: string; exoMailboxDelegate: Update<ExoMailboxDelegate> }>(),
);

export const updateExoMailboxDelegates = createAction(
    '[Pwsh/Exo] Update ExoMailboxDelegates',
    props<{ _tenant: string; exoMailboxDelegates: Update<ExoMailboxDelegate>[] }>(),
);

export const deleteExoMailboxDelegate = createAction(
    '[Pwsh/Exo] Delete ExoMailboxDelegate',
    props<{ _tenant: string; id: string }>(),
);

export const deleteExoMailboxDelegates = createAction(
    '[Pwsh/Exo] Delete ExoMailboxDelegates',
    props<{ _tenant: string; ids: string[] }>(),
);

export const clearExoMailboxDelegates = createAction(
    '[Pwsh/Exo] Clear ExoMailboxDelegates',
    props<{ _tenant: string }>(),
);

// Customize : Mircosoft calls
export const getMailboxDelegates = createAction(
    '[Pwsh/Exo] Get Mailbox Delegates',
    props<{ _tenant: string; Identity: string }>(),
);

export const getMailboxDelegatesSuccess = createAction(
    '[Pwsh/Exo] Get Mailbox Delegates Success',
    props<{ _tenant: string; exoMailboxDelegates: ExoMailboxDelegate }>(),
);
export const getMailboxDelegatesFailure = createAction(
    '[Pwsh/Exo] Get Mailbox Delegates Failure',
    props<{ _tenant: string; error: any }>(),
);

export const updateMailboxDelegates = createAction('[Pwsh/Exo] Update Mailbox Delegates', props<UpdateDelegateProps>());

export const updateMailboxDelegatesSuccess = createAction(
    '[Pwsh/Exo] Update Mailbox Delegates Success',
    props<UpdateDelegateProps>(),
);

export const updateMailboxDelegatesFailure = createAction(
    '[Pwsh/Exo] Update Mailbox Delegates Failure',
    props<{ _tenant: string; error: any }>(),
);
