import { createAction, props } from '@ngrx/store';
import { TargetGroup } from './model';

export const loadTargetGroup = createAction('[EventRuleTargetGroup] Load');

export const loadTargetGroupSuccess = createAction(
    '[EventRuleTargetGroup] Load Success',
    props<{ groups: TargetGroup[] }>(),
);

export const loadTargetGroupFailure = createAction('[EventRuleTargetGroup] Load Failure', props<{ error: any }>());

export const createTargetGroup = createAction('[EventRuleTargetGroup] Create', props<{ group: TargetGroup }>());

export const createTargetGroupSuccess = createAction(
    '[EventRuleTargetGroup] Create Success',
    props<{ group: TargetGroup }>(),
);

export const createTargetGroupFailure = createAction('[EventRuleTargetGroup] Create Failure', props<{ error: any }>());

export const deleteTargetGroup = createAction('[EventRuleTargetGroup] Delete', props<{ id: string }>());

export const deleteTargetGroupSuccess = createAction('[EventRuleTargetGroup] Delete Success', props<{ id: string }>());

export const deleteTargetGroupFailure = createAction(
    '[EventRuleTargetGroup] Delete Failure',
    props<{ id: string; error: any }>(),
);
