import { Component, OnInit, Input } from '@angular/core';
@Component({
    selector: 'app-header-button',
    templateUrl: './header-button.component.html',
    styleUrls: ['./header-button.component.scss'],
})
export class HeaderButtonComponent implements OnInit {
    @Input() icon: string;
    @Input() text: string;
    @Input() link: string;
    @Input() active: boolean = false;

    constructor() {}

    ngOnInit() {}
}
