import { ConditionalAccessPolicySchema, createCapPolicySchema } from '../shared/cap-schema';
export { BaselineComponent } from './baseline.component';
export { Remediate } from './remediate';
export const href =
    'https://learn.microsoft.com/en-us/azure/active-directory/conditional-access/howto-conditional-access-policy-compliant-device-admin';

const clientAppTypes = {
    type: 'array',
    items: {
        enum: ['all', 'All'],
    },
    minItems: 1,
    maxItems: 1,
    uniqueItems: true,
};

const applications = {
    type: 'object',
    properties: {
        includeApplications: {
            type: 'array',
            items: {
                enum: ['all', 'All'],
                type: 'string',
            },
            minItems: 1,
            maxItems: 1,
            uniqueItems: true,
        },
    },
    required: ['includeApplications'],
};

const grantControls = {
    type: 'object',
    properties: {
        operator: {
            type: 'string',
            const: 'OR',
        },
        builtInControls: {
            type: 'array',
            items: {
                type: 'string',
                enum: ['domainJoinedDevice', 'compliantDevice'],
            },
            maxItems: 2,
        },
    },
};

const policy: ConditionalAccessPolicySchema = {
    grantControls,
    conditions: {
        applications,
        clientAppTypes,
    },
};

export const CAPRequireCompliantOrHybridAzureADJoinedDeviceSchema = (form) => {
    return createCapPolicySchema(form, policy);
};
