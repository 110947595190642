import { createAction, props } from '@ngrx/store';
import { SwayTenant } from './model';

export const loadSwayTenant = createAction('[Sway/Tenant] Load Tenant', props<{ _tenant: string }>());

export const loadSwayTenantSuccess = createAction(
    '[Sway/Tenant] Load Tenant Success',
    props<{ _tenant: string; data: SwayTenant }>(),
);

export const loadSwayTenantFailure = createAction(
    '[Sway/Tenant] Load Tenant Failure',
    props<{ _tenant: string; error: any }>(),
);

export const updateSwayTenant = createAction(
    '[Sway/Tenant] Update Tenant',
    props<{ _tenant: string; data: Partial<SwayTenant>; update_auto_remediation?: boolean }>(),
);

export const updateSwayTenantSuccess = createAction(
    '[Sway/Tenant] Update Tenant Success',
    props<{ _tenant: string; tenant: SwayTenant }>(),
);

export const updateSwayTenantFailure = createAction(
    '[Sway/Tenant] Update Tenant Failure',
    props<{ _tenant: string; error: any }>(),
);
