import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import { Subscription } from './model';
import * as actions from './actions';

export const featureKey = 'subscriptions';

export interface State extends Status {
    data: Subscription[] | string;
}

export const initialState: State = {
    data: [],
    ...initialStatus,
};

export const reducer = createReducer(
    initialState,
    on(actions.loadMgmtSubscriptionsSuccess, (state, { data }) => ({ ...state, data, loaded: true, error: false })),
    on(actions.loadMgmtSubscriptionsFailure, (state, { error }) => ({ ...state, error, loaded: true })),
);
