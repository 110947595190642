import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaselineFormComponent } from '../../abstract/baseline-form.component';

@Component({
    selector: 'sway-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
})
export class FormComponent extends BaselineFormComponent implements OnInit {
    public control = new FormControl();

    ngOnInit(): void {
        const schema = JSON.parse(JSON.stringify(this.baseline.schema));

        if (this.readonly) {
            this.control.disable();
        }

        const value = !schema.properties[this.field] ? 'any' : !!schema.properties[this.field]?.enum ? true : false;

        this.control.setValue(value);

        this.control.valueChanges.subscribe((value) => {
            if (value === 'any') {
                delete schema.properties[this.field];
                schema.required = [];
            } else if (value === true) {
                schema.properties[this.field] = {};
                schema.properties[this.field]['enum'] = ['enabled', 'enforced'];
                schema.required = [this.field];
            } else {
                schema.properties[this.field] = {};
                schema.properties[this.field]['const'] = 'disabled'; // disabled
                schema.required = [this.field];
            }

            this.baselineChange.next({
                baseline: {
                    ...this.baseline,
                    schema: JSON.parse(JSON.stringify(schema)),
                },
            });
        });
    }
}
