import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, distinct, map, mergeMap, take } from 'rxjs/operators';
import { SharingPolicy } from 'src/app/interfaces/powershell/exo/sharing-policy.interface';
import { ChangesService } from 'src/app/services/changes.service';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { client } from '../../..';
import { storeChangesToDB } from '../../../octiga/changes/actions';
import * as SharingActions from './actions';

@Injectable()
export class SharingPolicyEffects {
    loadSharingPolicies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharingActions.loadSharingPolicies),
            distinct((action) => action._tenant),
            mergeMap(({ _tenant }) =>
                this.getSharingPolicies(_tenant).pipe(
                    map((data: SharingPolicy[]) => SharingActions.loadSharingPoliciesSuccess({ _tenant, data })),
                    catchError((error) => of(SharingActions.loadSharingPoliciesFailure({ _tenant, error }))),
                ),
            ),
        ),
    );

    updateSharingPolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SharingActions.updateSharingPolicy),
            mergeMap(({ _tenant, Identity, Domains }) =>
                this.updateSharingPolicy(_tenant, { Identity, Domains }).pipe(
                    concatMap(() => {
                        this.store
                            .pipe(select(client(_tenant).powershell.exoSharingPolicy.all), take(1))
                            .subscribe((res) => {
                                const old = {
                                    Identity: res[0].Identity,
                                    Domains: res[0].Domains,
                                };
                                const params = {
                                    user: 'company',
                                    fields: {
                                        operation: 'update',
                                        new: {
                                            Identity,
                                            Domains,
                                        },
                                        old,
                                    },
                                };
                                const item = this.changesService.formatChangesObjectToDB(params, 'sharing-policy');
                                this.store.dispatch(storeChangesToDB({ _tenant, item }));
                            });
                        return [SharingActions.updateSharingPolicySuccess({ _tenant, Identity, Domains })];
                    }),
                    catchError((error) => of(SharingActions.updateSharingPolicyFailure(error))),
                ),
            ),
        ),
    );

    private getSharingPolicies(tenant: string): Observable<Array<SharingPolicy>> {
        return this.ajax.get(tenant, '/api/microsoft/powershell/exo/sharing-policy');
    }

    updateSharingPolicy(tenant: string, options) {
        const url = '/api/microsoft/powershell/exo/sharing-policy';
        return this.ajax.put(url, options);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private store: Store<any>,
        private changesService: ChangesService,
    ) {}
}
