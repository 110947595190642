import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as ConditionalAccessPolicyActions from './actions';
import { ConditionalAccessPolicy } from '@microsoft/microsoft-graph-types-beta';

export const featureKey = 'accessPolicy';

export interface State extends EntityState<ConditionalAccessPolicy>, Status {}

export const adapter: EntityAdapter<ConditionalAccessPolicy> = createEntityAdapter<ConditionalAccessPolicy>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    // load

    on(ConditionalAccessPolicyActions.loadConditionalAccessPolicySuccess, (state, action) =>
        adapter.upsertMany(action.polices, { ...state, loaded: true, error: null }),
    ),
    on(ConditionalAccessPolicyActions.loadConditionalAccessPolicyFailure, (state, { error }) => ({
        ...state,
        error,
        loaded: false,
    })),

    // create
    on(ConditionalAccessPolicyActions.createConditionalAccessPolicy, (state, action) => ({
        ...state,
        updating: true,
        error: null,
    })),
    on(ConditionalAccessPolicyActions.createConditionalAccessPolicySuccess, (state, action) =>
        adapter.upsertOne(action.policy, { ...state, updating: false, error: null }),
    ),
    on(ConditionalAccessPolicyActions.createConditionalAccessPolicyFailure, (state, { error }) => ({
        ...state,
        error,
        updating: false,
    })),

    //update
    on(ConditionalAccessPolicyActions.updateConditionalAccessPolicy, (state, action) => ({
        ...state,
        updating: true,
        error: null,
    })),

    on(
        ConditionalAccessPolicyActions.updateConditionalAccessPolicySuccess, // TODO need chage here
        (state, action) => adapter.upsertOne(action.policy, { ...state, updating: false, error: null }),
    ),

    on(ConditionalAccessPolicyActions.updateConditionalAccessPolicyFailure, (state, { error }) => ({
        ...state,
        error,
        updating: false,
    })),

    // delete
    on(ConditionalAccessPolicyActions.deleteConditionalAccessPolicy, (state, action) => ({
        ...state,
        updating: true,
        error: null,
    })),

    on(
        ConditionalAccessPolicyActions.deleteConditionalAccessPolicySuccess, // TODO need chage here
        (state, action) => adapter.removeOne(action.id, { ...state, updating: false, error: null }),
    ),

    on(ConditionalAccessPolicyActions.deleteConditionalAccessPolicyFailure, (state, { error }) => ({
        ...state,
        error,
    })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
