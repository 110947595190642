import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, last, map, mergeMap, tap } from 'rxjs/operators';
import { LoaderState } from 'src/app/modules/shared/libs/loader/store/loader.reducer';
import { EffectsBase } from 'src/app/modules/shared/libs/state/effects-base';
import { retry } from 'src/app/pipes/retry.pipe';
import { MspAjaxService } from 'src/app/services/ajax/msp-ajax.service';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { InitClientService } from 'src/app/services/init-client.service';
import * as actions from './actions';
import { Msp } from './model';

@Injectable()
export class MspEffects extends EffectsBase {
    loadMsp$ = this.createEffect(
        actions.loadMsp,
        mergeMap(({ msp_id, userId }) =>
            this.mspAjax.get<Msp>(`/api/octiga/v2/msp?userId=${userId}`).pipe(
                retry(3000, 3, 'octiga timeout'),
                last(),
                tap((data: Msp) => data.tenants.forEach((t) => this.initService.addClientReducer(t.id))),
                map((data) => actions.loadMspSuccess({ data })),
                catchError((error) => of(actions.loadMspFailure({ error }))),
            ),
        ),
    );

    updateMsp$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateMsp),
            mergeMap((action) =>
                this.mspAjax.patch('/api/octiga/v2/msp', action.data).pipe(
                    map((data) => {
                        action.onSuccessFn();
                        return actions.updateMspSuccess({ data });
                    }),
                    catchError((error) => of(actions.updateMspFailure({ error }))),
                ),
            ),
        ),
    );

    updateMspTenants$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateMspTenants),
            mergeMap((action) =>
                this.mspAjax.put('/api/octiga/v2/msp/tenants', action.tenants).pipe(
                    concatMap(() => {
                        return [
                            actions.updateMspTenantsSuccess({ tenants: action.tenants }),
                            actions.updateMsp({
                                data: { tenants: action.tenants },
                            }),
                        ];
                    }),
                    catchError((error) => of(actions.updateMspTenantsFailure({ error }))),
                ),
            ),
        ),
    );

    deleteMspTenant$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteMspTenant),
            mergeMap(({ tenant, actor }) =>
                this.ajax.post(tenant, `/api/fabric/delete/tenant/${tenant}`, { tenant, actor }).pipe(
                    map((_data) => actions.deleteMspTenantSuccess({ tenant })),
                    catchError((error) => of(actions.deleteMspTenantFailure({ error }))),
                ),
            ),
        ),
    );

    sendMspLockoutEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.sendMspLockoutEmail),
            mergeMap((action) =>
                this.mspAjax
                    .post('/api/octiga/v2/msp/lockout', {
                        name: action.name,
                        upn: action.upn,
                        country_code: action.country_code,
                    })
                    .pipe(
                        map((res) =>
                            actions.sendMspLockoutEmailSuccess({
                                name: action.name,
                                upn: action.upn,
                                country_code: action.country_code,
                            }),
                        ),
                        catchError((error) => of(actions.sendMspLockoutEmailFailure({ error }))),
                    ),
            ),
        ),
    );

    sendMspUpgradeRequestEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.sendMspUpgradeRequestEmail),
            mergeMap((action) =>
                this.mspAjax
                    .post('/api/octiga/v2/msp/upgrade-plan', {
                        name: action.name,
                        upn: action.upn,
                        country_code: action.country_code,
                    })
                    .pipe(
                        map((res) =>
                            actions.sendMspUpgradeRequestEmailSuccess({
                                name: action.name,
                                upn: action.upn,
                                country_code: action.country_code,
                            }),
                        ),
                        catchError((error) => of(actions.sendMspUpgradeRequestEmailFailure({ error }))),
                    ),
            ),
        ),
    );

    updateMspCwmConnection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateMspCwmConnection),
            mergeMap((action) =>
                this.mspAjax.put('/api/octiga/v2/msp/cwm', action.data).pipe(
                    concatMap((data: { valid: boolean }) =>
                        data.valid
                            ? of(actions.updateMspCwmConnectionSuccess())
                            : of(
                                actions.updateMspCwmConnectionFailure({
                                    error: 'Invalid Cwm Connection Inputs, Please Enter Valid Connection Data.',
                                }),
                            ),
                    ),
                    catchError((error) => of(actions.updateMspCwmConnectionFailure({ error }))),
                ),
            ),
        ),
    );

    updateMspDattoConnection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateMspDattoConnection),
            mergeMap((action) =>
                this.mspAjax.put('/api/octiga/v2/msp/datto', action.data).pipe(
                    concatMap((data: { valid: boolean }) =>
                        data.valid
                            ? of(actions.updateMspDattoConnectionSuccess())
                            : of(
                                actions.updateMspDattoConnectionFailure({
                                    error: 'Invalid Datto Connection Inputs, Please Enter Valid Connection Data.',
                                }),
                            ),
                    ), // catch error here
                    catchError((error) => of(actions.updateMspDattoConnectionFailure({ error }))),
                ),
            ),
        ),
    );

    updateMspCwmConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateMspCwmConfig),
            mergeMap((action) =>
                this.mspAjax.put('/api/octiga/v2/msp/cwm/config', action.data).pipe(
                    concatMap(() => of(actions.updateMspCwmConfigSuccess({ data: action.data }))),
                    catchError((error) => of(actions.updateMspCwmConfigFailure({ error }))),
                ),
            ),
        ),
    );

    updateMspDattoConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateMspDattoConfig),
            mergeMap((action) =>
                this.mspAjax.put('/api/octiga/v2/msp/datto/config', action.data).pipe(
                    concatMap(() => of(actions.updateMspDattoConfigSuccess({ data: action.data }))),
                    catchError((error) => of(actions.updateMspDattoConfigFailure({ error }))),
                ),
            ),
        ),
    );

    updateMspHaloConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateMspHaloConfig),
            mergeMap((action) =>
                this.mspAjax.put('/api/octiga/v2/msp/halo/config', action.data).pipe(
                    concatMap(() => of(actions.updateMspHaloConfigSuccess({ data: action.data }))),
                    catchError((error) => of(actions.updateMspDattoConfigFailure({ error }))),
                ),
            ),
        ),
    );

    updateMspHaloConnection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateMspHaloConnection),
            mergeMap((action) =>
                this.mspAjax.put('/api/octiga/v2/msp/halo', action.data).pipe(
                    concatMap((data: { valid: { result: boolean; err?: any } }) => {
                        return data.valid.result
                            ? of(actions.updateMspHaloConnectionSuccess())
                            : of(actions.updateMspHaloConnectionFailure({ error: data.valid.err })); // catch error here
                    }),
                    catchError((error) => of(actions.updateMspHaloConnectionFailure({ error }))),
                ),
            ),
        ),
    );

    deleteMspHaloConnection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteMspHaloConnection),
            mergeMap((action) =>
                this.mspAjax.delete('/api/octiga/v2/msp/halo').pipe(
                    map((deleted) => actions.deleteMspHaloConnectionSuccess({ deleted })),
                    catchError((error) => of(actions.deleteMspHaloConnectionFailure({ error }))),
                ),
            ),
        ),
    );

    deleteMspDattoConnection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteMspDattoConnection),
            mergeMap((action) =>
                this.mspAjax.delete('/api/octiga/v2/msp/datto').pipe(
                    map((deleted) => actions.deleteMspDattoConnectionSuccess({ deleted })),
                    catchError((error) => of(actions.deleteMspDattoConnectionFailure({ error }))),
                ),
            ),
        ),
    );

    deleteMspCwmConnection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteMspCwmConnection),
            mergeMap((action) =>
                this.mspAjax.delete('/api/octiga/v2/msp/cwm').pipe(
                    map((deleted) => actions.deleteMspCwmConnectionSuccess({ deleted })),
                    catchError((error) => of(actions.deleteMspCwmConnectionFailure({ error }))),
                ),
            ),
        ),
    );

    constructor(
        private mspAjax: MspAjaxService,
        private ajax: TenantAjaxService,
        private initService: InitClientService,
        protected actions$: Actions,
        protected loaderStore: Store<LoaderState>,
        protected snackbar: MatSnackBar,
    ) {
        super(actions$, loaderStore, snackbar);
    }
}
