import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { combineLatest, first, map, Observable, switchMap, take, startWith } from 'rxjs';
import { client } from 'src/app/stores/client';
import { loadGraphUsers } from 'src/app/stores/client/graph/user/user.actions';
import { HostedOutboundSpamFilterPolicy } from 'src/app/stores/client/powershell/exo/hosted-outbound-spam-filter-policy/model';
import { selectSession } from 'src/app/stores/root.store';
import { BaseComponent } from '../../base/base.component';
import { IdentityName } from './remediate';

function validate(data: string[] = [], schema: string[] = []): boolean {
    const sortedData = JSON.parse(JSON.stringify(data)).sort();
    const sortedSchema = JSON.parse(JSON.stringify(schema)).sort();
    return JSON.stringify(sortedData) === JSON.stringify(sortedSchema);
}

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../styles/baseline-style.scss'],
})
export class BaselineComponent extends BaseComponent implements OnInit, OnChanges {
    emailsLookups;
    defaultPolicy: HostedOutboundSpamFilterPolicy;

    @Input() data: HostedOutboundSpamFilterPolicy[];

    isOutboundAdditionalRecipientsHasError: boolean;
    isNotifyOutboundSpamRecipientsHasError: boolean;
    BccSuspiciousOutboundAdditionalRecipients: { email: string; isExist: boolean }[];
    NotifyOutboundSpamRecipients: { email: string; isExist: boolean }[];
    isAllNotifyRecipientsFound: boolean;
    isAllBccSuspiciousOutboundAdditionalRecipientsFound: boolean;

    constructor(
        private store: Store<any>,
        private fb: FormBuilder,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.defaultPolicy = this.data.find((res) => res.Identity === IdentityName);
        this.checkRecipientListStatus(JSON.parse(JSON.stringify(this.baseline.schema)));
    }

    tenant_id$: Observable<string> = this.store.pipe(
        select(selectSession),
        map((sess) => sess.session.clientId),
    );

    ngOnInit(): void {
        this.tenant_id$.subscribe((_tenant) => {
            this.store.dispatch(loadGraphUsers({ _tenant }));
        });

        const schema = JSON.parse(JSON.stringify(this.baseline.schema));
        this.defaultPolicy = this.data.find((res) => res.Identity === IdentityName);

        this.getMailboxLookup().subscribe((res) => {
            this.emailsLookups = res;
        });

        this.form = this.initForm(schema);

        // for trigger ValueChanged

        this.checkRecipientListStatus(schema);

        this.observeFormValueChanges(schema);
    }

    checkRecipientListStatus(schema) {
        if (!this.defaultPolicy) {
            return;
        }

        const BccSuspiciousOutboundAdditionalRecipientsSchema: Array<string> =
            schema.contains.properties.BccSuspiciousOutboundAdditionalRecipients.items.enum || [];
        const NotifyOutboundSpamRecipientsSchema: Array<string> =
            schema.contains.properties.NotifyOutboundSpamRecipients.items.enum || [];

        this.BccSuspiciousOutboundAdditionalRecipients =
            this.defaultPolicy.BccSuspiciousOutboundAdditionalRecipients.map((res) => ({
                email: res,
                isExist: BccSuspiciousOutboundAdditionalRecipientsSchema.includes(res),
            }));
        this.isAllBccSuspiciousOutboundAdditionalRecipientsFound = this.BccSuspiciousOutboundAdditionalRecipients.some(
            (res) => !res.isExist,
        );

        this.NotifyOutboundSpamRecipients = this.defaultPolicy.NotifyOutboundSpamRecipients.map((res) => ({
            email: res,
            isExist: NotifyOutboundSpamRecipientsSchema.includes(res),
        }));

        this.isAllNotifyRecipientsFound = this.NotifyOutboundSpamRecipients.some((res) => !res.isExist);

        this.isOutboundAdditionalRecipientsHasError = !validate(
            this.defaultPolicy.BccSuspiciousOutboundAdditionalRecipients,
            BccSuspiciousOutboundAdditionalRecipientsSchema,
        );
        this.isNotifyOutboundSpamRecipientsHasError = !validate(
            this.defaultPolicy.NotifyOutboundSpamRecipients,
            NotifyOutboundSpamRecipientsSchema,
        );
    }

    addBccSuspiciousOutboundRecipient(email: string) {
        const oldValues = this.form.get('BccSuspiciousOutboundAdditionalRecipients').value || [];
        const newValues = [...oldValues, email];
        this.form.get('BccSuspiciousOutboundAdditionalRecipients').setValue([...new Set(newValues)]);
    }

    addNotifyOutboundSapmRecipient(email: string) {
        const oldValue = this.form.get('NotifyOutboundSpamRecipients').value || [];
        const newValues = [...oldValue, email];
        this.form.get('NotifyOutboundSpamRecipients').setValue([...new Set(newValues)]);
    }

    observeFormValueChanges(schema): void {
        this.form.valueChanges.pipe(startWith(this.form.value)).subscribe((res) => {
            schema.contains.properties.BccSuspiciousOutboundMail.const = res.BccSuspiciousOutboundMail;

            if (res.BccSuspiciousOutboundAdditionalRecipients && res.BccSuspiciousOutboundAdditionalRecipients.length) {
                schema.contains.properties.BccSuspiciousOutboundAdditionalRecipients.items.enum = JSON.parse(
                    JSON.stringify(res.BccSuspiciousOutboundAdditionalRecipients),
                );
                schema.contains.properties.BccSuspiciousOutboundAdditionalRecipients.minItems =
                    res.BccSuspiciousOutboundAdditionalRecipients.length;
                schema.contains.properties.BccSuspiciousOutboundAdditionalRecipients.maxItems =
                    res.BccSuspiciousOutboundAdditionalRecipients.length;
            } else {
                delete schema.contains.properties.BccSuspiciousOutboundAdditionalRecipients.items.enum;
                delete schema.contains.properties.BccSuspiciousOutboundAdditionalRecipients.minItems;
                schema.contains.properties.BccSuspiciousOutboundAdditionalRecipients.maxItems = 0;
            }

            if (res.NotifyOutboundSpam === 'any') {
                delete schema.contains.properties.NotifyOutboundSpam;
            } else {
                schema.contains.properties.NotifyOutboundSpam = {};
                schema.contains.properties.NotifyOutboundSpam.type = 'boolean';
                schema.contains.properties.NotifyOutboundSpam.const = res.NotifyOutboundSpam;
            }

            if (res.NotifyOutboundSpamRecipients && res.NotifyOutboundSpamRecipients.length) {
                schema.contains.properties.NotifyOutboundSpamRecipients.items.enum = JSON.parse(
                    JSON.stringify(res.NotifyOutboundSpamRecipients),
                );
                schema.contains.properties.NotifyOutboundSpamRecipients.minItems =
                    res.NotifyOutboundSpamRecipients.length;
                schema.contains.properties.NotifyOutboundSpamRecipients.maxItems =
                    res.NotifyOutboundSpamRecipients.length;
            } else {
                delete schema.contains.properties.NotifyOutboundSpamRecipients.items.enum;
                delete schema.contains.properties.NotifyOutboundSpamRecipients.minItems;
                schema.contains.properties.NotifyOutboundSpamRecipients.maxItems = 0;
            }

            schema.contains.properties.RecipientLimitExternalPerHour.const = res.RecipientLimitExternalPerHour;
            schema.contains.properties.RecipientLimitInternalPerHour.const = res.RecipientLimitInternalPerHour;
            schema.contains.properties.RecipientLimitPerDay.const = res.RecipientLimitPerDay;
            schema.contains.properties.ActionWhenThresholdReached.const = res.ActionWhenThresholdReached;

            this.checkRecipientListStatus(schema);

            let RecipientLimitPerDayError = false;

            if (
                +this.formValue.RecipientLimitExternalPerHour > +this.formValue.RecipientLimitPerDay ||
                +this.formValue.RecipientLimitInternalPerHour > +this.formValue.RecipientLimitPerDay
            ) {
                RecipientLimitPerDayError = true;
            }

            if (
                (res.NotifyOutboundSpamRecipients?.length === 0 && res.NotifyOutboundSpam == true) ||
                (res.BccSuspiciousOutboundAdditionalRecipients?.length === 0 && res.BccSuspiciousOutboundMail == true)
            ) {
                this.baselineErrorChange.next({ remediate: true, save: false });
                RecipientLimitPerDayError = true;
            } else {
                RecipientLimitPerDayError = false;
            }

            const BccSuspiciousOutboundAdditionalRecipientsError =
                res.BccSuspiciousOutboundMail && !(res.BccSuspiciousOutboundAdditionalRecipients?.length > 0);
            const NotifyOutboundSpamRecipientsError =
                res.NotifyOutboundSpam === true && !(res.NotifyOutboundSpamRecipients.length > 0);

            const hasError =
                RecipientLimitPerDayError ||
                BccSuspiciousOutboundAdditionalRecipientsError ||
                NotifyOutboundSpamRecipientsError;
            this.baselineErrorChange.next({ remediate: hasError, save: hasError });

            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema)),
            });
        });
    }

    initForm(schema): FormGroup {
        return this.fb.group({
            Identity: [schema.contains.properties.Identity.const],
            BccSuspiciousOutboundMail: [schema.contains.properties.BccSuspiciousOutboundMail.const],
            BccSuspiciousOutboundAdditionalRecipients: [
                JSON.parse(
                    JSON.stringify(
                        schema.contains.properties.BccSuspiciousOutboundAdditionalRecipients.items.enum || [],
                    ),
                ),
            ],
            NotifyOutboundSpam: [schema.contains.properties.NotifyOutboundSpam?.const || 'any'],
            NotifyOutboundSpamRecipients: [
                JSON.parse(JSON.stringify(schema.contains.properties.NotifyOutboundSpamRecipients.items.enum || [])),
            ],
            RecipientLimitExternalPerHour: [schema.contains.properties.RecipientLimitExternalPerHour.const],
            RecipientLimitInternalPerHour: [schema.contains.properties.RecipientLimitInternalPerHour.const],
            RecipientLimitPerDay: [schema.contains.properties.RecipientLimitPerDay.const],
            ActionWhenThresholdReached: [schema.contains.properties.ActionWhenThresholdReached.const],
        });
    }

    getMailboxLookup() {
        return this.tenant_id$.pipe(
            switchMap((tid) =>
                combineLatest([
                    this.store.select(client(tid).graph.users.withMailbox),
                    this.store.select(client(tid).graph.users.status),
                ]),
            ),
            first(([data, status]) => status.loaded),
            map(([data]) => data),

            map((res) => res.map((re) => ({ id: re['id'], name: re['mail'] }))),
            take(1),
        );
    }

    updateBccSuspiciousOutboundAdditionalRecipients(event: Array<string>) {
        this.form.get('BccSuspiciousOutboundAdditionalRecipients').patchValue(event);
    }

    updateNotifyOutboundSpamRecipients(event: Array<string>) {
        this.form.get('NotifyOutboundSpamRecipients').patchValue(event);
    }

    addNotFountOutboundRecipients() {
        const oldValues = this.form.get('BccSuspiciousOutboundAdditionalRecipients').value || [];
        const newValues = [...oldValues, ...this.defaultPolicy.BccSuspiciousOutboundAdditionalRecipients];
        this.form.get('BccSuspiciousOutboundAdditionalRecipients').setValue([...new Set(newValues)]);
    }

    addNotFountNotifyOutboundSpamRecipients() {
        const oldValue = this.form.get('NotifyOutboundSpamRecipients').value || [];
        const newValues = [...oldValue, ...this.defaultPolicy.NotifyOutboundSpamRecipients];
        this.form.get('NotifyOutboundSpamRecipients').setValue([...new Set(newValues)]);
    }

    get formValue() {
        return this.form.value;
    }
}
