import { Component, Input } from '@angular/core';

@Component({
    selector: 'sway-baseline-status',
    templateUrl: './baseline-status.component.html',
    styleUrls: ['./baseline-status.component.scss'],
})
export class BaselineStatusComponent {
    @Input() errors: Array<any> = [];
    @Input() hasError: boolean;
    @Input() ignore: boolean;
}
