import { updateExoHostedOutboundSpamFilterPolicy } from 'src/app/stores/client/powershell/exo/hosted-outbound-spam-filter-policy/actions';
import { HostedOutboundSpamFilterPolicy } from 'src/app/stores/client/powershell/exo/hosted-outbound-spam-filter-policy/model';
export const IdentityName = 'Default';

export function Remediate(_tenant: string, data: HostedOutboundSpamFilterPolicy[], schema: any) {
    const defaultIdentity = data.find((res) => res.Identity === IdentityName);

    const item: Partial<HostedOutboundSpamFilterPolicy> = {
        BccSuspiciousOutboundMail: schema.contains.properties.BccSuspiciousOutboundMail.const,
        BccSuspiciousOutboundAdditionalRecipients:
            schema.contains.properties.BccSuspiciousOutboundAdditionalRecipients.items.enum ||
            getRemovedItems(defaultIdentity, 'BccSuspiciousOutboundAdditionalRecipients'),
        NotifyOutboundSpamRecipients:
            schema.contains.properties.NotifyOutboundSpamRecipients.items.enum ||
            getRemovedItems(defaultIdentity, 'NotifyOutboundSpamRecipients'),
        RecipientLimitExternalPerHour: schema.contains.properties.RecipientLimitExternalPerHour.const,
        RecipientLimitInternalPerHour: schema.contains.properties.RecipientLimitInternalPerHour.const,
        RecipientLimitPerDay: schema.contains.properties.RecipientLimitPerDay.const,
        ActionWhenThresholdReached: schema.contains.properties.ActionWhenThresholdReached.const,
        Identity: schema.contains.properties.Identity.const,
    };

    item.BccSuspiciousOutboundMail =
        item.BccSuspiciousOutboundMail === 'any' ? undefined : item.BccSuspiciousOutboundMail;
    item.NotifyOutboundSpam = schema.contains.properties.NotifyOutboundSpam
        ? schema.contains.properties.NotifyOutboundSpam?.const
        : undefined;

    return updateExoHostedOutboundSpamFilterPolicy({ _tenant, data: item });
}

function getRemovedItems(defaultIdentity: HostedOutboundSpamFilterPolicy, property: string): string | Array<any> {
    const list = defaultIdentity[property].map((res) => `"${res}"`).join(', ');
    if (list === undefined || list.length === 0) {
        return [];
    }
    return `@{Remove=${list}}`;
}
