import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, createSelector, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { InputAsn } from './model';

export const featureKey = 'InputAsn';

export interface State extends EntityState<InputAsn>, Status {}

export const adapter: EntityAdapter<InputAsn> = createEntityAdapter<InputAsn>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(actions.loadInputAsn, (state) => ({ ...state, loading: true })),
    on(actions.loadInputAsnSuccess, (state, action) =>
        adapter.setAll(action.data, { ...state, loading: false, loaded: true }),
    ),
    on(actions.loadInputAsnFailure, (state, { error }) => ({ ...state, error })),

    on(actions.createInputAsn, (state) => ({ ...state, creating: true })),
    on(actions.createInputAsnSuccess, (state, action) => adapter.addOne(action.data, { ...state, creating: false })),
    on(actions.createInputAsnFailure, (state, { error }) => ({ ...state, error, creating: false })),

    on(actions.deleteInputAsn, (state) => ({ ...state, deleting: true })),
    on(actions.deleteInputAsnSuccess, (state, action) => adapter.removeOne(action.id, { ...state, deleting: false })),
    on(actions.deleteInputAsnFailure, (state, { error }) => ({ ...state, error, deleting: false })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectEntity = (id: string) => createSelector(selectEntities, (entities) => entities[id]);
