import { createAction, props } from '@ngrx/store';
import { InputAsn } from './model';

export const loadInputAsn = createAction('[EventRuleInputAsn] Load');

export const loadInputAsnSuccess = createAction('[EventRuleInputAsn] Load Success', props<{ data: InputAsn[] }>());

export const loadInputAsnFailure = createAction('[EventRuleInputAsn] Load Failure', props<{ error: any }>());

export const createInputAsn = createAction('[EventRuleInputAsn] Create', props<{ data: InputAsn }>());

export const createInputAsnSuccess = createAction('[EventRuleInputAsn] Create Success', props<{ data: InputAsn }>());

export const createInputAsnFailure = createAction('[EventRuleInputAsn] Create Failure', props<{ error: any }>());

export const deleteInputAsn = createAction('[EventRuleInputAsn] Delete', props<{ id: string }>());

export const deleteInputAsnSuccess = createAction('[EventRuleInputAsn] Delete Success', props<{ id: string }>());

export const deleteInputAsnFailure = createAction(
    '[EventRuleInputAsn] Delete Failure',
    props<{ id: string; error: any }>(),
);
