import { updateExoMobileDeviceMailboxPolicy } from 'src/app/stores/client/powershell/exo/mobile-device-mailbox-policy/actions';
import { MobileDeviceMailboxPolicy } from 'src/app/stores/client/powershell/exo/mobile-device-mailbox-policy/model';

export function Remediate(_tenant: string, data: MobileDeviceMailboxPolicy[], schema: any) {
    const defaultPolicy = data.find((res) => res.IsDefault);

    const item: Partial<MobileDeviceMailboxPolicy> = {
        Identity: defaultPolicy.Identity,
        PasswordExpiration: schema.contains.properties.PasswordExpiration.const || undefined,
        MinPasswordComplexCharacters: schema.contains.properties.MinPasswordComplexCharacters.const || undefined,
        MinPasswordLength: schema.contains?.properties?.MinPasswordLength?.const || null,
        MaxInactivityTimeLock: schema.contains.properties.MaxInactivityTimeLock.const || undefined,
        RequireDeviceEncryption: schema.contains.properties.RequireDeviceEncryption.const || false,
        DeviceEncryptionEnabled: schema.contains.properties.DeviceEncryptionEnabled.const || false,
        AlphanumericPasswordRequired: schema.contains.properties.AlphanumericPasswordRequired?.const || false,
        AllowSimplePassword: schema.contains.properties.AllowSimplePassword.const || false,
        PasswordEnabled: schema.contains.properties.PasswordEnabled.const || false,
        PasswordHistory: schema.contains.properties.PasswordHistory.const || 0,
    };

    return updateExoMobileDeviceMailboxPolicy({ _tenant, data: item });
}
