import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { User } from './model';

export const fetchExoUsers = createAction('[Client/Pwsh/Exo] Fetch Users', props<{ _tenant: string }>());

export const fetchExoUsersSuccess = createAction(
    '[Client/Pwsh/Exo] Fetch Users Success',
    props<{ _tenant: string; data: User[] }>(),
);

export const fetchExoUsersFailure = createAction(
    '[Client/Pwsh/Exo] Fetch Users Failure',
    props<{ _tenant: string; error: any }>(),
);

export const addExoUser = createAction('[Root/Pwsh/Exo] Add ExoUser', props<{ _tenant: string; exoUser: User }>());

export const upsertExoUser = createAction(
    '[Root/Pwsh/Exo] Upsert ExoUser',
    props<{ _tenant: string; exoUser: User }>(),
);

export const addExoUsers = createAction('[Root/Pwsh/Exo] Add ExoUsers', props<{ _tenant: string; exoUsers: User[] }>());

export const upsertExoUsers = createAction(
    '[Root/Pwsh/Exo] Upsert ExoUsers',
    props<{ _tenant: string; exoUsers: User[] }>(),
);

export const updateExoUser = createAction(
    '[Root/Pwsh/Exo] Update ExoUser',
    props<{ _tenant: string; exoUser: Update<User> }>(),
);

export const updateExoUsers = createAction(
    '[Root/Pwsh/Exo] Update ExoUsers',
    props<{ _tenant: string; exoUsers: Update<User>[] }>(),
);

export const deleteExoUser = createAction('[Root/Pwsh/Exo] Delete ExoUser', props<{ _tenant: string; id: string }>());

export const deleteExoUsers = createAction(
    '[Root/Pwsh/Exo] Delete ExoUsers',
    props<{ _tenant: string; ids: string[] }>(),
);

export const clearExoUsers = createAction('[Root/Pwsh/Exo] Clear ExoUsers', props<{ _tenant: string }>());
