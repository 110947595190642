import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from '../../../../status.interface';
import * as actions from './actions';
import { User } from './model';

export const featureKey = 'exoUser';

export interface State extends EntityState<User>, Status {}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
    selectId: (mailbox) => mailbox.Identity,
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,

    on(actions.fetchExoUsersSuccess, (state, { data }) => adapter.setAll(data, { ...state, loaded: true })),
    on(actions.fetchExoUsersFailure, (state, { error }) => ({ ...state, loaded: false, error })),
    on(actions.addExoUser, (state, action) => adapter.addOne(action.exoUser, state)),
    on(actions.upsertExoUser, (state, action) => adapter.upsertOne(action.exoUser, state)),
    on(actions.addExoUsers, (state, action) => adapter.addMany(action.exoUsers, state)),
    on(actions.upsertExoUsers, (state, action) => adapter.upsertMany(action.exoUsers, state)),
    on(actions.updateExoUser, (state, action) => adapter.updateOne(action.exoUser, state)),
    on(actions.updateExoUsers, (state, action) => adapter.updateMany(action.exoUsers, state)),
    on(actions.deleteExoUser, (state, { id }) => adapter.removeOne(id, state)),
    on(actions.deleteExoUsers, (state, action) => adapter.removeMany(action.ids, state)),
    on(actions.clearExoUsers, (state) => adapter.removeAll(state)),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
