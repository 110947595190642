import { createAction, props } from '@ngrx/store';
import { MobileDeviceMailboxPolicy } from './model';

export const fetchMobileDeviceMailboxPolicy = createAction(
    '[Client/Pwsh/Exo] Fetch Mobile Device Mailbox Policy',
    props<{ _tenant: string }>(),
);

export const fetchMobileDeviceMailboxPolicySuccess = createAction(
    '[Client/Pwsh/Exo] Fetch Mobile Device Mailbox Policy Success',
    props<{ _tenant: string; data: MobileDeviceMailboxPolicy[] }>(),
);

export const fetchMobileDeviceMailboxPolicyFailure = createAction(
    '[Client/Pwsh/Exo] Fetch Mobile Device Mailbox Policy Failure',
    props<{ _tenant: string; error: any }>(),
);

export const updateExoMobileDeviceMailboxPolicy = createAction(
    '[Client/Pwsh/Exo] Update Mobile Device Mailbox Policy',
    props<{ _tenant: string; data: Partial<MobileDeviceMailboxPolicy> }>(),
);

export const updateExoMobileDeviceMailboxPolicySuccess = createAction(
    '[Client/Pwsh/Exo] Update Mobile Device Mailbox Policy Success',
    props<{ _tenant: string; data: MobileDeviceMailboxPolicy }>(),
);

export const updateExoMobileDeviceMailboxPolicyFailure = createAction(
    '[Client/Pwsh/Exo] Update Mobile Device Mailbox Policy Failure',
    props<{ _tenant: string; error: any }>(),
);
