import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { DateTime } from 'luxon';
import { ReputationMap, Summary } from 'microsoft-events/dist/interfaces/summary.interface';
import {
    EMPTY,
    combineLatest,
    forkJoin,
    of,
    catchError,
    expand,
    map,
    mergeMap,
    reduce,
    tap,
    withLatestFrom,
    switchMap,
    take,
    from,
    filter,
    skipUntil,
    pipe,
    Observable,
} from 'rxjs';
import {
    createEventRule,
    createEventRuleSuccess,
    deleteEventRule,
    deleteEventRuleSuccess,
    updateEventRule,
    updateEventRuleSuccess,
} from 'src/app/modules/msp/store/event-rules/actions';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { BrotliService } from 'src/app/services/brotli.service';
import { CustomActionDeduplicateOperator } from 'src/app/stores/actions.interface';
import { client } from '../..';

import {
    RecalculateRequest,
    loadSummariesByStartEnd,
    loadSummarys,
    recalculateSummarys,
    recalculateSummarysFailure,
    recalculateSummarysSuccess,
    upsertSummarys,
} from './actions';
import { Coord } from 'microsoft-events/dist/interfaces/meta.interface';
import { selectMspData, selectMspGeos } from 'src/app/modules/msp/store';
import { EventRule } from 'src/app/modules/msp/store/event-rules/model';

const worker = new Worker(new URL('./effects.worker', import.meta.url));

export function isPointInCircle(checkPoint: Coord, centerPoint: Coord, radius: number) {
    const ky = 40000 / 360;
    const kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
    const dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
    const dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
    return Math.sqrt(dx * dx + dy * dy) <= radius / 1000;
}

function RuleGeoInReputationMap(geo: { center: Coord; radius: number }, rep: ReputationMap): boolean {
    return Object.values(rep).some(({ latitude, longitude }) =>
        isPointInCircle({ lat: latitude, lng: longitude }, geo.center, geo.radius),
    );
}

function RuleAsnInReputationMap(rule_asn: number | string, rep: ReputationMap): boolean {
    return Object.values(rep).some(({ asn }) => rule_asn === asn);
}

function SummariesAffectedByRule(summaries: Summary[], rule: EventRule): Summary[] {
    const affected: Summary[] = [];

    for (const summary of summaries) {
        if (rule.start && rule.start > summary.end) continue;
        if (rule.end && rule.end < summary.start) continue;

        const match =
            rule.country.some((cc) => !!summary.data.country[cc]) ||
            rule.ip.some((ip) => !!summary.data.ip[ip]) ||
            rule.asn.some((asn) => RuleAsnInReputationMap(asn, summary.data.reputation)) ||
            rule.geo.some((geo) => RuleGeoInReputationMap(geo, summary.data.reputation));

        if (match) {
            affected.push(summary);
        }
    }

    return affected;
}

@Injectable()
export class SummaryEffects {
    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private store: Store<any>,
        private brotliService: BrotliService,
    ) {}

    private decompress() {
        return mergeMap((data: any) =>
            (data.length ? combineLatest(data.map((item) => this.brotliService.decompress(item.data))) : of([])).pipe(
                map(
                    (results) =>
                        data.map((item, i) => ({
                            ...item,
                            data: results[i],
                        })) as Summary[],
                ),
            ),
        );
    }

    private fetchWithPaging(_tenant: string, start: string, end: string) {
        return this.ajax.get(_tenant, `/api/microsoft/monitoring/summary?start=${start}&end=${end}`).pipe(
            expand((data) => {
                if (data.LastEvaluatedKey) {
                    const lek = encodeURIComponent(JSON.stringify(data.LastEvaluatedKey));
                    return this.ajax.get(
                        _tenant,
                        `/api/microsoft/monitoring/summary?start=${start}&end=${end}&LastEvaluatedKey=${lek}`,
                    );
                } else {
                    return EMPTY;
                }
            }),
            reduce((acc, data: any) => acc.concat(data.Items), []),
            this.decompress(),
        );
    }

    private fetchSummariesParallel(_tenant: string, days: number) {
        if (days <= 7) {
            const date = DateTime.now();
            const end = date.plus({ days: 1 }).toISODate();
            const start = date.minus({ days }).toISODate();
            return this.fetchWithPaging(_tenant, start, end);
        }

        // break into 4 calls
        const best_fit = Math.ceil(days / 4);
        const remainder = days - best_fit * 3;
        const calls = [best_fit, best_fit, best_fit, remainder + 1]; // +1 so we get up-to the next date
        let date = DateTime.now().minus({ days }); // start at first date
        const ranges = calls.map((days) => {
            const start = date.toISODate();
            date = date.plus({ days }); // increment days
            const end = date.toISODate();
            return [start, end]; // save as YYYY-MM-DD
        });

        const tasks = ranges.map(([start, end]) => this.fetchWithPaging(_tenant, start, end));

        return combineLatest(tasks).pipe(map((res) => res.flat()));
    }

    private fetchSummaryByStartEnd(_tenant: string, start: string, end: string) {
        return this.ajax.get(_tenant, `/api/microsoft/monitoring/summary?start=${start}&end=${end}`).pipe(
            expand((data) => {
                if (data.LastEvaluatedKey) {
                    const lek = encodeURIComponent(JSON.stringify(data.LastEvaluatedKey));
                    return this.ajax.get(
                        _tenant,
                        `/api/microsoft/monitoring/summary?start=${start}&end=${end}&LastEvaluatedKey=${lek}`,
                    );
                } else {
                    return EMPTY;
                }
            }),
            reduce((acc, data: any) => acc.concat(data.Items), []),
            this.decompress(),
        );
    }

    private recalculateSummarys(_tenant: string, dates: RecalculateRequest[]) {
        const chunks = [];

        for (let i = 0; i < dates.length; i += 500) {
            chunks.push(dates.slice(i, i + 500));
        }

        return forkJoin(chunks.map((chunk) => this.ajax.post(_tenant, '/api/microsoft/monitoring/summary', chunk)));
    }

    loadSummaries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadSummarys),
            mergeMap((action) =>
                CustomActionDeduplicateOperator(action, this.store.select(client(action._tenant).summary.num_of_days)),
            ),
            mergeMap(({ _tenant, num_days }) =>
                this.fetchSummariesParallel(_tenant, num_days).pipe(
                    map((summarys) => upsertSummarys({ _tenant, summarys, num_days })),
                    catchError((error) => {
                        console.error(error);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    loadSummariesByStartEnd$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadSummariesByStartEnd),
            mergeMap(({ _tenant, start, end }) =>
                this.fetchSummaryByStartEnd(_tenant, start, end).pipe(
                    map((summarys) => upsertSummarys({ _tenant, summarys })),
                    catchError((error) => {
                        console.error(error);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    recalculate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(recalculateSummarys),
            tap((action) => console.log('updating', action.dates.length, 'summaries')),
            mergeMap((action) => {
                return this.recalculateSummarys(action._tenant, action.dates).pipe(
                    map(() => recalculateSummarysSuccess({ _tenant: action._tenant })),
                    catchError((error) =>
                        of(
                            recalculateSummarysFailure({
                                _tenant: action._tenant,
                                error,
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    // ensure summaries are loaded to check whether rule requires summary be update
    ruleChanged$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createEventRule, updateEventRule, deleteEventRule),
            switchMap((_) => this.store.select(selectMspData)),
            mergeMap(({ tenants }) => tenants.map(({ id }) => loadSummarys({ _tenant: id, num_days: 90 }))),
        ),
    );

    checkSummariesPipe = (source: Observable<{ rule: EventRule }>): Observable<any> => {
        return source.pipe(
            withLatestFrom(
                // all summaries from all tenants
                this.store.pipe(
                    select(selectMspData),
                    filter((data) => !!data),
                    switchMap(({ tenants }) =>
                        combineLatest(
                            tenants.map(({ id }) =>
                                this.store.pipe(
                                    select(client(id).summary.all),
                                    skipUntil(
                                        this.store.pipe(
                                            select(client(id).summary.status),
                                            map((status) => status.loaded),
                                        ),
                                    ),
                                    map((summaries) => ({ id, summaries })),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
            mergeMap(([{ rule }, tenant_summaries]) => {
                const actions = [];

                for (const { id, summaries } of tenant_summaries) {
                    const affected = SummariesAffectedByRule(summaries, rule);

                    if (affected.length > 0) {
                        const dates = affected.map(({ start, end }) => ({ start, end }));
                        actions.push(recalculateSummarys({ _tenant: id, dates }));
                    }
                }

                return actions;
            }),
        );
    };

    createEventRuleSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createEventRuleSuccess),
            withLatestFrom(this.store.select(selectMspGeos)),
            map(([{ rule }, geos]) => {
                const geo_ids = rule.input_geo;
                const input_geos = geos.filter((geo) => geo_ids.some((id) => geo.id === id));
                const geo = input_geos.map((geo) => ({
                    center: {
                        lat: geo.lat,
                        lng: geo.lng,
                    },
                    radius: geo.radius,
                }));
                return { rule: { ...rule, geo } };
            }),
            this.checkSummariesPipe,
        ),
    );

    updateEventRuleSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateEventRuleSuccess),
            withLatestFrom(this.store.select(selectMspGeos)),
            map(([{ rule, previous }, geos]) => {
                const geo_ids = [...rule.input_geo, ...previous.input_geo];
                const input_geos = geos.filter((geo) => geo_ids.some((id) => geo.id === id));
                const geo = input_geos.map((geo) => ({
                    center: {
                        lat: geo.lat,
                        lng: geo.lng,
                    },
                    radius: geo.radius,
                }));

                // this is a merging of values from previous and current versions of the rule
                // thus allowing the checkSummariesPipe to detect what summaries need to be reprocessed
                const mergedRule: EventRule = {
                    ...rule,
                    ip: [...new Set([...rule.ip, ...previous.ip])], // use ...Set to filter duplicates
                    asn: [...new Set([...rule.asn, ...previous.asn])],
                    country: [...new Set([...rule.country, ...previous.country])],
                    geo, // TODO: could optimize to filter duplicates
                    target_users: [...rule.target_users, ...previous.target_users], // TODO: could optimize to filter duplicates
                    target_tenants: [...new Set([...rule.target_tenants, ...previous.target_tenants])],
                    input_geo: [],
                };

                return { rule: mergedRule };
            }),
            this.checkSummariesPipe,
        ),
    );

    deleteEventRuleSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteEventRuleSuccess),
            withLatestFrom(this.store.select(selectMspGeos)),
            map(([{ rule }, geos]) => {
                const geo_ids = rule.input_geo;
                const input_geos = geos.filter((geo) => geo_ids.some((id) => geo.id === id));
                const geo = input_geos.map((geo) => ({
                    center: {
                        lat: geo.lat,
                        lng: geo.lng,
                    },
                    radius: geo.radius,
                }));
                return { rule: { ...rule, geo } };
            }),
            this.checkSummariesPipe,
        ),
    );
}
