import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, distinct, mergeMap, map } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as actions from './actions';
import { MobileDeviceMailboxPolicy } from './model';
import { retry } from 'src/app/pipes/retry.pipe';

////////   APPLIES TO ON-PREM ONLY   ////////
const endPoint = '/api/microsoft/powershell/exo/mobile-device-mailbox-policy';
@Injectable()
export class ExoMobileDeviceMailboxPolicyEffects {
    fetchMobileDeviceMailBoxPolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.fetchMobileDeviceMailboxPolicy),
            distinct((action) => action._tenant),
            mergeMap((action) =>
                this.get(action._tenant).pipe(
                    retry(3000, 3, 'mobile device mailbox policy timeout'),
                    map((data: MobileDeviceMailboxPolicy[]) => data.map((res) => ({ ...res, id: res.Identity }))),
                    map((data: MobileDeviceMailboxPolicy[]) =>
                        actions.fetchMobileDeviceMailboxPolicySuccess({ _tenant: action._tenant, data }),
                    ),
                    catchError((error) =>
                        of(
                            actions.fetchMobileDeviceMailboxPolicyFailure({
                                _tenant: action._tenant,
                                error: { message: error.message, trace: error.trace },
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    private get(tenant: string): Observable<MobileDeviceMailboxPolicy[]> {
        return this.ajax.get(tenant, endPoint);
    }

    updateExoMobileDeviceMailBoxPolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateExoMobileDeviceMailboxPolicy),
            mergeMap((action) =>
                this.put(action._tenant, action.data).pipe(
                    map((res) => ({ id: res[0].Identity, ...res[0] })),
                    map((data: MobileDeviceMailboxPolicy) =>
                        actions.updateExoMobileDeviceMailboxPolicySuccess({ _tenant: action._tenant, data }),
                    ),
                    catchError((error) =>
                        of(actions.updateExoMobileDeviceMailboxPolicyFailure({ _tenant: action._tenant, error })),
                    ),
                ),
            ),
        ),
    );

    private put(tenant: string, body: Partial<MobileDeviceMailboxPolicy>): Observable<MobileDeviceMailboxPolicy> {
        return this.ajax.put(tenant, endPoint, body);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
    ) {}
}
