import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MalwareFilterPolicy } from 'src/app/interfaces/powershell/exo/malware-filter-policy.interface';
import { BaseComponent } from '../../base/base.component';
@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../styles/baseline-style.scss'],
})
export class BaselineComponent extends BaseComponent implements OnInit, OnChanges {
    defaultPolicy: MalwareFilterPolicy;

    ngOnInit(): void {
        const schema = JSON.parse(JSON.stringify(this.baseline.schema));
        this.defaultPolicy = this.data.find((res) => res.IsDefault);

        const value = schema?.contains.properties?.EnableFileFilter?.const;

        this.control.setValue(value);

        this.control.valueChanges.subscribe((value) => {
            schema.contains.properties.EnableFileFilter['const'] = value;

            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema)),
            });
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.defaultPolicy = this.data.find((res) => res.IsDefault);
    }
}
