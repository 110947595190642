import { EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { createStatusEntityAdapter, EntityStatus, initialEntityStatus } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { Dismiss } from './model';

export const featureKey = 'dismiss';

export interface State extends EntityState<Dismiss>, EntityStatus {}

export const adapter = createStatusEntityAdapter<Dismiss>({});

export const initialState: State = adapter.getInitialState({
    ...initialEntityStatus,
});

export const reducer = createReducer(
    initialState,

    on(actions.loadDismissItemsSuccess, (state, { data }) => adapter.upsertMany(data, { ...state, loaded: true })),
    on(actions.createDismissItem, (state) => ({ ...state, creating: true })),
    on(actions.createDismissItemSuccess, (state, { data }) => adapter.upsertOne(data, { ...state, creating: false })),
    on(actions.createDismissItemFailure, (state) => ({ ...state, creating: false })),
    on(actions.deleteDismissItem, (state) => ({ ...state, updating: true })),
    on(actions.deleteDismissItemSuccess, (state, { id }) => adapter.removeOne(id, { ...state, updating: false })),
    on(actions.deleteDismissItemFailure, (state, action) => ({ ...state, updating: false, error: action.error })),
);

export const { selectIds, selectEntities, selectAll, selectTotal, selectEntityStatus } = adapter.getSelectors();
