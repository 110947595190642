import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { combineLatest, first, map, Observable, startWith, switchMap, take } from 'rxjs';
import { MalwareFilterPolicy } from 'src/app/interfaces/powershell/exo/malware-filter-policy.interface';
import { client } from 'src/app/stores/client';
import { selectSession } from 'src/app/stores/root.store';
import { isValidEmail } from 'src/app/utils/custom-validators';
import { BaseComponent } from '../../base/base.component';
import { Identity } from './remediate';

@Component({
    selector: 'app-baseline',
    templateUrl: './baseline.component.html',
    styleUrls: ['./baseline.component.scss', '../../styles/baseline-style.scss'],
})
export class BaselineComponent extends BaseComponent implements OnInit, OnChanges {
    emailsLookups: string[];
    defaultPolicy: MalwareFilterPolicy;

    tenant_id$: Observable<string> = this.store.pipe(
        select(selectSession),
        map((sess) => sess.session.clientId),
    );

    constructor(
        private fb: FormBuilder,
        private store: Store<any>,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.defaultPolicy = this.data.find((res) => res.Identity === Identity);
    }

    ngOnInit(): void {
        const schema = JSON.parse(JSON.stringify(this.baseline.schema));
        this.form = this.initForm(schema);

        this.defaultPolicy = this.data.find((res) => res.Identity === Identity);
        this.getMailboxLookup().subscribe((res) => {
            this.emailsLookups = res;
        });

        this.form.valueChanges.pipe(startWith(this.form.value)).subscribe((res) => {
            schema.contains.properties.EnableInternalSenderAdminNotifications['const'] =
                res.EnableInternalSenderAdminNotifications;

            schema.contains.properties.InternalSenderAdminAddress['const'] =
                (res.InternalSenderAdminAddress as string)?.trim() || '';

            const hasError =
                res.EnableInternalSenderAdminNotifications &&
                (!res.InternalSenderAdminAddress || !isValidEmail(res.InternalSenderAdminAddress));

            this.baselineErrorChange.next({
                remediate: hasError,
                save: hasError,
            });

            this.baselineChange.next({
                ...this.baseline,
                schema: JSON.parse(JSON.stringify(schema)),
            });
        });
    }

    initForm(schema: any): FormGroup {
        return this.fb.group({
            EnableInternalSenderAdminNotifications: [
                schema?.contains?.properties?.EnableInternalSenderAdminNotifications?.const,
            ],
            InternalSenderAdminAddress: [schema?.contains?.properties?.InternalSenderAdminAddress?.const],
        });
    }

    getMailboxLookup() {
        return this.tenant_id$.pipe(
            switchMap((tid) =>
                combineLatest([
                    this.store.select(client(tid).graph.users.withMailbox),
                    this.store.select(client(tid).graph.users.status),
                ]),
            ),
            first(([data, status]) => status.loaded),
            map(([data]) => data),
            map((res) => res.map((re) => re['mail'])),
            take(1),
        );
    }

    onSelectEmail(e) {
        this.form.get('InternalSenderAdminAddress').setValue(e);
    }

    get formValue() {
        return this.form.value;
    }
}
