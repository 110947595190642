import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'refresh-snack-bar',
    templateUrl: 'refresh-snack-bar.html',
    styleUrls: ['refresh-snack-bar.scss'],
})
export class RefreshSnackBarComponent {
    constructor(
        public snackBarRef: MatSnackBarRef<RefreshSnackBarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
    ) {}

    reload() {
        window.location.reload();
    }
}
