import { createAction, props } from '@ngrx/store';
import { TargetUser } from './model';

export const loadTargetUser = createAction('[EventRuleTargetUser] Load');

export const loadTargetUserSuccess = createAction(
    '[EventRuleTargetUser] Load Success',
    props<{ users: TargetUser[] }>(),
);

export const loadTargetUserFailure = createAction('[EventRuleTargetUser] Load Failure', props<{ error: any }>());

export const createTargetUser = createAction('[EventRuleTargetUser] Create', props<{ user: TargetUser }>());

export const createTargetUserSuccess = createAction(
    '[EventRuleTargetUser] Create Success',
    props<{ user: TargetUser }>(),
);

export const createTargetUserFailure = createAction('[EventRuleTargetUser] Create Failure', props<{ error: any }>());

export const deleteTargetUser = createAction('[EventRuleTargetUser] Delete', props<{ id: string }>());

export const deleteTargetUserSuccess = createAction('[EventRuleTargetUser] Delete Success', props<{ id: string }>());

export const deleteTargetUserFailure = createAction(
    '[EventRuleTargetUser] Delete Failure',
    props<{ id: string; error: any }>(),
);
