import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { take, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { client } from 'src/app/stores/client';
@Component({
    selector: 'billing-info-form',
    templateUrl: './info-form.component.html',
    styleUrls: ['./info-form.component.scss'],
})
export class InfoFormComponent implements OnInit, OnDestroy {
    @Output() info = new EventEmitter<any>();
    subscriptions: Subscription[] = [];
    public formGroup: FormGroup;
    public tenant_id = this.route.snapshot.paramMap.get('tenant');

    constructor(
        private fb: FormBuilder,
        private store: Store<any>,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.formGroup = this.fb.group({
            email: ['', [Validators.email]],
        });

        this.subscriptions.push(
            this.formGroup.valueChanges.subscribe((res) => {
                if (this.formGroup.valid) {
                    this.info.emit(this.formGroup.value);
                } else {
                    this.info.emit(false);
                }
            }),
        );

        this.store
            .pipe(
                select(client(this.tenant_id).octiga.tenant.data),
                filter((t) => !!t),
                take(1),
            )
            .subscribe((tenant) => {
                if (!!tenant.stripe_customer_email) {
                    this.formGroup.patchValue({ email: tenant.stripe_customer_email });
                } else if (!!tenant.contact_email) {
                    this.formGroup.patchValue({ email: tenant.contact_email });
                }
            });
    }

    ngOnDestroy() {
        for (const s of this.subscriptions) {
            s.unsubscribe();
        }
    }
}
