import { Store, select } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { windows10CompliancePolicyDisplayName } from 'src/app/modules/sway/tools/schemas/compliance-policies/Windows10CompliancePolicy';
import { Windows10CompliancePolicySchema } from 'src/app/modules/sway/tools/schemas/compliance-policies/Windows10CompliancePolicy/model';
import { client } from 'src/app/stores/client';
import { PolicyAssignment } from 'src/app/stores/client/graph/compliance-policies/policies/modet';
export { BaselineComponent } from './baseline.component';
export { Remediate } from './remediate';
export const href = 'https://learn.microsoft.com/en-us/mem/intune/protect/compliance-policy-create-windows';

export function selectData(store: Store, tenant: string): Observable<PolicyAssignment[]> {
    return store.pipe(
        select(client(tenant).graph.compliancePolicies.all),
        map((res) => {
            const policies = res.filter((res) => res?.policy?.displayName === windows10CompliancePolicyDisplayName);

            return policies;
        }),
    );
}

export function getTargetGroupsIds(schema: Windows10CompliancePolicySchema, type): string[] {
    const items =
        schema?.contains?.properties?.assignments?.items?.properties?.target?.oneOf?.filter(
            (res) => res?.properties?.['@odata.type']?.const === type,
        ) || [];
    const result = items?.map((res) => res.properties.groupId.const);

    return result;
}

export function getPolicyAssignments(schema: Windows10CompliancePolicySchema) {
    const items = schema.contains.properties?.assignments?.items?.properties?.target?.oneOf;

    //  filter(res => res?.properties?.['@odata.type']?.const === targetType);

    const result = items?.map((schemaItem) => {
        const data_type = schemaItem.properties?.['@odata.type'].const;
        const groupId = schemaItem?.properties?.groupId?.const;

        return {
            '@odata.type': '#microsoft.graph.deviceCompliancePolicyAssignment',
            // id will be set in the effect
            target: {
                '@odata.type': data_type,
                groupId,
            },
        };
    });

    return result || [];
}
