import { ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import { resetState } from '../meta-reducers/reset.meta-reducer';
import * as fromGeoJson from './root/google/geojson/reducer';
import * as fromDirectoryRoleTemplates from './root/graph/directoryRoleTemplates/reducer';
import * as fromAsnList from './root/octiga/asn-list/reducer';
import * as fromSession from './root/octiga/session/reducer';
import * as fromReportCategory from './root/report/category/reducer';
import * as fromSwayCategory from './root/sway/category/reducer';
import * as fromSpec from './root/sway/spec/reducer';
import { selectStatus } from './status.interface';
import { routerReducer } from '@ngrx/router-store';
import * as fromSwayMspConfig from '../stores/root/sway/onboardingStatus/reducer';

export interface State {
    router: typeof routerReducer;
    [fromAsnList.featureKey]: fromAsnList.State;
    [fromGeoJson.featureKey]: fromGeoJson.State;
    [fromSession.featureKey]: fromSession.State;
    [fromSpec.featureKey]: fromSpec.State;
    [fromSwayCategory.featureKey]: fromSwayCategory.State;
    [fromReportCategory.featureKey]: fromReportCategory.State;
    [fromDirectoryRoleTemplates.featureKey]: fromDirectoryRoleTemplates.State;
    [fromSwayMspConfig.featureKey]: fromSwayMspConfig.State;
}

export const reducers: ActionReducerMap<State> = {
    router: routerReducer,
    [fromAsnList.featureKey]: fromAsnList.reducer,
    [fromGeoJson.featureKey]: fromGeoJson.reducer,
    [fromSession.featureKey]: fromSession.reducer,
    [fromSpec.featureKey]: fromSpec.reducer,
    [fromSwayCategory.featureKey]: fromSwayCategory.reducer,
    [fromReportCategory.featureKey]: fromReportCategory.reducer,
    [fromDirectoryRoleTemplates.featureKey]: fromDirectoryRoleTemplates.reducer,
    [fromSwayMspConfig.featureKey]: fromSwayMspConfig.reducer,
};

export const metaReducers: MetaReducer[] = [resetState];

// ASN List
const selectAsnListFeature = createFeatureSelector<fromAsnList.State>(fromAsnList.featureKey);
export const selectAsnList = createSelector(selectAsnListFeature, fromAsnList.selectAll);
export const selectAsnListStatus = createSelector(selectAsnListFeature, (state) => selectStatus(state));

// GeoJson
const selectGeoJsonFeature = createFeatureSelector<fromGeoJson.State>(fromGeoJson.featureKey);
export const selectGeoJson = createSelector(selectGeoJsonFeature, fromGeoJson.geoJsonData);

export const selectGeoJsonStatus = createSelector(selectGeoJsonFeature, (state) => selectStatus(state));

//#region Session
const selectSessionFeature = createFeatureSelector<fromSession.State>(fromSession.featureKey);
export const selectSession = createSelector(selectSessionFeature, fromSession.getSession);

export const selectAuthToken = createSelector(selectSession, (res) => res.session?.token);

export const getSession = createSelector(selectSessionFeature, ({ session }) => session);

export const getSessionMspId = createSelector(getSession, (session) => session?.msp_id);

export const getSessionClientId = createSelector(getSession, (session) => session?.clientId);

export const getHasCurrentTenant = createSelector(getSession, (session) => Boolean(session?.clientId));
//#endregion

const selectSwaySpecFeature = createFeatureSelector<fromSpec.State>(fromSpec.featureKey);
export const selectSwaySpecAll = createSelector(selectSwaySpecFeature, fromSpec.selectAll);
export const selectSwaySpecByType = (type: string) =>
    createSelector(selectSwaySpecAll, (res) => res.filter((re) => re.type === type));
export const selectSwaySpecByTag = (tag: string) =>
    createSelector(selectSwaySpecAll, (res) => res.find((re) => re.tag === tag));
export const selectSwaySpecById = (id: string) =>
    createSelector(selectSwaySpecAll, (res) => res.find((re) => re.id === id));

export const selectSwaySpecStatus = createSelector(selectSwaySpecFeature, (state) => selectStatus(state));

// sway report cateory
const selectReportCategoryFeature = createFeatureSelector<fromReportCategory.State>(fromReportCategory.featureKey);
export const selectReportCategories = createSelector(selectReportCategoryFeature, fromReportCategory.selectAll);
export const selectReportCategoriesStatus = createSelector(selectReportCategoryFeature, (state) => selectStatus(state));

// sway category
const selectSwayCategoryFeature = createFeatureSelector(fromSwayCategory.featureKey);
export const selectSwayCategories = createSelector(selectSwayCategoryFeature, fromSwayCategory.selectAll);
export const selectSwayTenantCategories = createSelector(selectSwayCategories, (categories) =>
    categories.filter((res) => res.type === 'tenant'),
);
export const selectSwayGroupCategories = createSelector(selectSwayCategories, (categories) =>
    categories.filter((res) => res.type === 'group'),
);
export const selectSwayCategoriesStatus = createSelector(selectSwayCategoryFeature, (state) => selectStatus(state));

// graph directory roles
const selectGraphDirectoryRolesTemplate = createFeatureSelector(fromDirectoryRoleTemplates.featureKey);
export const selectGraphDirectoryRoleTemplates = createSelector(
    selectGraphDirectoryRolesTemplate,
    fromDirectoryRoleTemplates.selectAll,
);

export const selectGraphDirectoryRoleTemplatesStatus = createSelector(selectGraphDirectoryRolesTemplate, (state) =>
    selectStatus(state),
);

export const selectSwayCategoryById = (id: string) =>
    createSelector(selectSwayCategories, (categories) => categories.find((g) => g.id === id));

const selectSwayMspConfigFeature = createFeatureSelector<fromSwayMspConfig.State>(fromSwayMspConfig.featureKey);

// msp config
export const selectSwayMspConfig = createSelector(selectSwayMspConfigFeature, fromSwayMspConfig.selectAll);
export const selectSwayMspConfigStatus = createSelector(selectSwayMspConfigFeature, selectStatus);
