import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { EntityStatus, initialStatus } from 'src/app/stores/status.interface';
import * as actions from './actions';
import { RipeASN } from './model';

export const featureKey = 'ripeAsns';

export interface State extends EntityState<RipeASN>, EntityStatus {}

export const adapter = createEntityAdapter<RipeASN>({
    selectId: (item: RipeASN) => item.asn,
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,
    on(actions.loadASNList, (state) => ({
        ...state,
        loading: true,
    })),
    on(actions.loadASNListSuccess, (state, { data }) =>
        adapter.upsertMany(data, {
            ...state,
            loading: false,
            loaded: true,
        }),
    ),
    on(actions.loadASNListFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false,
        updating: false,
    })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
