import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SharingPolicy } from 'src/app/interfaces/powershell/exo/sharing-policy.interface';
import { initialStatus, Status } from '../../../../status.interface';
import * as SharingActions from './actions';

export const featureKey = 'exoSharingPolicy';

export interface State extends EntityState<SharingPolicy>, Status {}

export const adapter: EntityAdapter<SharingPolicy> = createEntityAdapter<SharingPolicy>({
    selectId: (policy) => policy.Id,
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus,
});

export const reducer = createReducer(
    initialState,

    on(SharingActions.loadSharingPoliciesSuccess, (state, action) =>
        adapter.setAll(action.data, { ...state, loaded: true }),
    ),
    on(SharingActions.loadSharingPoliciesFailure, (state, { error }) => ({ ...state, error })),
    on(SharingActions.updateSharingPolicySuccess, (state, data) => {
        const _newDataArray = [].concat(selectAll(state));
        const index = _newDataArray.findIndex((d) => d.Identity === data.Identity);
        const _dataObj = { ..._newDataArray[index] };
        const newPolicy = {
            ..._dataObj,
            Domains: data.Domains,
        };
        _newDataArray[index] = newPolicy;
        return adapter.setAll(_newDataArray, { ...state, loaded: true });
    }),
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
