import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BillingRoutingModule } from './billing-routing.module';
import { CardFormComponent } from './components/card-form/card-form.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ContainerComponent } from './components/container/container.component';
import { InfoFormComponent } from './components/info-form/info-form.component';
import { PaymentStatusComponent } from './components/payment-status/payment-status.component';
import { SubscriptionPlanComponent } from './components/subscription-plan/subscription-plan.component';
import { BillingEffects } from './store/effects/billing.effects';
import * as fromStore from './store/reducers';

@NgModule({
    declarations: [
        ContainerComponent,
        CardFormComponent,
        SubscriptionPlanComponent,
        CheckoutComponent,
        InfoFormComponent,
        PaymentStatusComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BillingRoutingModule,
        MatButtonModule,
        MatCardModule,
        MatListModule,
        MatIconModule,
        MatStepperModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        StoreModule.forFeature(fromStore.storeFeatureKey, fromStore.reducers, { metaReducers: fromStore.metaReducers }),
        EffectsModule.forFeature([BillingEffects]),
    ],
})
export class BillingModule {}
