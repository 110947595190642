import { of, throwError } from 'rxjs';
import { delay, map, mergeMap, retryWhen } from 'rxjs/operators';

export function retry(millisecond: number, count: number, msg: string) {
    return retryWhen((error) => {
        return error.pipe(
            delay(millisecond),
            map((res) => {
                count--;
                if (count === 0) {
                    console.log('XHR retry limit reached');
                    throw msg;
                } else {
                    console.log('retrying XHR call');
                    return res;
                }
            }),
        );
    });
}

// export function retryNoErrorChange(millisecond: number, count: number) {
//     return retryWhen(errors => {
//         let retryCount = 0;
//         return errors.pipe(
//             delay(millisecond),
//             mergeMap(error => {
//                 if (retryCount++ < count) {
//                     console.log(`Retrying XHR call (${retryCount}/${count})`);
//                     return of(error);
//                 } else {
//                     console.log('XHR retry limit reached');
//                     return throwError(() => error);
//                 }
//             })
//         );
//     });
// }
