import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from '../../../../status.interface';
import * as actions from './actions';
import { AdminAuditLogConfig } from './model';

export const featureKey = 'exoAdminAuditLogConfig';

export interface State extends Status {
    data: AdminAuditLogConfig;
}

export const initialState: State = {
    data: undefined,
    ...initialStatus,
};

export const reducer = createReducer(
    initialState,

    on(actions.fetchExoAdminAuditLogConfigSuccess, (state, { data }) => ({
        ...state,
        loaded: true,
        error: null,
        data,
    })),
    on(actions.fetchExoAdminAuditLogConfigFailure, (state, { error }) => ({ ...state, error })),
    on(actions.updateExoAdminAuditLogConfig, (state) => ({ ...state, updating: true, error: null })),
    on(actions.updateExoAdminAuditLogConfigSuccess, (state, { data }) => ({
        ...state,
        updating: false,
        error: null,
        data,
    })),
    on(actions.updateExoAdminAuditLogConfigFailure, (state, { error }) => ({ ...state, updating: false, error })),
);

export const selectItem = (state: State) => state.data;
