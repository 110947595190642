import { createAction, props } from '@ngrx/store';
import { MSPUser } from './model';

export const loadMspUsers = createAction('[Msp/API] Load Msp Users', props<{ msp_id: string }>());

export const loadMspUsersSuccess = createAction('[Msp/API] Load Msp Users Success', props<{ msp_users: MSPUser[] }>());

export const loadMspUsersFailure = createAction('[Msp/API] Load Msp Users Failure', props<{ error: any }>());

export const upsertMspUser = createAction('[Msp/API] Upsert Msp User', props<{ msp_id: string; msp_user: MSPUser }>());

export const upsertMspUserSuccess = createAction('[Msp/API] Upsert Msp User Success', props<{ msp_user: MSPUser }>());

export const upsertMspUserFailure = createAction('[Msp/API] Upsert Msp User Failure', props<{ error: any }>());

export const updateMspUser = createAction('[Msp/API] Update Msp User', props<{ data: MSPUser }>());

export const updateMspUserSuccess = createAction('[Msp/API] Update Msp User Success', props<{ data: MSPUser }>());

export const updateMspUserFailure = createAction('[Msp/API] Update Msp User Failure', props<{ error: any }>());

export const deleteMspUser = createAction('[Msp/API] Delete Msp User', props<{ msp_id: string; id: string }>());

export const deleteMspUserSuccess = createAction('[Msp/API] Delete Msp User Success', props<{ id: string }>());

export const deleteMspUserFailure = createAction('[Msp/API] Delete Msp User Failure', props<{ error: any }>());
