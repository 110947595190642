import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, distinct, map, mergeMap } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';

import * as actions from './actions';
import { SpoTenant } from './model';

@Injectable()
export class SpoTenantEffects {
    fetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.fetchSpoTenant),
            distinct((action) => action._tenant),
            mergeMap((action) =>
                this.get(action._tenant).pipe(
                    map((data: SpoTenant[]) =>
                        actions.fetchSpoTenantSuccess({ _tenant: action._tenant, data: data[0] }),
                    ),
                    catchError((error) => of(actions.fetchSpoTenantFailure({ _tenant: action._tenant, error }))),
                ),
            ),
        ),
    );

    private get(tenant: string): Observable<SpoTenant[]> {
        return this.ajax.get(tenant, '/api/microsoft/powershell/spo/tenant');
    }

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateSpoTenant),
            mergeMap((action) =>
                this.put(action._tenant, action.data).pipe(
                    map(() => actions.updateSpoTenantSuccess({ _tenant: action._tenant, data: action.data })),
                    catchError((error) => {
                        console.log('ERROR', error);
                        return of(actions.updateSpoTenantFailure({ _tenant: action._tenant, error }));
                    }),
                ),
            ),
        ),
    );

    private put(tenant: string, body: Partial<SpoTenant>): Observable<void> {
        return this.ajax.put(tenant, '/api/microsoft/powershell/spo/tenant', body);
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
    ) {}
}
