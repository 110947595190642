import { Injectable } from '@angular/core';
import { ServicePrincipal } from '@microsoft/microsoft-graph-types-beta';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { loadServicePrincipalByDisplayNameSuccess, loadServicePrincipalsSuccess } from '../actions';
import * as actions from './actions';

@Injectable()
export class GraphServicePrincipalAppRolesEffects {
    loadServicePrincipalsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadServicePrincipalsSuccess),
            mergeMap(({ _tenant, data }) => {
                const ids: Set<string> = new Set();
                for (const sp of data) {
                    for (const role of sp.appRoleAssignments) {
                        ids.add(role.resourceId);
                    }
                }
                return [...ids.values()].map((id) => actions.loadServicePrincipalAppRoles({ _tenant, id }));
            }),
        ),
    );

    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadServicePrincipalAppRoles),
            mergeMap(({ _tenant, id }) =>
                this.get(_tenant, id).pipe(
                    map((response) => response.appRoles),
                    map((data) => actions.loadServicePrincipalAppRolesSuccess({ _tenant, id, data })),
                ),
            ),
        ),
    );

    loadServicePrincipalByDisplayNameSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadServicePrincipalByDisplayNameSuccess),
            mergeMap(({ _tenant, data }) => {
                const ids: Set<string> = new Set();
                for (const role of data.appRoleAssignments) {
                    ids.add(role.resourceId);
                }
                return [...ids.values()].map((id) => actions.loadServicePrincipalAppRoles({ _tenant, id }));
            }),
        ),
    );

    get(tenant: string, id: string) {
        return this.ajax.get<ServicePrincipal>(
            tenant,
            `/api/microsoft/graph/servicePrincipals/${id}?$select=id,appRoles`,
        );
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
    ) {}
}
