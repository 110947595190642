import { createReducer, on } from '@ngrx/store';
import * as actions from './actions';

export const featureKey = 'mspDatto';
export interface MspDattoState {
    data: any | null;
    loaded: boolean;
    error: any;
}

export const initialState: MspDattoState = {
    data: null,
    loaded: false,
    error: false,
};

export const reducer = createReducer(
    initialState,

    on(actions.loadMspDattoConnectionSuccess, (state, { data }) => ({ ...state, data, loaded: true })),
    on(actions.loadMspDattoConnectionFailure, (state, { error }) => ({ ...state, error })),

    on(actions.loadMspDattoConfigSuccess, (state, { data }) => ({ ...state, data, loaded: true })),
    on(actions.loadMspDattoConfigFailure, (state, { error }) => ({ ...state, error })),
);

export const selectData = (state: MspDattoState) => state.data;
export const selectLoaded = (state: MspDattoState) => state.loaded;
export const selectLoading = (state: MspDattoState) => !state.loaded;
export const selectError = (state: MspDattoState) => state.error;
