import { DirectoryRole } from '@microsoft/microsoft-graph-types-beta';
import { createAction, props } from '@ngrx/store';

export const loadDirectoryRoles = createAction('[Graph/Directory Role] Load', props<{ _tenant: string }>());

export const loadDirectoryRolesSuccess = createAction(
    '[Graph/Directory Role] Load Success',
    props<{ _tenant: string; roles: DirectoryRole[] }>(),
);

export const loadDirectoryRolesFailure = createAction(
    '[Graph/Directory Role] Load Failure',
    props<{ _tenant: string; error: any }>(),
);
