import { Injectable } from '@angular/core';
import { MessageRule, User } from '@microsoft/microsoft-graph-types-beta';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import {
    Observable,
    catchError,
    combineLatest,
    concatMap,
    distinct,
    filter,
    map,
    mergeMap,
    of,
    switchMap,
    take,
} from 'rxjs';
import { EXOInboxRule } from 'src/app/interfaces/powershell/exo/inbox-rule.interface';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { ChangesService } from 'src/app/services/changes.service';
import * as ChangesActions from 'src/app/stores/client/octiga/changes/actions';
import { client } from '../..';
import * as actions from './actions';
import { InboxRuleModel } from './model';

interface Response {
    id: string;
    status: number;
    headers: {
        [key: string]: string;
    };
    body: {
        value: MessageRule[];
    };
}

interface BatchResponse {
    responses: Response[];
}

function GetExoRuleActions(_tenant: string, models: InboxRuleModel[]) {
    const exo_actions: Action[] = [];
    for (const model of models) {
        for (const rule of model.rules) {
            if (!rule.displayName) {
                console.log('rule without displayName', rule);
                continue;
            }
            if (!rule.actions && !rule.conditions) {
                exo_actions.push(
                    actions.fetchExoInboxRule({
                        _tenant,
                        displayName: rule.displayName,
                        user_id: model.user_id,
                    }),
                );
            }
        }
    }
    return exo_actions;
}

@Injectable()
export class GraphInboxRulesEffects {
    private fetchInboxRules(tenant: string, users: User[]) {
        const requests = users.map((user) => ({
            method: 'GET',
            id: user.id,
            url: `/users/${user.id}/mailFolders/inbox/messageRules`,
        }));

        const request_batches = []; // batching batches! $batch is limited to 20 requests per batch

        let count = 0;

        do {
            const start = count * 20;
            const end = start + 20;
            request_batches.push(requests.slice(start, end));
        } while (++count * 20 < requests.length);

        console.log('request batches', request_batches);

        return combineLatest(request_batches.map((requests) => this.getBatch(tenant, { requests }))).pipe(
            map((items) => items.flat(1)),
        );
    }

    private getBatch(tenant: string, body: any): Observable<InboxRuleModel[]> {
        return this.ajax.post<BatchResponse>(tenant, '/api/microsoft/graph/$batch', body).pipe(
            map((response) => {
                const result: InboxRuleModel[] = [];
                for (const res of response.responses) {
                    if (res.status === 200) {
                        result.push({
                            user_id: res.id,
                            rules: res.body.value,
                        });
                    } else {
                        console.log('error response in batch request');
                        console.log(body);
                        console.log(res);
                    }
                }
                return result;
            }),
        );
    }

    private fetchGraphInboxRules$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.fetchGraphInboxRules),
            distinct((action) => action._tenant),
            switchMap((action) =>
                this.store.pipe(
                    select(client(action._tenant).graph.inboxRule.status),
                    filter((status) => !status.loaded),
                    map(() => action),
                    take(1),
                ),
            ),
            mergeMap(({ _tenant, users }) =>
                this.fetchInboxRules(_tenant, users).pipe(
                    mergeMap((rules) => [
                        actions.fetchGraphInboxRulesSuccess({ _tenant, rules }),
                        ...GetExoRuleActions(_tenant, rules), // some rules might be found in EXO
                    ]),
                    catchError((error) =>
                        of(
                            actions.fetchGraphInboxRulesFailure({
                                _tenant,
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    private fetchExoInboxRule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.fetchExoInboxRule),
            concatMap(({ _tenant, user_id, displayName }) =>
                this.pwsGetInboxRule(_tenant, user_id, displayName).pipe(
                    map((rule) =>
                        actions.fetchExoInboxRuleSuccess({
                            _tenant,
                            user_id,
                            rule,
                        }),
                    ),
                    catchError((error) =>
                        of(
                            actions.fetchExoInboxRuleFailure({
                                _tenant,
                                user_id,
                                displayName,
                                error,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    private toggleInboxRule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.toggleInboxRule),
            mergeMap((action) =>
                this.ajax
                    .patch(
                        action._tenant,
                        `/api/microsoft/graph/users/${action.mailbox_id}/mailFolders/inbox/messageRules/${action.rule_id}`,
                        { isEnabled: action.isEnabled },
                    )
                    .pipe(
                        concatMap(() => {
                            const params = {
                                user: action.user,
                                fields: {
                                    id: action.rule_id,
                                    displayName: action.displayName,
                                    actions: action.actions,
                                    conditions: action.conditions,
                                    isEnabled: action.isEnabled,
                                },
                            };
                            const item = this.changesService.formatChangesObjectToDB(params, action.changeType);
                            return [
                                ChangesActions.storeChangesToDB({
                                    _tenant: action._tenant,
                                    item,
                                }),
                                actions.toggleInboxRuleSuccess(action),
                            ];
                        }),
                        catchError((error) =>
                            of(
                                actions.toggleInboxRuleFailure({
                                    _tenant: action._tenant,
                                    error,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    private pwsGetInboxRule(_tenant: string, mailbox: string, displayName: string): Observable<EXOInboxRule> {
        const url = `/api/microsoft/powershell/exo/inbox-rule?Mailbox=${mailbox}&Identity=${encodeURIComponent(
            displayName,
        )}`;
        return this.ajax.get<EXOInboxRule[]>(_tenant, url).pipe(map((res) => res[0]));
    }

    constructor(
        private actions$: Actions,
        private changesService: ChangesService,
        private ajax: TenantAjaxService,
        private store: Store,
    ) {}
}
