import { IdentitySecurityDefaultsEnforcementPolicy } from '@microsoft/microsoft-graph-types-beta';
import { createReducer, on } from '@ngrx/store';
import { Status, initialStatus } from 'src/app/stores/status.interface';
import * as SecurityDefaultsEnforcementActions from './actions';

export const featureKey = 'securityDefaults';

export interface State extends Status {
    data: IdentitySecurityDefaultsEnforcementPolicy;
}

export const initialState: State = {
    data: null,
    ...initialStatus,
};

export const reducer = createReducer(
    initialState,
    // load

    on(SecurityDefaultsEnforcementActions.loadSecurityDefaultsPolicySuccess, (state, action) => ({
        ...state,
        data: action.policy,
        error: false,
        loaded: true,
    })),
    on(SecurityDefaultsEnforcementActions.loadSecurityDefaultsFailure, (state, { error }) => ({
        ...state,
        error,
        loaded: false,
    })),

    on(SecurityDefaultsEnforcementActions.updateSecurityDefaults, (state, action) => ({
        ...state,
        updating: true,
        error: null,
    })),

    on(SecurityDefaultsEnforcementActions.updateSecurityDefaultsSuccess, (state, action) => ({
        ...state,
        data: { ...state.data, isEnabled: action.isEnabled },
        updating: false,
        error: false,
    })),
    on(SecurityDefaultsEnforcementActions.updateSecurityDefaultsFailure, (state, { error }) => ({
        ...state,
        error,
        updating: false,
    })),
);

export const selectPolicy = (state: State) => state.data;
export const selectError = (state: State) => state.error;
