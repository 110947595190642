import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConditionalAccessPolicy } from '@microsoft/microsoft-graph-types-beta';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subscription, combineLatest, debounceTime, first, map, skipUntil, switchMap } from 'rxjs';
import { TemplateItem } from 'src/app/modules/sway/store/templates/items/model';
import { client } from 'src/app/stores/client';
import {
    createConditionalAccessPolicySuccess,
    deleteConditionalAccessPolicySuccess,
} from 'src/app/stores/client/graph/conditional-access/actions';
import { updateBaseline } from 'src/app/stores/client/sway/baseline/actions';
import { Baseline } from 'src/app/stores/client/sway/baseline/model';
import { selectSession } from 'src/app/stores/root.store';
import { NoCapSchema } from '../cap-schema';
import { checkIsBlockingEveryone } from '../utlize';

@Component({
    selector: 'sway-cap-baseline',
    templateUrl: './cap-baseline.component.html',
    styleUrls: ['./cap-baseline.component.scss', '../../../../styles/baseline-style.scss'],
})
export class CapBaselineComponent implements OnInit, OnDestroy {
    @Input() form: FormGroup;
    @Input() tenant_id: string;
    @Input() readOnly: boolean;
    @Input() baseline: Baseline;
    @Input() baselineTemplateItem: TemplateItem;
    @Input() schemaFunc: Function;
    @Input() preventBlockingEveryOne: boolean;

    @Output() baselineChange = new EventEmitter<Baseline>();
    @Output() baselineErrorChange = new EventEmitter<{ remediate: boolean; save: boolean }>();
    isBlockingEveryOne: boolean = false;

    isSecurityDefaultsEnabled: boolean;

    @Input() policy$: BehaviorSubject<ConditionalAccessPolicy>;
    @Input() schema$: BehaviorSubject<any>;
    noIncludeUsers: boolean;

    constructor(
        private store: Store<any>,
        private action: Actions,
    ) {}

    subs: Subscription[] = [];

    securityDefaults$ = this.store.select(selectSession).pipe(
        map((sess) => sess.session.clientId),

        switchMap((tid) =>
            combineLatest([
                this.store.select(client(tid).graph.securityDefaults.item),
                this.store.select(client(tid).graph.securityDefaults.status),
            ]),
        ),
        first(([data, status]) => status.loaded),
        map(([data]) => data),
    );

    ngOnInit(): void {
        this.noIncludeUsers = this.checkIsNoIncludeUsers();

        this.isBlockingEveryOne = checkIsBlockingEveryone(this.form.getRawValue());

        const blockingEveryoneError = this.isBlockingEveryOne && this.preventBlockingEveryOne;

        if (this.noIncludeUsers || blockingEveryoneError) {
            this.baselineErrorChange.next({
                remediate: true,
                save: true,
            });
        } else {
            this.baselineErrorChange.next({
                remediate: false,
                save: false,
            });
        }

        this.observeCAPPoliciesCreationOrDeletion();
        let schema = JSON.parse(JSON.stringify(this.baseline.schema));
        const loaded$ = this.store.pipe(
            select(client(this.tenant_id).graph.conditionalAccessPolicy.status),
            map((status) => status.loaded),
        );

        const _sub = this.store
            .pipe(
                select(client(this.tenant_id).graph.conditionalAccessPolicy.all),
                skipUntil(loaded$),
                map((data) => {
                    if (data instanceof Array) {
                        if (!!this.baseline.key) {
                            const id = this.baseline.key;
                            const policy = data.find((res) => res.id === id);

                            if (!policy && !!id) {
                                const data: Baseline = { ...this.baseline, key: null };

                                const _tenant = this.tenant_id;

                                this.store.dispatch(updateBaseline({ _tenant, data }));
                            }

                            return policy || {};
                        } else {
                            const displayName =
                                schema?.contains?.properties?.displayName?.const ||
                                schema?.not?.contains?.properties?.displayName?.const;

                            const policy = data.find((res) => res.displayName === displayName) || {};

                            if (policy.id && !!schema.contains) {
                                // there is a policy, and it has not deleted baseline

                                const data: Baseline = { ...this.baseline, schema: schema, key: policy.id };

                                const _tenant = this.tenant_id;

                                this.store.dispatch(updateBaseline({ _tenant, data }));
                            }

                            return policy;
                        }
                    }
                }),
            )
            .subscribe((res) => {
                this.policy$.next(res);
            });

        const sub = this.securityDefaults$
            .pipe(
                switchMap((securityDefaults) => {
                    this.isSecurityDefaultsEnabled = securityDefaults.isEnabled;

                    if (this.isSecurityDefaultsEnabled) {
                        this.baselineErrorChange.next({
                            remediate: true,
                            save: false,
                        });
                    }

                    return this.form.valueChanges.pipe(debounceTime(100));
                }),
            )
            .subscribe((_) => {
                const formValues = this.form.getRawValue();

                if (formValues.state === 'delete') {
                    schema = JSON.parse(JSON.stringify(NoCapSchema(formValues.displayName)));
                } else {
                    schema = this.setSchemaValue();
                }

                this.schema$.next(schema);

                if (this.form.get('displayName').errors?.required) {
                    this.baselineErrorChange.next({
                        remediate: true,
                        save: true,
                    });
                } else {
                    this.baselineErrorChange.next({
                        remediate: false,
                        save: false,
                    });
                }

                this.baselineChange.next({
                    ...this.baseline,
                    schema: JSON.parse(JSON.stringify(schema)),
                });
            });

        this.subs.push(sub, _sub);
    }

    checkIsNoIncludeUsers() {
        const formValue = this.form.value;

        if (formValue.includeUsersConfig === 'GuestsOrExternalUsers') {
            if (
                (formValue.includeUsers?.length > 0 && formValue.includeUserSettings.includeUsers) ||
                (formValue.includeRoles?.length > 0 && formValue.includeUserSettings.includeRoles) ||
                (formValue.includeGuestsOrExternalUsers?.length > 0 &&
                    formValue.includeUserSettings.includeGuestsOrExternalUsers) ||
                (formValue.includeGroups?.length > 0 && formValue.includeUserSettings.includeGroups)
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    setSchemaValue(): any {
        const formValues = this.form.getRawValue();

        const schema = JSON.parse(JSON.stringify(this.schemaFunc(formValues)));

        return schema;
    }

    observeCAPPoliciesCreationOrDeletion() {
        const sub = this.action.pipe(ofType(createConditionalAccessPolicySuccess)).subscribe((res) => {
            if (res) {
                const policyId = res.policy.id;

                const data: Baseline = { ...this.baseline, key: policyId };

                const _tenant = this.tenant_id;

                this.store.dispatch(updateBaseline({ _tenant, data }));
            }
        });

        const sub2 = this.action.pipe(ofType(deleteConditionalAccessPolicySuccess)).subscribe((res) => {
            if (res) {
                const data: Baseline = { ...this.baseline, key: null };

                const _tenant = this.tenant_id;

                this.store.dispatch(updateBaseline({ _tenant, data }));
            }
        });

        this.subs.push(sub, sub2);
    }

    ngOnDestroy(): void {
        this.subs.forEach((sub) => {
            sub.unsubscribe();
        });
    }
}
